import React from "react";

// wrappers
import {
  MainWithSide as Main
} from "../../components/containers/Wrappers";

// page components
import { ViewRegulationsBlock } from "../../components/currentProfile/ViewRegulationsBlock";

// side
import { ViewAboutBlock } from "../../components/currentProfile/ViewAboutBlock";
import { ViewProfileBlock } from "../../components/currentProfile/ViewProfileBlock";
import { ViewRecentActivityBlock } from "../../components/currentProfile/ViewRecentActivityBlock";
import { ViewRepresentativesBlock } from "../../components/currentProfile/ViewRepresentativesBlock";

export default function PolicyMakerProfilePage(props) {
  const {isCurrentUser , isGuest} = props;

  return (
      <div className="pt-3 pb-8 w-full">
        <ViewProfileBlock isCurrentUser={isCurrentUser} isGuest={isGuest} />
        <ViewAboutBlock isCurrentUser={isCurrentUser} isGuest={isGuest}/>
        <ViewRegulationsBlock isCurrentUser={isCurrentUser} isGuest={isGuest}/>
        <ViewRepresentativesBlock isCurrentUser={isCurrentUser} isGuest={isGuest}/>
        <ViewRecentActivityBlock isCurrentUser={isCurrentUser} isGuest={isGuest}/>
      </div>

  );
}
