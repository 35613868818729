import { Button } from "primereact/button";
import React from "react";
import { useFormik } from "formik";

// Dailogs
import { useSelector } from "react-redux";
import { EditAboutDialog } from "../dialogs/EditAboutDialog";

export const ViewAboutBlock = (props) => {

  const { isCurrentUser } = props;

  const userState = useSelector((state) => state.user);

  const user = {
    about: userState.user.about ?? "N/A"
  };

  const [showEdit, setShowEdit] = React.useState(false);


  return (
    <div className="mt-4 bg-white p-3 border-round">
      <div className="flex justify-content-between align-items-center">
        <h2>About</h2>

        {(!props.isGuest && isCurrentUser) && <Button icon="pi pi-pencil" className="p-button-text" onClick={() => {setShowEdit(true)}} /> }


      </div>
      <div>{user?.about}</div>

      {/* Dailogs */}
      {(!props.isGuest && isCurrentUser) &&
        <EditAboutDialog
          visible={showEdit}
          onHide={() => setShowEdit(false)}
        />
      }
    </div>
  );
};
