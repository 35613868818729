import React from "react";
import { useFormik } from "formik";
import { Image } from "primereact/image";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";


// logo
import logo from "../../assets/logo.svg";
import eca from "../../assets/eca.png";
import unu from "../../assets/unu.png";



// customs
import ButtonGroup from "../../components/forms/ButtonGroup";
import EnterpreneurRegistrationForm from "../../components/forms/EnterpreneurRegistrationForm";
import InvestorRegistrationForm from "../../components/forms/InvestorRegistrationForm";
import PolicyMakerRegistrationForm from "../../components/forms/PolicyMakerRegistrationForm";
import { USERTYPE } from "../../constants/USERTYPE";



export default function RegisterPage(props) {
  const [selected, setSelected] = React.useState(USERTYPE.ENTREPRENEUR);


  return (
    <main className="relative overflow-y-scroll">
      <div className="relative flex">
        <div className="h-full col-12 lg:col-6 p-3 md:p-5 lg:py-8 flex flex-column">
          <div className="w-12 md:w-10 lg:w-9 align-self-center">
            <div className="mt-3">
              <Image src={logo} imageClassName="h-2rem md:h-3rem" />
            </div>
            <div className="flex flex-column mt-5 text-3xl md:text-5xl">
              <strong>Sign up for the JUSTIS</strong>
              <strong>Social Portal</strong>
            </div>

            <p className="text-base md:text-lg lg:text-xl mt-5">
              Sign up as
            </p>
            <ButtonGroup
              selected={selected}
              onButtonOneClick={() => setSelected(USERTYPE.ENTREPRENEUR)}
              onButtonTwoClick={() => setSelected(USERTYPE.POLICY_MAKER)}
              onButtonThreeClick={() => setSelected(USERTYPE.INVESTOR)}
            />

            {selected === USERTYPE.ENTREPRENEUR ? (
              <EnterpreneurRegistrationForm />
            ) : selected === USERTYPE.POLICY_MAKER ? (
              <PolicyMakerRegistrationForm />
            ) : (
              <InvestorRegistrationForm />
            )}

            <div>
              <Link
                to="/"
                className="no-underline text-md md:text-lg text-primary-800  hover:text-primary-600"
              >
                <p className="text-md m-0 ">Need an account? Sign up here</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="h-auto flex-auto hidden lg:flex flex-column login-image justify-content-end">
          <div className="p-7 login w-10 align-self-center mb-5">
            <div>
              <strong className="text-white text-4xl">
                Connect, engage and unlock potential investments
              </strong>
            </div>

            <div>
              <p className="text-lg text-white font-medium">
                The JUSTIS digital portal seeks to bridge the gap between green
                entrepreneurs, policymakers, and investors to accelerate green
                growth and green transitions.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="h-full relative flex flex-column justify-content-center align-items-center ">
        <div className="login h-screen my-4 p-5 w-11 md:w-8 lg:w-5 flex flex-column align-items-center overflow-y-scroll">
          <div className="mt-5 flex gap-3 align-items-center flex-wrap justify-content-center">
            <Image
              src={logo}
              alt="logo"
              imageClassName="w-6rem md:w-8rem"
              imageStyle={{
                objectFit: "contain",
              }}
            />

            <Image
              src={unu}
              alt="logo"
              imageClassName="w-6rem md:w-8rem"
              imageStyle={{
                objectFit: "contain",
              }}
            />

            <Image
              src={eca}
              alt="logo"
              imageClassName="w-6rem md:w-8rem"
              imageStyle={{
                objectFit: "contain",
              }}
            />
          </div>

          <h1 className="text-xl md:text-2xl lg:text-3xl text-primary-700 font-bold text-center">
            SIGN UP AS
          </h1>
          <ButtonGroup
            selected={selected}
            onButtonOneClick={() => setSelected(USERTYPE.ENTREPRENEUR)}
            onButtonTwoClick={() => setSelected(USERTYPE.POLICY_MAKER)}
            onButtonThreeClick={() => setSelected(USERTYPE.INVESTOR)}
          />

          {selected === USERTYPE.ENTREPRENEUR ? (
            <EnterpreneurRegistrationForm />
          ) : selected === USERTYPE.POLICY_MAKER ? (
            <PolicyMakerRegistrationForm />
          ) : (
            <InvestorRegistrationForm />
          )}

          <div>
            <Link
              to="/"
              className="no-underline text-md md:text-lg text-primary-800  hover:text-primary-600"
            >
              <p className="text-md m-0 ">Already have an account? Sign in</p>
            </Link>
          </div>
        </div>
      </div> */}
    </main>
  );
}
