import React from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";

// custom components
import { ShareAPost } from "../../components/customs/ShareAPost";
import { SuggestedUsers } from "../../components/customs/SuggestedUsers";

// Wrappers
import { useDispatch, useSelector } from "react-redux";
import {
  MainWithSide as Main,
  MainWithSideWrapper,
  Side,
} from "../../components/containers/Wrappers";
import AddPostDialog from "../../components/dialogs/AddPostDialog";
import EditPostDialog from "../../components/dialogs/EditPostDialog";
import { defaultImage } from "../../constants/IMAGES";

// loader and error
import NoPost from "./NoPost";
import { getFirebaseDocs } from "../../util/firebaseFirestoreUtils";
import { FIREBASECOLLECTION } from "../../constants/FIREBASECOLLECTIONS";
import {
  deletePostSuccess,
  getPostsError,
  getPostsStart,
  getPostsSuccess,
  resetActionType,
  likePostSuccess
} from "../../redux/slices/PostSlice";
import { DocumentData, QueryDocumentSnapshot, getDocs, limit, onSnapshot, orderBy, startAfter } from "firebase/firestore";
import { toast } from "react-toastify";
import {
  deletePostMiddleware,
  likePostMiddleware,
} from "../../redux/middlewares/PostMiddleware";
import { getUsersMiddleware } from "../../redux/middlewares/UserMiddleware";
import { Button } from "primereact/button";
import PostItem2 from "../../components/customs/PostItem2";

export default function HomePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //redux
  const authenticateState = useSelector((state: any) => state.authenticate);
  const userState = useSelector((state: any) => state.user);
  const postState = useSelector((state: any) => state.post);

  // dialogs for the share components
  const [showShareDialog, setShowShareDailog] = React.useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false);
  const [selectedPost, setSelectedPost] = React.useState(null);

  //pagination hooks
  const lastVisibleDocSnapshot = React.useRef<QueryDocumentSnapshot<DocumentData>>();
  const [isPaginationLoading, setIsPaginationLoading] = React.useState<boolean>(false);

  //get real time posts
  React.useEffect(() => {


    // const postsRef = getFirebaseDocs(`${FIREBASECOLLECTION.POSTS}`,[orderBy("createdAt","desc"), limit(5)]);
    const postsRef = getFirebaseDocs(`${FIREBASECOLLECTION.POSTS}`, [orderBy("createdAt", "desc"), limit(5)]);

    const unsubscribeListener = onSnapshot(postsRef, (querySnapshot) => {
      console.log("on snap callback called for get posts");
      getPostsStart();
      try {
        const posts: any[] = [];
        querySnapshot.forEach((doc) => {
          posts.push(doc.data());
        });

        // Get the last visible document
        const lastVisible: QueryDocumentSnapshot<DocumentData> = querySnapshot.docs[querySnapshot.docs.length - 1];
        lastVisibleDocSnapshot.current = lastVisible;
        dispatch(getPostsSuccess(posts));

      } catch (error) {
        const errorCode = error.code;
        dispatch(getPostsError({ message: errorCode ?? error }));
      }
    });

    sessionStorage.setItem("hasAlreadyReloaded", "true");

    return () => {
      unsubscribeListener();
    };
  }, []);

  //on load more posts clicked
  const onLoadMorePosts = async () => {
    setIsPaginationLoading(true);


    // const postsRef = getFirebaseDocs(`${FIREBASECOLLECTION.POSTS}`,[orderBy("createdAt","desc"), limit(5)]);
    const postsRef = getFirebaseDocs(`${FIREBASECOLLECTION.POSTS}`, [orderBy("createdAt", "desc"), limit(5), startAfter(lastVisibleDocSnapshot.current)]);
    const docSnapshots = await getDocs(postsRef);

    const posts: any[] = [...postState.posts]
    docSnapshots.forEach((doc) => {
      posts.push(doc.data());
    });

    dispatch(getPostsSuccess(posts))

    // Get the last visible document
    const lastVisible: QueryDocumentSnapshot<DocumentData> = docSnapshots.docs[docSnapshots.docs.length - 1];
    lastVisibleDocSnapshot.current = lastVisible;

    setIsPaginationLoading(false);
  }

  //fetch all users
  React.useEffect(() => {
    dispatch<any>(getUsersMiddleware());
  }, []);

  
  //on post deleted
  React.useEffect(() => {
    if (postState.ACTION_TYPE === deletePostSuccess.toString()) {
      toast.success("Post deleted successfully");
      dispatch(resetActionType());
    } else if (postState.ACTION_TYPE === getPostsError.toString()) {
      toast.error(postState.getPostsMessage);
      dispatch(resetActionType());
    }
  }, [postState.ACTION_TYPE]);

  return (
    <MainWithSideWrapper className={""}>
      {/* confirm dialog */}
      <ConfirmDialog />
      {/* Main Page Items */}
      {/* ---------------------------------------------------------------- */}
      <Main className="pt-3">
        <ShareAPost
          avatar={authenticateState.user?.profileImage ?? defaultImage}
          onAvatarClick={() =>
            navigate(`/profile/${authenticateState.user_id}`)
          }
          onShare={() => setShowShareDailog(!showShareDialog)}
        />

        <div className="flex flex-column gap-5 mt-4 pb-8 w-full">
          {postState.isGetPostsLoading && <ProgressSpinner />}
          {postState.posts?.length === 0 ? (
            <NoPost text="No Posts Found" />
          ) : (
            postState.posts?.map((item, index) => (
              <PostItem2
                item={item}
                key={index.toString()}

                onEditPost={() => {
                  // dispatch(getPostSuccess(item))
                  setSelectedPost(item);
                  setShowUpdateDialog(true);
                }}
                onDeletePost={() => {
                  // dispatch(getPostSuccess(item))
                  setSelectedPost(item);
                  confirmDialog({
                    message: "Do you want to delete this post?",
                    header: "Delete Confirmation",
                    icon: "pi pi-info-circle",
                    acceptClassName: "p-button-danger",
                    accept: () => {
                      dispatch<any>(deletePostMiddleware(item.id));
                    },
                    reject: () => { },
                  });
                }}
              />
              
            ))
          )}
          <Button label="Load More" onClick={() => { onLoadMorePosts() }} loading={isPaginationLoading} />
        </div>
      </Main>
      {/* ---------------------------------------------------------------- */}

      {/* Suggested Users Component */}
      {/* ---------------------------------------------------------------- */}
      <Side className={""}>
        <SuggestedUsers
          // data
          data={[]}
          onProfileClick={() => { }}
        />
      </Side>
      {/* ---------------------------------------------------------------- */}

      {/* Dialogs for the post components  */}
      {/* ---------------------------------------------------------------- */}
      <AddPostDialog
        visible={showShareDialog}
        onHide={() => setShowShareDailog(false)}
      // setShowShareDailog={setShowShareDailog}
      />

      <EditPostDialog
        visible={showUpdateDialog}
        onHide={() => setShowUpdateDialog(false)}
        // ! selected post details
        selectedPost={selectedPost}
      />

      {/* ---------------------------------------------------------------- */}
    </MainWithSideWrapper>
  );
}
