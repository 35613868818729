import React from "react";
import Lottie from "react-lottie";
import * as image from "../../assets/lottie/notfound.json";

import { SuggestedUsers } from "../../components/customs/SuggestedUsers";

import {
  MainWithSideWrapper,
  MainWithSide,
  Side,
} from "../../components/containers/Wrappers";

export default function ErrorPage({text = "User not found"}) {
  return (
    <MainWithSideWrapper>
      <MainWithSide className="flex flex-column justify-content-start align-items-start pointer-events-none border-x-1 border-gray-300">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: image,
          }}
          height={400}
          width={400}
        />

        <div className="text-center font-medium mt-0 w-full text-xl">
         {text}
        </div>
      </MainWithSide>
      {/* <Side>
        <SuggestedUsers />
      </Side> */}
    </MainWithSideWrapper>
  );
}
