import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import React from "react";

import { useFormik } from "formik";
import { Input } from "../forms/CustomInputs";


//redux
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateUserProfileMiddleware } from "../../redux/middlewares/AuthenticateMiddleware";

import { isFormFieldValid } from "../../util/formikUtils";

export const AddRepresentativesDialog = (props) => {

    const authenticateState = useSelector((state) => state.authenticate);
    const userState = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const location = useLocation();
    // const {regulationIndex} = location.state;


    const { visible, onHide, } = props;

    const formik = useFormik({
        initialValues: {
            name: "",
            imageUrl: "",
            role: "",
        },

        validate: (values) => {
            const errors = {};
            if (!values.name) {
              errors.name = " name is required.";
            } 
            if (!values.role) {
              errors.role = "role is required.";
            } 
            if (!values.imageUrl) {
              errors.imageUrl = " URL is required.";
            } 
           
            return errors;
          },

        onSubmit: (values) => {
            const REP = userState.user?.representatives ?? []  ;
            let REPS = [ ...REP]
            REPS.push(
                {
                    name : values.name,
                    imageUrl : values.imageUrl,
                    role : values.role,
                }
             );
            const userObject = {
                representatives: REPS 

            }

            dispatch(updateUserProfileMiddleware(userObject));
        },
    });

   

    return (
        <Dialog
            header={"Add Representative"}
            visible={visible}
            onHide={onHide}
            className="w-11 md:w-8 lg:w-6"
            footer={() => (
                <div>
                    <Button
                        className="p-button-secondary"
                        type="submit"
                        label="Save"
                        onClick={formik.handleSubmit}
                        loading={authenticateState.isUpdateUserProfileLoading}

                    />
                </div>
            )}
        >
            <div className="flex flex-column bg-white p-5 mb-5">
                
                {/* input sections */}
                {/* ---------------------------------------------------------------- */}
                <Input
                    formik={formik}
                    label="Name"
                    id="name"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    className={classNames({ "p-invalid": isFormFieldValid(formik, "name"), })}
                />

                <Input
                    label="Role"
                    formik={formik}
                    id="role"
                    name="role"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.role}
                    className={classNames({ "p-invalid": isFormFieldValid(formik, "role"), })}
                />

                <Input
                    label="Image URL"
                    formik={formik}
                    id="imageUrl"
                    name="imageUrl"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.imageUrl}
                    className={classNames({ "p-invalid": isFormFieldValid(formik, "imageUrl"), })}
                />
                {/* ---------------------------------------------------------------- */}
            </div>
        </Dialog>
    );
};

