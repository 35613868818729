import React from "react";
import Lottie from "react-lottie";
import * as image from "../../assets/lottie/loading.json";

export default function Loading() {
  return (
    <div className=" flex p-8 justify-content-center align-items-center pointer-events-none">
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: image,
        }}
        height={100}
        width={100}
      />
    </div>
  );
}
