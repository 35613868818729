


import { arrayRemove, arrayUnion, increment } from "firebase/firestore";
import { FIREBASECOLLECTION } from "../../constants/FIREBASECOLLECTIONS";
import { auth } from "../../firebase/firebase-config";
import { deleteFirebaseDoc, getCollectionFirebase,  setFirebaseDoc, updateFirebaseDoc } from "../../util/firebaseFirestoreUtils";

import {
     getNotificationsError, getNotificationsStart,getNotificationsSuccess,
    addNotificationError, addNotificationStart, addNotificationSuccess,
    deleteNotificationError, deleteNotificationStart, deleteNotificationSuccess,
    editNotificationError, editNotificationStart, editNotificationSuccess,
    toggleReadNotificationStart , toggleReadNotificationSuccess, toggleReadNotificationError ,

} from "../slices/NotificationSlice";

export const addNotificationMiddleware = (notificationId, notificationObject) => {
    return async (dispatch) => {
        dispatch(addNotificationStart());
        try {

            setFirebaseDoc(`${FIREBASECOLLECTION.NOTIFICATIONS}/${notificationId}`, notificationObject);

            dispatch(addNotificationSuccess({}));

        } catch (error) {
            const errorCode = error.code;
            dispatch(addNotificationError({ message: errorCode ?? error }));
        }
    };
};

export const editNotificationMiddleware = (notificationId, otherDetails) => {
    return async (dispatch) => {
        dispatch(editNotificationStart());
        try {

            updateFirebaseDoc(`${FIREBASECOLLECTION.NOTIFICATIONS}/${notificationId}`, otherDetails);

            //get from user collection and save to redux
            dispatch(editNotificationSuccess({}));

        } catch (error) {
            const errorCode = error.code;
            dispatch(editNotificationError({ message: errorCode ?? error }));
        }
    };
};

export const deleteNotificationMiddleware = (notificationId) => {
    return async (dispatch) => {
        dispatch(deleteNotificationStart());
        try {

            await deleteFirebaseDoc(`${FIREBASECOLLECTION.NOTIFICATIONS}/${notificationId}`);

            //get from user collection and save to redux
            dispatch(deleteNotificationSuccess({}));

        } catch (error) {
            const errorCode = error.code;
            dispatch(deleteNotificationError({ message: errorCode ?? error }));
        }
    };
};

export const toggleReadNotificationMiddleware = (notificationId, isRead = true) => {
    return async (dispatch) => {
        dispatch(toggleReadNotificationStart());
        try {



            //if already liked, unlike
            if (isRead) {

                //like if not liked
                await updateFirebaseDoc(`${FIREBASECOLLECTION.NOTIFICATIONS}/${notificationId}`, {
                    likeCount: increment(1),
                    likes: arrayUnion(auth.currentUser.uid.toString())
                });
                dispatch(toggleReadNotificationSuccess({}));

            } else {

                await updateFirebaseDoc(
                    `${FIREBASECOLLECTION.NOTIFICATIONS}/${notificationId}`,
                    {
                        likeCount: increment(-1),
                        likes: arrayRemove(auth.currentUser.uid.toString())
                    }
                );
                dispatch(toggleReadNotificationSuccess({}));

            }

        } catch (error) {
            console.log(error);
            const errorCode = error.code;
            dispatch(toggleReadNotificationError({ message: errorCode ?? error }));
        }
    };
};

export const getNotificationsMiddleware = () => {
    return async (dispatch) => {
        dispatch(getNotificationsStart());

        try {
            const posts = [];
            const querySnapshot = await getCollectionFirebase(FIREBASECOLLECTION.NOTIFICATIONS);

            querySnapshot.forEach((doc) => {
                posts.push(doc.data());
                console.log(doc.id, " => ", doc.data());
            });

            dispatch(getNotificationsSuccess(posts));

        } catch (error) {
            const errorCode = error.code;
            // const errorMessage = error.message;
            dispatch(getNotificationsError({ message: errorCode }));
        }
    };
}

