import React from "react";
import { useLocation } from "react-router-dom";

import Empty from "../../components/search/Empty";
import Loading from "../../components/search/Loading";

// results
import { useSelector } from "react-redux";
import UserResults from "../../components/search/UserResults";

export default function UserSearch(props) {
  const searchState = useSelector((state) => state.search);

  const { state } = useLocation();




  if (searchState.isGetUserSearchResultsLoading) {
    return <Loading />;
  }

  if (searchState.userSearchResults?.length === 0) {
    return (
      <>
        <Empty />
      </>
    );
  }

  return (
    <div>
      {searchState.userSearchResults?.length > 0 && (
        <UserResults title="Users" data={searchState.userSearchResults} />
      )}
    </div>
  );
}
