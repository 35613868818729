export const countryOptionTemplate = (option) => {
  return (
    <div className="country-item">
      <div>{option.name}</div>
    </div>
  );
};

export const selectedCountryTemplate = (option, props) => {
  if (option) {
    return (
      <div className="country-item country-item-value">
        <div>{option.name}</div>
      </div>
    );
  }

  return <span>{props.placeholder}</span>;
};
