import React from "react";
import moment from "moment";

export const MessageItem = ({ item, currentUserId }, key) => {
  // console.log(item);

  return (
    <div key={key} className="flex flex-column py-5 px-2  md:px-5 ">
      {item.from.id === currentUserId ?

        <div
          className="align-self-end bg-primary-500 text-white p-3 w-5 "
          style={{
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 0,
          }}
        >
          {item.message}
          <div className="mt-2 text-xs">
            {moment(parseInt(item.createdAt)).fromNow()}
          </div>
          <div></div>
        </div>
        :
        <div
          className="align-self-start bg-cyan-500 text-white p-3 w-5 "
          style={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 20,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
          }}
        >
          {item.message}
          <div className="mt-2 text-xs">
            {moment(parseInt(item.createdAt)).fromNow()}
          </div>
        </div>
      }


    </div>
  );
};
