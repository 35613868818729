
import {
  getUserError, getUsersError, getUsersStart, getUsersSuccess, getUserStart, getUserSuccess
} from "../slices/UserSlice";


import { FIREBASECOLLECTION } from "../../constants/FIREBASECOLLECTIONS";
import { auth } from "../../firebase/firebase-config";
import { getCollectionFirebase, getFirebaseDoc } from "../../util/firebaseFirestoreUtils";
import { getAuthenticatedUserSuccess } from "../slices/AuthenticateSlice";



export const getUserByIdMiddleware = (uid) => {
  return async (dispatch) => {
    dispatch(getUserStart());

    try {
      // const fireBaseUser = await getFirebaseDoc( `${FIREBASECOLLECTION.USERS}/${uid}`);
      const fireBaseUser = await getFirebaseDoc( `${FIREBASECOLLECTION.USERS}/${uid}`);
      dispatch(getUserSuccess( fireBaseUser.data()));
      if(auth.currentUser?.uid === uid){
        dispatch(getAuthenticatedUserSuccess(fireBaseUser.data()))
      }

    } catch (error) {
      const errorCode = error.code;
      // const errorMessage = error.message;
      dispatch(getUserError({ message: errorCode }));
    }
  };
}

export const getUsersMiddleware = () => {
  return async (dispatch) => {
    dispatch(getUsersStart());

    try {
      const users = [];
      const querySnapshot = await getCollectionFirebase(`${FIREBASECOLLECTION.USERS}`);

      querySnapshot.forEach((doc) => {
        users.push(doc.data());
      });

      dispatch(getUsersSuccess(users));

    } catch (error) {
      const errorCode = error.code;
      // const errorMessage = error.message;
      dispatch(getUsersError({ message: errorCode }));
    }
  };
}


