import React from "react";
import { Button } from "primereact/button";
import { RWebShare } from "react-web-share";
export const ShareProfile = ({color}) => {
  return (
    <div className={`mt-2 mr-4 ml-1 p-2  border-round flex flex-column`}>
       <RWebShare
                data={ {
                    text: "Checkout this Justis User's Profile!.",
                    url: window.location.href,
                    title: "Justis Social",
                  }
                }
                // onClick={()=>console.log("on click")}
              >

      <Button
        className={`p-button-sm flex gap-2 w-10 ${color}`}
        icon="pi pi-share-alt"
        label=""
        >
        Share Profile
      </Button>
        </RWebShare>
    </div>
  );
};
