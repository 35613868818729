import { If } from "@kanzitelli/if-component";
import moment from "moment";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import empty from "../../assets/icons/notification-bell.png";
import { toast } from "react-toastify";

const activeStyle =
  "p-button-sm text-sm px-3 py-1 border-round-xl bg-primary-100 border-none text-primary";
const inactiveStlye =
  "p-button-sm px-3 py-1 border-round-xl p-button-text border-none text-color";

export default function Notifications({ toggleMenu }) {
  const [view, setView] = React.useState("all");
  const navigate = useNavigate();
  const notificationState = useSelector((state) => state.notification);
  let data = notificationState.notifications;

  return (
    <div className="flex flex-column gap-1 w-12">
      {/* ----------------- NOTIFCATION HEADER STARTS ---------------*/}
      <section className="flex justify-content-between align-items-center gap-8 w-full">
        <h3 className="text-lg font-bold">Notifications</h3>
        <small
          className="cursor-pointer"
          onClick={(e) => {
            navigate("/notifications");
            toggleMenu(e);
          }}
        >
          See All
        </small>
      </section>
      {/* ----------------- NOTIFCATION HEADER ENDS ---------------*/}
      {/* ------------------ VIEW TYPE TOGGLE STARTS -------------- */}
      <section className="flex gap-3 mb-4">
        <Button
          className={`${view === "all" ? activeStyle : inactiveStlye}`}
          label="Recent"
          onClick={() => setView("all")}
        />
        <Button
          className={`${view === "unread" ? activeStyle : inactiveStlye}`}
          label="Unread"
          onClick={() => setView("unread")}
        />
      </section>
      {/* ------------------ VIEW TYPE TOGGLE ENDS -------------- */}
      {/* ------------------ ALL LIST STARTS -------------- */}
      <If
        _={view === "all"}
        _then={
          <If
            _={data.length > 0}
            _then={
              <section className="flex flex-column gap-4 w-full">
                {data
                  .filter((item) => item?.isRead === false)
                  .map((item) => (
                    <NotificationItem
                      key={item.id}
                      data={item}
                      onMarkAsRead={() => toast.warning("Marked as read")}
                      onDelete={() => toast.error("Deleted")}
                    />
                  ))}
              </section>
            }
            _else={
              <section className="flex flex-column  w-full justify-content-center align-items-center">
                <Image src={empty} height={100} width={100} />
                <p className="text-center">No notifications to show</p>
              </section>
            }
          />
        }
      />
      {/* ------------------ ALL LIST ENDS -------------- */}
      {/* 
          the idea is to show the most recent 5 notifications 
       */}
      {/* ------------------ UNREAD LIST STARTS -------------- */}
      <If
        _={view === "unread"}
        _then={
          <If
            _={
              data.length > 0 &&
              data.filter((item) => item?.isRead === false).length > 0
            }
            _then={
              <section className="flex flex-column gap-4 w-full">
                {data
                  .filter((item) => item?.isRead === false)
                  .slice(0, 5)
                  .map((item) => (
                    <NotificationItem
                      key={item.id}
                      data={item}
                      onMarkAsRead={() => toast.success("Marked as read")}
                      onDelete={() => toast.success("Deleted")}
                    />
                  ))}
              </section>
            }
            _else={
              <section className="flex flex-column  w-full justify-content-center align-items-center">
                <Image src={empty} height={100} width={100} />
                <p className="text-center">No notifications to show</p>
              </section>
            }
          />
        }
      />
      {/* ------------------ UNREAD LIST ENDS -------------- */}
    </div>
  );
}

export const NotificationItem = (
  { data, fullMessage, onMarkAsRead, onDelete },
  key
) => {
  return (
    <div key={key} className="flex flex-column gap-2 cursor-pointer">
      <div className="flex align-items-start justify-content-between gap-3 ">
        <div className="flex align-items-start gap-3">
          <Image
            src={data?.sentFrom?.imageUrl}
            imageClassName="border-circle"
            height={70}
            width={70}
          />
          <div className="flex flex-column gap-1">
            <div className="text-sm">
              <span className="font-bold">{data?.sentFrom?.name} </span>
              {data.title}
            </div>
            <div
              className={`text-sm ${
                !fullMessage &&
                "white-space-nowrap max-w-10rem lg:max-w-11rem overflow-hidden text-overflow-ellipsis"
              } `}
            >
              {data.message}
            </div>
            <div className="flex align-items-center gap-1">
              <i className="pi pi-clock text-xs" />
              <small className="text-xs">
                {" "}
                {moment(parseInt(data.createdAt)).fromNow()}
              </small>
            </div>
          </div>
        </div>

        <If
          _={!data.isRead}
          _then={
            <div
              className={`p-button-sm text-xs border-round-xl bg-primary-100 border-none text-primary-600`}
              style={{
                padding: "0.2rem 0.5rem",
              }}
            >
              new
            </div>
          }
          _else={
            <div
              style={{
                padding: "0.2rem 0.5rem",
              }}
            ></div>
          }
        />
      </div>
      {/* notification actions */}
      <div className="mb-2 flex gap-2 pl-1">
        <If
          _={!data?.isRead}
          _then={
            <Button
              className="p-0 text-sm p-button-text border-round-sm"
              onClick={onMarkAsRead}
              icon="pi pi-folder"
              tooltip="Mark as read"
            />
          }
          _else={
            <Button
              className="p-0 text-sm p-button-text border-round-sm"
              onClick={onMarkAsRead}
              icon="pi pi-folder-open"
            />
          }
        />
        <Button
          className="text-sm p-0 p-button-danger p-button-text border-round-sm"
          onClick={onDelete}
          icon="pi pi-trash"
          tooltip="Delete Notification"
        />
      </div>
    </div>
  );
};
