import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";


//redux
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfileMiddleware } from "../../redux/middlewares/AuthenticateMiddleware";
import { CalendarInput, Input, NumberInput, SelectSingle } from "../forms/CustomInputs";

export const EditBusinessInformationDialog = (props) => {
    const { visible, onHide, } = props;
    const dispatch = useDispatch();

    const authenticateState = useSelector((state) => state.authenticate);
    const userState = useSelector((state) => state.user);



    const formik = useFormik({
        initialValues: {
            sector: userState.user?.sector ?? "Informal",
            registeredSince: userState.user?.registeredSince ?? null,
            branches: userState.user?.branches ?? 0,
            greenYears: userState.user?.greenYears ?? 0,
            industry: userState.user?.industry ?? "Pharmacy",
            businessType: userState.user?.businessType ?? "",
            scale: userState.user?.scale ?? "Start Up",
            employees: userState.user?.employees ?? 0,
        },

        onSubmit: (values) => {

            console.log(values.registeredSince.getTime());
            const userObject = {
                sector: values.sector,
                registeredSince: values.registeredSince.getTime(),
                branches: values.branches,
                greenYears: values.greenYears,
                industry: values.industry,
                businessType: values.businessType,
                scale: values.scale,
                employees: values.employees,

            }

            console.log(userObject);

            dispatch(updateUserProfileMiddleware(userObject));
        },
    });

   

    return (
        <Dialog
            header={"Edit Business Information"}
            visible={visible}
            onHide={onHide}
            className="w-11 md:w-8 lg:w-6"
            footer={() => (
                <div>
                    <Button
                        className="p-button-secondary"
                        label="Save"
                        onClick={formik.handleSubmit}
                        loading={authenticateState.isUpdateUserProfileLoading}

                    />
                </div>
            )}
        >
            <form onSubmit={formik?.handleSubmit} className=" " >
                <div>
                    <SelectSingle
                        formik={formik}
                        id={"sector"}
                        label="Select Sector type"
                        options={["Informal", "Formal"]}
                        value={formik.values.sector}
                    />

                    <CalendarInput
                        formik={formik}
                        id="registeredSince"
                        label="Registered Since"
                        value={new Date(formik.values.registeredSince) }
                    />

                    <NumberInput
                        formik={formik}
                        id="branches"
                        label="Number of Branches"
                        value={formik.values.branches}
                    />

                    <NumberInput
                        formik={formik}
                        id="greenYears"
                        label="Number of Green Years"
                        value={formik.values.greenYears}
                    />

                    <Input
                        formik={formik}
                        id="industry"
                        placeholder="(eg.Pharmacy, Agricultural, Clothing ... )"
                        label="Industry"
                        value={formik.values.industry}
                    />

                    <Input
                        formik={formik}
                        id="businessType"
                        placeholder="(eg.Trade, Service)"
                        label="Business Type"
                        value={formik.values.businessType}
                    />

                    <SelectSingle
                        formik={formik}
                        id={"scale"}
                        label="Business Scale"
                        options={["Start Up", "Full-Grown"]}
                        value={formik.values.scale}
                    />

                    <NumberInput
                        formik={formik}
                        id="employees"
                        label="Number of Employees"
                        value={formik.values.employees}
                    />
                </div>
            </form>
        </Dialog>
    );
};