import React from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";

import { isFormFieldValid, getFormErrorMessage } from "../../util/formikUtils";

// country
import { countries } from "../../constants/countries";
import {
  countryOptionTemplate,
  selectedCountryTemplate,
} from "./countryTemplate";

// custom input
const Input = ({ formik, id, label, value, icon, placeholder }) => {
  return (
    <div className="p-fluid w-11 mb-3 field">
      <label
        htmlFor={id}
        className={classNames({
          "p-error": isFormFieldValid(formik, id),
        })}
      >
        {label}
      </label>
      <span className="p-input-icon-right">
        <i className={icon} />
        <InputText
          id={id}
          name={id}
          value={value}
          onChange={formik?.handleChange}
          placeholder={placeholder}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, id),
            "p-input-sm": true,
          })}
        />
      </span>
      <div className="mt-2">{getFormErrorMessage(formik, id)}</div>
    </div>
  );
};

// custom textarea
const TextArea = ({ formik, id, label, value, icon, placeholder }) => {
  return (
    <div className="p-fluid w-11 mb-3 field">
      <label
        htmlFor={id}
        className={classNames({
          "p-error": isFormFieldValid(formik, id),
        })}
      >
        {label}
      </label>
      <span className="p-input-icon-right">
        <i className={icon} />
        <InputTextarea
          id={id}
          name={id}
          value={value}
          onChange={formik?.handleChange}
          placeholder={placeholder}
          autoResize
          className={classNames({
            "p-invalid": isFormFieldValid(formik, id),
            "p-input-sm": true,
          })}
        />
      </span>
      <div className="mt-2">{getFormErrorMessage(formik, id)}</div>
    </div>
  );
};

// custom password
const PasswordInput = ({ formik, id, label, value, feedback }) => {
  return (
    <div className="p-fluid w-11 mb-3 field">
      <label
        htmlFor={id}
        className={classNames({
          "p-error": isFormFieldValid(formik, id),
        })}
      >
        {label}
      </label>
      <span className="p-input-icon-right">
        <Password
          id={id}
          name={id}
          value={value}
          feedback={feedback}
          toggleMask
          onChange={formik?.handleChange}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, id),
          })}
        />
      </span>
      <div className="mt-2">{getFormErrorMessage(formik, id)}</div>
    </div>
  );
};

// custom  country drop down
const CountrySelect = ({ formik, id, label, value }) => {
  return (
    <div className="p-fluid w-11  mb-3 field">
      <label
        htmlFor={id}
        className={classNames({
          "p-error": isFormFieldValid(formik, id),
        })}
      >
        {label}
      </label>
      <span className="p-float-label p-input-icon-right">
        <Dropdown
          name={id}
          id={id}
          value={value}
          options={countries}
          onChange={(e) => {
            formik.setFieldValue(id, e.value);
          }}
          optionLabel="name"
          filter
          showClear
          filterBy="name"
          placeholder={"Select a country"}
          valueTemplate={selectedCountryTemplate}
          itemTemplate={countryOptionTemplate}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, id),
            "p-input-sm": true,
          })}
        />
      </span>
      <div className="mt-2">{getFormErrorMessage(formik, id)}</div>
    </div>
  );
};

// custom single select
const SelectSingle = ({ formik, id, label, value, options }) => (
  <div className="p-fluid w-11  mb-3 field">
    <label
      htmlFor={id}
      className={classNames({
        "p-error": isFormFieldValid(formik, id),
      })}
    >
      {label}
    </label>

    <SelectButton
      value={value}
      options={options}
      onChange={(e) => {
        formik.setFieldValue(id, e.value);
      }}
      className="p-button-sm w-7"
    />
    <div className="mt-2">{getFormErrorMessage(formik, id)}</div>
  </div>
);

// custom calender input
const CalendarInput = ({ formik, id, label, value }) => (
  <div className="p-fluid w-11  mb-3 field">
    <label
      htmlFor={id}
      className={classNames({
        "p-error": isFormFieldValid(formik, id),
      })}
    >
      {label}
    </label>

    <Calendar
      dateFormat="dd MM yy"
      value={value}
      onChange={(e) => formik.setFieldValue(id, e.value)}
    ></Calendar>
    <div className="mt-2">{getFormErrorMessage(formik, id)}</div>
  </div>
);

// custom number import
const NumberInput = ({ formik, id, label, value }) => (
  <div className="p-fluid w-11  mb-3 field">
    <label
      htmlFor={id}
      className={classNames({
        "p-error": isFormFieldValid(formik, id),
      })}
    >
      {label}
    </label>
    <InputNumber
      value={value}
      onValueChange={(e) => formik.setFieldValue(id, e.value)}
    />
    <div className="mt-2">{getFormErrorMessage(formik, id)}</div>
  </div>
);

// LIST OF EXPORTED FROM THIS PAGE
export {
  Input,
  TextArea,
  PasswordInput,
  CountrySelect,
  SelectSingle,
  CalendarInput,
  NumberInput,
};
