import React from "react";
// router
import { Routes, Route, Navigate } from "react-router-dom";
// pages
import LoginPage from "../pages/auth/LoginPage";
import ForgotPaaswordPage from "../pages/auth/ForgotPasswordPage";
import RegisterPage from "../pages/auth/RegisterPage";
import ProfilePage from "../pages/profile/ProfilePage";

export default function AuthRouter() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPaaswordPage />} />
        <Route path="/register" element={<RegisterPage />} />

        <Route path="/guest/profile/:id" element={<ProfilePage isGuest={true} />} />
        {/* default redirect */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}
