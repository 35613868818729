import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { TextArea } from "../forms/CustomInputs";


//redux
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateUserProfileMiddleware } from "../../redux/middlewares/AuthenticateMiddleware";
import { getUserByIdMiddleware } from "../../redux/middlewares/UserMiddleware";
import { updateUserProfileError, updateUserProfileSuccess } from "../../redux/slices/AuthenticateSlice";

export const EditAboutDialog = (props) => {
  const authenticateState = useSelector((state) => state.authenticate);
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();


    const { visible, onHide, } = props;

    const formik = useFormik({
      initialValues: {
          about: userState.user?.about ?? "N/A",
      },

      onSubmit: (values) => {

          const userObject = {
              about: values.about,

          }

          dispatch(updateUserProfileMiddleware(userObject));
      },
  });
  
      

    return (
      <Dialog
        header={"Edit About"}
        visible={visible}
        onHide={onHide}
        className="w-11 md:w-8 lg:w-6"
        footer={() => (
          <div>
            <Button
              className="p-button-secondary"
              label="Save"
              onClick={formik.handleSubmit}
              loading={authenticateState.isUpdateUserProfileLoading }

            />
          </div>
        )}
      >
        <div>
          <TextArea
            formik={formik}
            type="submit"
            id="about"
            label="About"
            value={formik.values.about}
          />
        </div>
      </Dialog>
    );
  };

  