import React from "react";
import { useLocation } from "react-router-dom";
import { POSTTAGS } from "../../constants/POSTTAGS";

import Empty from "../../components/search/Empty";

// results
import { useSelector } from "react-redux";
import PostResults from "../../components/search/PostResults";

export default function PolicySearchPage(props) {
  const searchState = useSelector((state) => state.search);

  const { state } = useLocation();

  const [filteredPosts, setFilteredPosts] = React.useState([]);
  React.useEffect(()=>{
    let mounted = true

    if(mounted){

      const filteredP = searchState.postSearchResults.filter(post => post.tags.includes(POSTTAGS.POLICY.value));
      setFilteredPosts( filteredP);

    }

    return ()=>{
      mounted = false;
    }

  },[]);

  if (filteredPosts.length === 0) {
    return <Empty />;
  }

  return (
    <div>
      {filteredPosts.length > 0 && (
        <PostResults title="General Updates Posts" data={filteredPosts} />
      )}
    </div>
  );
}
