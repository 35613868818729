import { useBreakpoints, useCurrentWidth } from "react-breakpoints-hook";

// ! -------------------------------SIDED WRAPPERS START-------------------------------------
/*
 *  This is a wrapper that renders a scrollable main component with a side scrollable component
 */
const MainWithSideWrapper = ({ children, className }) => {
  return (
    <div
      className={`${className} grid w-full overflow-scroll  bg-gray-100 grid-nogutter mx-auto relative`}
    >
      {children}
    </div>
  );
};

const MainWithSide = ({ children, className }) => {
  let { xs, sm, md, lg, xlg } = useBreakpoints({
    xs: { min: 0, max: 500 },
    sm: { min: 501, max: 890 },
    md: { min: 891, max: 1100 },
    lg: { min: 1101, max: 1400 },
    xlg: { min: 1401, max: null },
  });

  return (
    <main
      className={`${className} 
      ${
        xlg
          ? "px-8 mx-5"
          : lg
          ? "px-3"
          : md
          ? "px-5 "
          : sm
          ? "px-4 mx-8"
          : xs
          ? "pl-2 mx-auto"
          : "pl-2 mx-auto"
      } 
    col mx-auto relative`}
      style={{
        minHeight: "100vh",
      }}
    >
      {/* {`Current width -> ${width}`} */}
      {children}
    </main>
  );
};

const Side = ({ children, className }) => {
  // let width = useCurrentWidth();
  let { sm, md } = useBreakpoints({
    sm: { min: 0, max: 890 },
    md: { min: 891, max: 1100 },
  });

  return (
    <aside
      className={`${className} overflow-x-hidden relative col-fixed ${
        sm ? "hidden" : md ? "flex" : "flex"
      }`}
      style={{
        width: "320px",
      }}
    >
      {children}
    </aside>
  );
};

// ! -------------------------------SIDED WRAPPERS END-------------------------------------

const Main = ({ children, className }) => {
  return (
    <main className={`${className} col  overflow-x-hidden relative`}>
      style=
      {{
        minHeight: "70vh",
      }}
      {children}
    </main>
  );
};

const Column = ({ children, className }) => {
  return <div className={`${className} flex flex-column`}></div>;
};

const Row = ({ children, className }) => {
  return (
    <div className={`${className} flex flex-wrap gap-3 mb-3 `}>{children}</div>
  );
};

export { MainWithSideWrapper, MainWithSide, Main, Side, Column, Row };
