import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { SelectButton } from 'primereact/selectbutton';
import React from "react";

// icons
import {
  FcDocument, FcStackOfPhotos, FcVideoCall
} from "react-icons/fc";

//feed types
import { ref } from "firebase/storage";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FEEDTYPE } from "../../constants/FEEDTYPE";
import { defaultImage } from "../../constants/IMAGES";
import { POSTTAGS } from "../../constants/POSTTAGS";
import { storage } from "../../firebase/firebase-config";
import { uploadFileMiddleware, uploadFileToS3Middleware } from "../../redux/middlewares/FileMiddleware";
import { editPostMiddleware } from "../../redux/middlewares/PostMiddleware";
import { resetActionType as resetFileActionType, setUploadType, uploadFileError, uploadFileSuccess } from "../../redux/slices/FileSlice";
import { editPostError, editPostSuccess, resetActionType } from "../../redux/slices/PostSlice";
import { getFormErrorMessage } from "../../util/formikUtils";
import { TextArea } from "../forms/CustomInputs";

const EditPostDialog = (props) => {

  const{ visible, onHide, selectedPost } = props;
  const [showDocumentName, setShowDocumentName] = React.useState(false);

  const [documentName, setDocumentName] = React.useState("");
  const [documentType, setDocumentType] = React.useState("");
  
  const [uuid, setUUID] = React.useState();

  const fileInput = React.useRef(null);


  //redux
  const authenticateState = useSelector((state) => state.authenticate);
  const postState = useSelector((state) => state.post);
  const fileState = useSelector((state) => state.file);
  const dispatch = useDispatch();


  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
      description: selectedPost?.description,
      file: null,
      fileData: {
        name: selectedPost?.fileData?.name,
        type: selectedPost?.fileData?.name,
      },
      tags: selectedPost?.tags
    },

    validate: (values) => {
      const errors = {};
      if (!values.description) {
        errors.description = " This field is required";
      }
      if (values.tags.length === 0) {
        errors.tags = "Please select a tag, this helps people to easily locate your post.";
      }

      if(values.file && (values.file.size > 10000000)){
        errors.file = "The file selected cannot exceed 10mb";

      }
     

      return errors;
    },

    onSubmit: (values) => {
      const id = selectedPost?.id;
      setUUID(id);
      if(values.file){
        // const storageRef = ref(storage, `post_images/${id}`);
        // dispatch(uploadFileMiddleware(storageRef, values.file));

        let directory =  documentType=== FEEDTYPE.IMAGE ? "post_images" : documentType=== FEEDTYPE.VIDEO ? "post_videos" : "post_documents" ;
        
        dispatch(setUploadType("EDIT_POST"))
        dispatch(uploadFileToS3Middleware( values.file, directory));
        
      }else{

        const post = {
          id: selectedPost?.id,
          // userId: authenticateState.user_id,
          description: values.description,
          tags: values.tags,

          // createdBy:authenticateState.user,
          updatedAt: Date.now(),

        }
        dispatch( editPostMiddleware(id , post ))
      }
    },
  });

  React.useEffect(() => {

    if (fileState.ACTION_TYPE === uploadFileSuccess.toString() && fileState.UPLOAD_TYPE === "EDIT_POST") {
      const post = {
        id: selectedPost?.id,
        // userId: authenticateState.user_id,
        description: formik.values.description,
        tags: formik.values.tags,
        fileData:{name:documentName, type:documentType},
        fileUrl: fileState.uploadURL,

        // createdBy:authenticateState.user,
        updatedAt: Date.now(),
      }
      dispatch( editPostMiddleware(uuid, post) );
      dispatch(resetFileActionType())
    } else if (fileState.ACTION_TYPE === uploadFileError.toString()) {
      toast.error(fileState.uploadFileMessage);
      dispatch(resetFileActionType())
    }

  }, [fileState.ACTION_TYPE]);

  React.useEffect(() => {

    if (postState.ACTION_TYPE === editPostSuccess.toString()) {
      
      dispatch(resetActionType());
      toast.success("Post updated successfully");

      onHide();formik.resetForm();

    } else if (postState.ACTION_TYPE === editPostError.toString()) {
      toast.error(postState.editPostMessage);
      dispatch(resetActionType());
    }

  }, [postState.ACTION_TYPE]);

  return (
    <React.Fragment>
      <Dialog
        header={"Edit Post"}
        visible={visible}
        onHide={() => {
          onHide();
          formik.resetForm();
        }}
        className="w-11 md:w-8 lg:w-6"
        footer={() => <div />}
      >
        <div className="border-top-1 border-gray-300 py-3">
          {/* image and user name */}
          <div className="flex gap-3 align-items-center">
            <Image
              src={authenticateState.user?.profileImage ?? defaultImage}
              imageClassName="h-3rem w-3rem border-circle"
              imageStyle={{ objectFit: "cover" }}
            />
            <p className="text-xl font-medium">
              {authenticateState.user?.name} 
            </p>
          </div>
          {/* input body */}

          <div className='mt-3'>
            <TextArea
              className={`text-xl shadow-1 border-1 border-gray-300 w-full`}
              formik={formik}
              placeholder="What is on your mind?"
              id="description"
              label="What is on your mind?"
              value={formik.values.description}
              autoFocus
              onChange={formik.handleChange}
            />
          </div>
        </div>

        {/*add to post */}
        <div className="shadow-1 px-3 py-1 border-gray-300 border-1 border-round flex align-items-center justify-content-between">
          <div className="font-medium">
            {formik.values.file?.name ?? "Change Media"}
          </div>

          <div className="flex align-items-center justify-content-between gap-1">
            <Button
              className="p-button-sm p-button-text"
              onClick={() => {
                fileInput.current.accept = "video/*";
                fileInput.current.click();
                setDocumentType(FEEDTYPE.VIDEO);
                setShowDocumentName(false);
              }}
            >
              <FcVideoCall size={30} />
            </Button>

            <Button
              className="p-button-sm p-button-text"
              onClick={() => {
                fileInput.current.accept = "image/*";
                fileInput.current.click();
                setDocumentType(FEEDTYPE.IMAGE);
                setShowDocumentName(false);
              }}
            >
              <FcStackOfPhotos size={30} />
            </Button>

            <Button
              className="p-button-sm p-button-text"
              onClick={(e) => {
                fileInput.current.accept = ".doc, .docx,.ppt, .pptx,.txt,.pdf";
                e.preventDefault();
                setDocumentType(FEEDTYPE.DOCUMENT);
                fileInput.current.click();
                setShowDocumentName(true);
              }}
            >
              <FcDocument size={30} />
            </Button>
          </div>
        </div>
        <div className="mt-2">{getFormErrorMessage(formik, "file")}</div>


        {/* tags */}
        <div className="py-1 my-2 ">
          <div className="mt-3">Tags</div>
          <div className=" mt-2">
            <SelectButton
              
              options={[
                POSTTAGS.GENERALUPDATE,
                POSTTAGS.OPPORTUNITY,
                POSTTAGS.POLICY,
              ]}
              value={formik.values.tags}
              onChange={(e) => {
                formik.setFieldValue("tags", e.value);
              }}
              multiple
            />
            <div className="mt-2">{getFormErrorMessage(formik, "tags")}</div>
          </div>
        </div>

        {/* post button */}
        <Button
          className="p-button-sm p-button-raised mt-4 w-full"
          label="POST"
          type="submit"
          onClick={formik.handleSubmit}
          loading={postState.isAddPostLoading || fileState.isUploadFileLoading}
        />
      </Dialog>

      {/* hidden input node for file select */}
      {/* ---------------------------------------------------------------- */}
      <div className="hidden">
        <input
          type="file"
          id="file"
          ref={fileInput}
          // style={{ display: "none" }}
          onChange={(e) => {
            e.preventDefault();
            setDocumentName(e.target.files[0].name);
            formik.setFieldValue("file", e.target.files[0]);
          }}
        />
      </div>
      {/* ---------------------------------------------------------------- */}
    </React.Fragment>
  );
};
// ----------------------------------------------------------------

export default EditPostDialog;
