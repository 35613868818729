//  firebase auth
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";

import { auth } from "../firebase/firebase-config";

export const signUpWithEmailAndPasswordFirebase = async (email, password) => {

    try {
        
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        return userCredential;
        
    } catch (error) {
        throw error;
    }

}

export const signInWithEmailAndPasswordFirebase = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        return userCredential;
        
    } catch (error) {
        throw error;
    }

}

export const forgotPasswordFirebase = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
    } catch (error) {
        throw error;
    }
  
};

export const updatePassword = async (email, newPassword) => {

    if (!auth.currentUser) {
        throw "User must be signed in first";
    }

    await updatePassword(auth.currentUser, newPassword);
}



export const signOutFirebase = async () => {

    const userCredential = await signOut(auth);
    return userCredential;

}