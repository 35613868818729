import React from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";
import moment from "moment";
import { defaultImage } from "../../constants/IMAGES";
import { ProgressSpinner } from 'primereact/progressspinner';
import { useSelector } from "react-redux";
import { addCommentSuccess } from "../../redux/slices/PostSlice";

export default function Comments({ comments, inputRef, onAddComment, isGetCommentsLoading, isAddCommentLoading }) {
  const [comment, setComment] = React.useState("");
  const postState = useSelector((state) => state.post);

  React.useEffect(() => {
    if (postState.ACTION_TYPE === addCommentSuccess.toString()) {
      setComment("");
    }
  }, [postState.ACTION_TYPE]);

  return (
    <div className="bg-white border-round-lg py-4 px-5">
      <div className="flex gap-2">
        <InputText
          id="comment"
          ref={inputRef}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className="p-inputtext-sm border-round-3xl w-full bg-gray-200 px-3"
          placeholder="Add a comment..."
        />
        {isAddCommentLoading ? <ProgressSpinner style={{width: '40px', height: '40px'}}/> : 
        <Button
          disabled={!comment}
          className="p-button-sm border-round-2xl "
          icon="pi pi-comment"
          tooltip="add comment"
          onClick={() => onAddComment(comment)}
        />}
      </div>

      <div className="mt-3">
        <span className="mb-3 text-lg">Comments</span>

        <div className="flex flex-column gap-4 mt-3">
          {isGetCommentsLoading ?
            <ProgressSpinner  /> :

            comments?.length === 0 ?

              <h5>No comments yet, be the first to add a comment</h5> :
              
              comments?.map((item, index) => (
                <div key={index.toString()} className="flex gap-2">
                  <Image
                    src={item?.createdBy?.profileImage ?? defaultImage}
                    imageClassName="h-2rem w-2rem border-circle"
                    imageStyle={{ objectFit: "cover" }}
                  />

                  <div>
                    <div className="flex flex-column bg-gray-200 py-2 px-4 border-round">
                      <div className="text-xs  mb-2 flex gap-5 justify-content-between">
                        <span className="font-semibold"> {item?.createdBy?.name}</span>
                        <span className="font-light">
                          {moment(item.createdAt).fromNow()}
                        </span>
                      </div>
                      <span>{item.comment}</span>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}
