import React from "react";
import { Image } from "primereact/image";

export const NewChatItemComponent = ({ item, onItemClick }, key) => {
 

  return (
    <div
      className="px-3 py-1 border-bottom-1 border-gray-200 cursor-pointer flex align-items-center gap-2"
      onClick={onItemClick}
      key={key}
    >
      <Image
        imageClassName="bg-gray-200 border-circle"
        imageStyle={{
          height: 45,
          width: 45,
          objectFit: "cover",
        }}
        src={item.profileImage}
      />

      <div className="text-xs white-space-nowrap overflow-hidden text-overflow-ellipsis">
        {item.name}
      </div>
    </div>
  );
};
