import React from "react";
import { If } from "@kanzitelli/if-component";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { NotificationItem } from "../../components/customs/Notifications";
import empty from "../../assets/icons/notification-bell.png";
import { toast } from "react-toastify";

// wrappers
import {
  MainWithSideWrapper as Wrapper,
  MainWithSide as Main,
  Side,
} from "../../components/containers/Wrappers";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationsStart,
  getNotificationsSuccess,
  setUnreadNotificationCount,
} from "../../redux/slices/NotificationSlice";
import { getFirebaseDocs } from "../../util/firebaseFirestoreUtils";
import { onSnapshot } from "firebase/firestore";

export default function NotificationsPage() {
  const dispatch = useDispatch();
  const notificationState = useSelector((state) => state.notification);
  const lastVisibleDocSnapshot = React.useRef();

  const activeStyle =
    "p-button-sm text-sm px-3 py-1 border-round-xl bg-primary-100 border-none text-primary";
  const inactiveStlye =
    "p-button-sm px-3 py-1 border-round-xl p-button-text border-none text-color";
  const [view, setView] = React.useState("all");

  let data = notificationState.notifications;

  return (
    <Wrapper>
      <Main className="pb-8 ">
        <div className="p-3 md:p-5  bg-white border-round my-3 flex flex-column ">
          <section className="flex justify-content-between align-items-center w-full">
            <h3 className="text-lg md:text-3xl font-bold m-0">Notifications</h3>
          </section>
          {/* ------------------ VIEW TYPE TOGGLE STARTS -------------- */}
          <section className="flex gap-3 mt-3 mb-4">
            <Button
              className={`${view === "all" ? activeStyle : inactiveStlye}`}
              label="All"
              onClick={() => setView("all")}
            />
            <Button
              className={`${view === "unread" ? activeStyle : inactiveStlye}`}
              label="Unread"
              onClick={() => setView("unread")}
            />
          </section>
          {/* ------------------ VIEW TYPE TOGGLE ENDS -------------- */}
          {/* ------------------ ALL LIST STARTS -------------- */}
          <If
            _={view === "all"}
            _then={
              <If
                _={data.length > 0}
                _then={
                  <section className="flex flex-column gap-4 w-full">
                    {data.map((item) => (
                      <NotificationItem
                        key={item.id}
                        data={item}
                        onItemClick={() => alert(item.id)}
                        fullMessage
                        onMarkAsRead={() => toast.warning("Marked as read")}
                        onDelete={() => toast.error("Deleted")}
                      />
                    ))}
                  </section>
                }
                _else={
                  <section className="flex flex-column  w-full justify-content-center align-items-center">
                    <Image src={empty} height={100} width={100} />
                    <p className="text-center">No notifications to sho</p>
                  </section>
                }
              />
            }
          />
          {/* ------------------ ALL LIST ENDS -------------- */}
          {/* 
          the idea is to show the most recent 5 notifications 
       */}
          {/* ------------------ UNREAD LIST STARTS -------------- */}
          <If
            _={view === "unread"}
            _then={
              <If
                _={
                  data.length > 0 &&
                  data.filter((item) => item?.isRead === false).length > 0
                }
                _then={
                  <section className="flex flex-column gap-4 w-full">
                    {data
                      .filter((item) => item?.isRead === false)
                      .slice(0, 5)
                      .map((item) => (
                        <NotificationItem
                          key={item.id}
                          data={item}
                          onItemClick={() => alert(item.id)}
                          fullMessage
                          onMarkAsRead={() => toast.warning("Marked as read")}
                          onDelete={() => toast.error("Deleted")}
                        />
                      ))}
                  </section>
                }
                _else={
                  <section className="flex flex-column  w-full justify-content-center align-items-center">
                    <Image src={empty} height={100} width={100} />
                    <p className="text-center">No notifications to show</p>
                  </section>
                }
              />
            }
          />
          {/* ------------------ UNREAD LIST ENDS -------------- */}
        </div>
      </Main>
      <Side classsName="hidden" />
    </Wrapper>
  );
}
