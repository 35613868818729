import React from "react";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { useFormik } from "formik";

// wrappers
import { Row } from "../containers/Wrappers";

// dailogs
import { EditBusinessInformationDialog } from "../dialogs/EditBusinessInformationDialog";
import { useSelector } from "react-redux";
import moment from "moment";

export const ViewBusinessInformationBlock = (props) => {
  const { isCurrentUser } = props;

  const userState = useSelector((state) => state.user);

  const user = {
    sector: userState.user.sector ?? "N/A",
    registeredSince: userState.user?.registeredSince,
    branches: userState.user.branches ?? "N/A",
    greenYears: userState.user.greenYears ?? "N/A",
    industry: userState.user?.industry ?? "N/A",
    businessType: userState.user?.businessType ?? "N/A",
    scale: userState.user.scale ?? "N/A",
    employees: userState.user?.employees ?? "N/A",
  };

  const [showEdit, setShowEdit] = React.useState(false);

  return (
    <div className="mt-4 p-3 bg-white">
      <div className="flex justify-content-between align-items-center">
        <h2>Business Information</h2>

        {(!props.isGuest && isCurrentUser) && (
          <Button
            icon="pi pi-pencil"
            className="p-button-text"
            onClick={() => {
              setShowEdit(true);
            }}
          />
        )}
      </div>

      <Row>
        <Item label="Sector" value={user?.sector ?? "N/A"} />
        <Item
          label="Registered"
          value={ (user?.registeredSince) ?  new Date(user.registeredSince).toDateString() : "No Date Provided " }
        />
        <Item label="Branches" value={user?.branches ?? "N/A"} />
        <Item
          label="Green Years"
          value={user?.greenYears + " year(s)" ?? "N/A"}
        />
        <Item label="Industry" value={user?.industry ?? "N/A"} />
        <Item label="Business Type" value={user?.businessType ?? "N/A"} />
        <Item label="Scale" value={user?.scale ?? "N/A"} />
        <Item label="Employees" value={user?.employees ?? "N/A"} />
      </Row>

      {/* Dialog */}
      {/* ---------------------------------------------------------------- */}
      {(!props.isGuest && isCurrentUser) && (
        <EditBusinessInformationDialog
          visible={showEdit}
          onHide={() => setShowEdit(false)}
        />
      )}
      {/* ---------------------------------------------------------------- */}
    </div>
  );
};

const Item = ({ label, value }) => {
  return (
    <div className="flex align-items-center gap-2 w-12">
      <Chip label={label} className="border-round-sm" />
      <div className="text-lg m-1">{value}</div>
    </div>
  );
};
