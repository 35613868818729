import React from "react";
import { useFormik } from "formik";
import { Button } from "primereact/button";

import { toast, ToastContainer } from "react-toastify";

//redux
import { useSelector, useDispatch } from "react-redux";
import { signupSuccess, signupError, resetActionType } from "../../redux/slices/AuthenticateSlice";
import { signupMiddleware } from '../../redux/middlewares/AuthenticateMiddleware'

import {
  Input,
  CountrySelect,
  SelectSingle,
  PasswordInput,
} from "./CustomInputs";
import { USERTYPE } from "../../constants/USERTYPE";
import { goToAppPage } from "../../redux/slices/NavigationSlice";


export default function PolicyMakerRegistrationForm(props) {

  const dispatch = useDispatch();
  const authenticateState = useSelector((state) => state.authenticate);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      country: { name: "Ghana", code: "GH" },
    },

    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "Email is required.";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address. E.g. example@email.com";
      }

      if (!values.name) {
        errors.name = "Name is required";
      }

      if (!values.password) {
        errors.password = "Password is required";
      }

      if (!values?.country?.code) {
        errors.country = "Country is required";
      }

      if (values.password !== values.confirmPassword) {

        errors.password = "Passwords do not match";
        errors.confirmPassword = "Passwords do not match";

      }
      
      return errors;
    },

    onSubmit: (values) => {
      const name = values.name;
      const email = values.email;
      const password = values.password;

      const country = values.country;
      const userType = USERTYPE.POLICY_MAKER;
      const profileImage = "https://as1.ftcdn.net/v2/jpg/03/46/83/96/1000_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg";

      dispatch(signupMiddleware(email, password, { name, profileImage, country, userType }));

    },
  });


  React.useEffect(() => {
    if (authenticateState.ACTION_TYPE === signupSuccess.toString()) {
      toast.success("signup successfull");
      dispatch(goToAppPage());
      dispatch(resetActionType());
    } else if (authenticateState.ACTION_TYPE === signupError.toString()) {
      toast.error(authenticateState.signupMessage);
    }

    dispatch(resetActionType());
  }, [authenticateState.ACTION_TYPE]);
  return (
    <form
      onSubmit={formik?.handleSubmit}
      className="w-full flex flex-column py-5"
    >

      <Input
        formik={formik}
        id={"name"}
        label={"Name of Policy Maker*"}
        icon="pi pi-user"
        value={formik.values.name}
        errorId="name"
      />

      <CountrySelect
        formik={formik}
        id="country"
        label="Select Country"
        value={formik.values.country}
      />

      <Input
        formik={formik}
        id={"email"}
        label={"Email*"}
        icon="pi pi-envelope"
        value={formik.values.email}
      />

      <PasswordInput
        formik={formik}
        id={"password"}
        label={"Password*"}
        feedback={true}
        value={formik.values.password}
      />

      <PasswordInput
        formik={formik}
        id={"confirmPassword"}
        label={"Confirm Password*"}
        feedback={true}
        value={formik.values.confirmPassword}
      />

      <div className="p-fluid w-11 my-5 ">
        <Button
          type="submit"
          label="SIGN UP"
          loading={authenticateState.isSignupLoading}
          className="p-button-raised"
        />
      </div>
    </form>
  );
}






