import React from "react";
import { Link } from "react-router-dom";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { useNavigate } from "react-router-dom";

import { useBreakpoints } from "react-breakpoints-hook";

// logo
import logo from "../../assets/logo.svg";
// import eca from "../../assets/eca.png";

//redux
import { useSelector, useDispatch } from "react-redux";
import { defaultImage } from "../../constants/IMAGES";
import Notifications from "../customs/Notifications";

export default function Navbar({ username, showMenu }) {
  const dispatch = useDispatch();
  const authenticateState = useSelector((state) => state.authenticate);
  const notificationState = useSelector((state) => state.notification);
  const overlayPanelRef = React.useRef(null);

  let { lg } = useBreakpoints({
    lg: { min: 1101, max: null },
  });

  const navigate = useNavigate();


  return (
    <div
      className="bg-white py-1 lg:px-5 md:px-3 px-2 flex align-items-center justify-content-between shadow-md w-full fixed"
      style={{ zIndex: 1 }}
    >
      <div className="flex gap-2 align-items-center">
        <Button
          className={`b-button-sm p-button-text ${lg ? "hidden" : "flex"} `}
          icon="pi pi-bars text-xl"
          onClick={showMenu}
        />
        <div className="flex align-items-center justify-content-center">
          <Image
            imageClassName="cursor-pointer"
            imageStyle={{ objectFit: "cover" }}
            src={logo}
            height={30}
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      </div>

      <div className="flex gap-2 flex align-items-center ">
        {/* Button with badge to show notification count */}
        <Button
          className="p-button-small p-button-text px-0 py-1"
          icon="pi pi-bell text-3xl"
          badge={notificationState.unreadNotificationCount}
          badgeClassName="p-badge-success top-0 right-0 absolute"
          onClick={(e) => overlayPanelRef.current.toggle(e)}
        />
        <OverlayPanel ref={overlayPanelRef} className="border-1 border-gray-300 w-10 md:w-7 lg:w-5 z-5">
          {/* main notification component */}
          <Notifications
            toggleMenu={(e) => overlayPanelRef.current.toggle(e)}
          />
        </OverlayPanel>
        <Link to={`/profile/${authenticateState.user_id}`}>
          <Image
            src={authenticateState.user?.profileImage ?? defaultImage}
            imageClassName="border-circle"
            imageStyle={{
              objectFit: "cover",
              height: "2.5rem",
              width: "2.5rem",
            }}
          />
        </Link>
        {/* <span className="hidden md:flex">{authenticateState.user?.name}</span> */}
      </div>
    </div>
  );
}
