import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
    ACTION_TYPE: "",

    isSendMessageLoading: false,
    isGetRecentChatsLoading: false,

    sendMessageMessage: "",
    getRecentChatsMessage: "",


    recentChats: [],


}

export const ChatSlice = createSlice({
    name: 'post',
    initialState: INITIAL_STATE,
    reducers: {

        //send message 
        sendMessageStart: state => {
            state.ACTION_TYPE = sendMessageStart.toString();
            state.isSendMessageLoading = true;
            state.sendMessageMessage = "";

        },
        sendMessageSuccess: (state, action) => {
            state.ACTION_TYPE = sendMessageSuccess.toString();
            state.isSendMessageLoading = false;
            state.sendMessageMessage = "";
        },
        sendMessageError: (state, action) => {
            state.ACTION_TYPE = sendMessageError.toString();
            state.isSendMessageLoading = false;
            state.sendMessageMessage = action.payload.message;
        },


        //get recent chats
        getRecentChatsStart: state => {
            state.ACTION_TYPE = getRecentChatsStart.toString();
            state.isGetRecentChatsLoading = true;
            state.getRecentChatsMessage = "";

        },
        getRecentChatsSuccess: (state, action) => {
            state.ACTION_TYPE = getRecentChatsSuccess.toString();
            state.isGetRecentChatsLoading = false;
            state.getRecentChatsMessage = "";
            state.recentChats = action.payload;
        },
        getRecentChatsError: (state, action) => {
            state.ACTION_TYPE = getRecentChatsError.toString();
            state.isGetRecentChatsLoading = false;
            state.getRecentChatsMessage = action.payload.message;
        },

        

        //reset 
        resetActionType: state => {
            state.ACTION_TYPE = resetActionType.toString();
        },

    }
})

// Action creators are generated for each case reducer function
export const
    {
        sendMessageStart, sendMessageSuccess, sendMessageError,
        getRecentChatsStart, getRecentChatsSuccess, getRecentChatsError,

        resetActionType
    } = ChatSlice.actions

export default ChatSlice.reducer