import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  ACTION_TYPE: "",
  UPLOAD_TYPE: "", // to prevent accidental uploadFileSuccess triggers from other pages

  isUploadFileLoading: false,

  uploadFileMessage: "",

  uploadProgress:0,
  uploadURL:"",

}

export const AuthenticateSlice = createSlice({
  name: 'file',
  initialState: INITIAL_STATE,
  reducers: {

   

     //upload file
     uploadFileStart: state => {
      state.ACTION_TYPE = uploadFileStart.toString();
      state.isUploadFileLoading = true;
      state.uploadFileMessage = "";
      state.uploadProgress = 0;

    },

    uploadFileSuccess: (state, action) => {
      state.ACTION_TYPE = uploadFileSuccess.toString();
      state.isUploadFileLoading = false;
      state.uploadFileMessage = "File uploaded successfully";
      state.uploadURL = action.payload.path;

    },

    uploadFileError: (state, action) => {
      state.ACTION_TYPE = uploadFileError.toString();
      state.isUploadFileLoading = false;
      state.uploadFileMessage = action.payload.message;
    },

    setUploadProgress: (state, action) => {
      state.ACTION_TYPE = setUploadProgress.toString();
      state.uploadProgress = action.payload.progress;
    },

    setUploadType: (state, action) => {
      state.UPLOAD_TYPE = action.payload;
    },

    //reset 
    resetActionType: state => {
      state.ACTION_TYPE = resetActionType.toString();
      state.UPLOAD_TYPE = resetActionType.toString();
    },

  }
})

// Action creators are generated for each case reducer function
export const
  {
    uploadFileStart, uploadFileSuccess, uploadFileError,setUploadProgress,
    resetActionType, setUploadType
  } = AuthenticateSlice.actions

export default AuthenticateSlice.reducer