import React from "react";
import { useBreakpoints } from "react-breakpoints-hook";

import { useSelector, useDispatch } from "react-redux";

import { MenuButton } from "./MenuButton";
import { logOutMiddleware } from "../../redux/middlewares/AuthenticateMiddleware";

export default function SideLeft(props) {
  const { width = "320px" } = props;

  let { lg } = useBreakpoints({
    lg: { min: 1101, max: null },
  });

  const dispatch = useDispatch();
  const authenticateState = useSelector((state) => state.authenticate);

  return (
    <div
      className={`${lg ? "flex" : "hidden"} stickScroll flex-column px-2 py-3 ${
        props.height
      }`}
      style={{
        width: width,
      }}
    >
      <MenuButton icon="pi pi-home" label="Home" to="/" />

      <MenuButton
        icon="pi pi-user"
        label="Profile"
        to={`profile/${authenticateState.user_id}`}
      />

      <MenuButton icon="pi pi-search" label="Search" to="/search/top" />

      <MenuButton
        icon="pi pi-bell"
        label="Notifications"
        to="/notifications"
        onMenuClick={props.hideMenu}
      />

      <MenuButton icon="pi pi-send" label="Messages" to="/chat" />

      <MenuButton
        icon="pi pi-home"
        label="About"
        onMenuClick={() => window.open("https://justis.africa/about", "_self")}
      />

      <MenuButton
        icon="pi pi-sign-out"
        label="Logout"
        onMenuClick={() => dispatch(logOutMiddleware())}
      />
    </div>
  );
}
