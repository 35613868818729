import {
  forgotPasswordError, forgotPasswordStart,
  forgotPasswordSuccess, loginError, loginStart,
  loginSuccess, logOutError, logOutStart,
  logOutSuccess, signupError, signupStart,
  signupSuccess, updateUserProfileError, updateUserProfileStart,
  updateUserProfileSuccess
} from "../slices/AuthenticateSlice";

import {
  goToAppPage, goToLoginPage
} from "../slices/NavigationSlice";

//  firebase auth
import { auth } from "../../firebase/firebase-config";

import {
  forgotPasswordFirebase,
  signInWithEmailAndPasswordFirebase,
  signUpWithEmailAndPasswordFirebase
} from "../../util/firebaseAuthUtils";

import {
  onAuthStateChanged, signOut
} from "firebase/auth";
import { FIREBASECOLLECTION } from "../../constants/FIREBASECOLLECTIONS";
import { getFirebaseDoc, setFirebaseDoc, updateFirebaseDoc } from "../../util/firebaseFirestoreUtils";

// app authentication middleware -> handles app entry point
export const appAuthenticationMiddleware = () => {
  return async (dispatch) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch( goToAppPage())
        return;
      } else {
        dispatch(goToLoginPage());
        return;
      }
    });
  };
};

/**
 *
 * Call this middleware when logining new users
 * @param {*} email
 * @param {*} password
 * @returns
 */
export const loginMiddleware = (email, password) => {
  return async (dispatch) => {
    dispatch(loginStart());
    try {

      const userCredentials = await signInWithEmailAndPasswordFirebase(email, password);
      
      const userObject = await getFirebaseDoc(`${FIREBASECOLLECTION.USERS}/${userCredentials.user.uid}`);

      //get from user collection and save to redux
      dispatch(loginSuccess({user_uid: userCredentials.user.uid , user: userObject.data()}));
      // dispatch( getUserSuccess(userObject ));

      //save to localstorage
      localStorage.setItem("user_id",userCredentials.user.uid);

    } catch (error) {
      const errorCode = error.code;
      dispatch(loginError({ message: errorCode ?? error }));
    }
  };
};

export const signupMiddleware = (email, password, otherDetails) => {
  return async (dispatch) => {
    dispatch(signupStart());
    try {

      const userCredentials = await signUpWithEmailAndPasswordFirebase(email, password);

      const id = userCredentials.user.uid;
      //get other details and store to user collection
      setFirebaseDoc( `${FIREBASECOLLECTION.USERS}/${id}`, { ...otherDetails, email, id});

      //get from user collection and save to redux
      dispatch(signupSuccess({user_uid: userCredentials.user.uid , user:{ ...otherDetails, email}}));
      // dispatch( getUserSuccess( { ...otherDetails, email} ));
      
    } catch (error) {
      const errorCode = error.code;
      dispatch(signupError({ message: errorCode ?? error }));
    }
  };
};


/**
 * Send a password reset link
 * @param {*} email
 * @returns
 */
export const forgotPasswordMiddleware = (email) => {
  return async (dispatch) => {
    dispatch(forgotPasswordStart());

    try {
      await forgotPasswordFirebase(email);
      dispatch(forgotPasswordSuccess({}));
      
      
    } catch (error) {
      // console.log(error);
      // const errorCode = error.code;
      dispatch(forgotPasswordError({ message: error.code }));
      
    }
  };
};


export const updateUserProfileMiddleware = ( userObject) => {
  return async (dispatch) => {
    dispatch(updateUserProfileStart());

    if (!auth.currentUser) {
      dispatch(updateUserProfileError({ message: "User must be signed in first" }));
      return;
    }
    
    try {
      updateFirebaseDoc(`${FIREBASECOLLECTION.USERS}/${auth.currentUser.uid}`, userObject)
      dispatch(updateUserProfileSuccess());
    } catch (error) {
      const errorCode = error.code;
      // const errorMessage = error.message;
      dispatch(updateUserProfileError({ message: errorCode }));
    }
  };
};



export const logOutMiddleware = () => {
  return async (dispatch) => {
    dispatch(logOutStart());
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        dispatch(logOutSuccess());
        localStorage.removeItem("user_id");
        dispatch(goToLoginPage());
      })
      .catch((error) => {
        // An error happene.
        dispatch(logOutError({ message: error.code }));
      });
  };
};
