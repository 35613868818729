import { combineReducers } from "redux";
import sidebarSlice from "./SidebarSlice";
import AuthenticateSlice from "./AuthenticateSlice";
import UserSlice from "./UserSlice";
import PostSlice from "./PostSlice";
import FileSlice from "./FileSlice";
import SearchSlice from "./SearchSlice";
import ChatSlice from "./ChatSlice";
import NavigationSlice from "./NavigationSlice";
import NotificationSlice from "./NotificationSlice";


export default combineReducers({
  sidebar: sidebarSlice,
  authenticate: AuthenticateSlice,
  file: FileSlice,
  user: UserSlice,
  post: PostSlice,
  search: SearchSlice,
  chat: ChatSlice,
  notification: NotificationSlice,
  navigation: NavigationSlice,
});
