import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { MenuButton } from "./MenuButton";
import { logOutMiddleware } from "../../redux/middlewares/AuthenticateMiddleware";

export default function SideMenu(props) {
  const dispatch = useDispatch();
  const authenticateState = useSelector((state) => state.authenticate);

  return (
    <div className={`flex-column px-2 py-3`}>
      <MenuButton
        icon="pi pi-home"
        label="Home"
        to="/"
        onMenuClick={props.hideMenu}
      />

      <MenuButton
        icon="pi pi-user"
        label="Profile"
        to={`profile/${authenticateState.user_id}`}
        onMenuClick={props.hideMenu}
      />

      <MenuButton
        icon="pi pi-search"
        label="Search"
        to="/search"
        onMenuClick={props.hideMenu}
      />

      <MenuButton
        icon="pi pi-bell"
        label="Notifications"
        to="/notifications"
        onMenuClick={props.hideMenu}
      />

      <MenuButton
        icon="pi pi-send"
        label="Messages"
        to="/chat"
        onMenuClick={props.hideMenu}
      />

      <MenuButton
        icon="pi pi-home"
        label="About"
        onMenuClick={() => {
          window.open("https://justis.africa/about", "_self");
        }}
      />

      <MenuButton
        icon="pi pi-sign-out"
        label="Logout"
        onMenuClick={() => {
          dispatch(logOutMiddleware());
          props.onMenuClick();
        }}
      />
    </div>
  );
}
