import React from "react";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { useLocation, useNavigate } from "react-router-dom";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import { useBreakpoints } from "react-breakpoints-hook";
import { InputTextarea } from "primereact/inputtextarea";
import { ContextMenu } from "primereact/contextmenu";
import { SpeedDial } from "primereact/speeddial";
import { Tooltip } from "primereact/tooltip";
import Lottie from "react-lottie";
import * as image from "../../assets/lottie/send.json";
import FlatList from "flatlist-react";
import { MessageItem } from "../../components/chat/MessageItem";

import {
  Container,
  RecentChatWrapper,
  MessagesWrapper,
  TopWrapper,
  SendWrapper,
} from "../../components/containers/ChatLayout";

import {
  RecentChat,
  RecentChatSmallScreen,
} from "../../components/chat/RecentChat";
import { NewChat } from "../../components/chat/NewChatDialog";
import { defaultImage } from "../../constants/IMAGES";

import { useDispatch, useSelector } from "react-redux";
import {
  sendMessageMiddleware,
} from "../../redux/middlewares/ChatMiddleware";
import { getFirebaseDocs } from "../../util/firebaseFirestoreUtils";
import { FIREBASECOLLECTION } from "../../constants/FIREBASECOLLECTIONS";
import { onSnapshot, where } from "firebase/firestore";
import { generateChatRoomId } from "../../util/helperFunctions";
import {
  getRecentChatsError,
  getRecentChatsStart,
  getRecentChatsSuccess,
  resetActionType,
  sendMessageSuccess
} from "../../redux/slices/ChatSlice";
import { addNotificationMiddleware } from "../../redux/middlewares/NotificationMiddleware";

export default function ChatPage() {
  const authenticateState = useSelector((state) => state.authenticate);
  const chatState = useSelector((state) => state.chat);
  const dispatch = useDispatch();

  const { state } = useLocation();

  const navigate = useNavigate();
  const location = useLocation();
  const cm = React.useRef(null);

  const [chatMessage, setChatMessage] = React.useState("");
  const [showRecentChatDialog, setShowRecentChatDialog] = React.useState(false);
  const [showNewChatDialog, setShowNewChatDialog] = React.useState(false);

  const [chats, setChats] = React.useState([]);

  const last = React.useRef();

  let { xs, sm } = useBreakpoints({
    xs: { min: 0, max: 360 },
    sm: { min: 361, max: 960 },
  });
  const items = [
    // {
    //   label: "Clear messages",
    // },
    {
      separator: true,
    },
    {
      label: "Close Chat",
      command: () => navigate(location.pathname, {}),
    },
  ];

  const menu = [
    {
      label: "Recent Chat",
      icon: "pi pi-comments",
      command: () => {
        setShowRecentChatDialog(!showRecentChatDialog);
      },
    },
    {
      label: "New Chat",
      icon: "pi pi-plus-circle",
      command: () => {
        setShowNewChatDialog(!showNewChatDialog);
      },
    },
  ];

  // scroll last message into view
  React.useEffect(() => {
    last.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [state, chatMessage]);

  //realtime room listener
  React.useEffect(() => {
    // if(!state) return;

    dispatch(getRecentChatsStart());

    const roomsRef = getFirebaseDocs(`${FIREBASECOLLECTION.ROOMS}`);
    const unsubscribeListener = onSnapshot(roomsRef, (querySnapshot) => {
      try {
        const rooms = [];
        querySnapshot.forEach((doc) => {
          //FILTER room for authenticated user
          let roomObject = doc.data();
          if (
            roomObject.to.id === authenticateState.user.id ||
            roomObject.from.id === authenticateState.user.id
          ) {
            rooms.push(doc.data());
          }
        });

        dispatch(getRecentChatsSuccess(rooms));
      } catch (error) {
        const errorCode = error.code;
        console.log(error);
        dispatch(getRecentChatsError(error));
      }
    });

    return () => {
      console.log("unsubscribeListener");
      unsubscribeListener();
    };
  }, []);

  //realtime chat messages listener
  React.useEffect(() => {
    console.log("---------state----------------");
    console.log(state);

    if (!state) return;

    const ROOM_ID = generateChatRoomId(
      state?.recipient?.email,
      authenticateState.user.email
    );

    const messagesRef = getFirebaseDocs(
      `${FIREBASECOLLECTION.ROOMS}/${ROOM_ID}/${FIREBASECOLLECTION.MESSAGES}`,
      [where("roomId", "==", ROOM_ID)]
    );

    const unsubscribeListener = onSnapshot(messagesRef, (querySnapshot) => {
      try {
        const chatts = [];
        querySnapshot.forEach((doc) => {
          chatts.push(doc.data());
        });
        setChats(chatts);
        console.log(chatts);
      } catch (error) {
        const errorCode = error.code;
        console.log(error);
      }
    });

    return () => {
      console.log("unsubscribeListener");
      unsubscribeListener();
    };
  }, [state]);

  //send message function
  const sendMessage = React.useCallback(() => {
    const ROOM_ID = generateChatRoomId(
      state?.recipient?.email,
      authenticateState.user.email
    );

    const time = Date.now().toString();
    let data = {
      roomId: ROOM_ID,
      to: {
        id: state.recipient.id,
        name: state.recipient.name,
        email: state.recipient.email,
        profileImage: state.recipient.profileImage,
      },
      from: {
        id: authenticateState.user.id,
        name: authenticateState.user.name,
        email: authenticateState.user.email,
        profileImage: authenticateState.user.profileImage,
      },
      message: chatMessage,
      createdAt: time,
    };

    dispatch(sendMessageMiddleware(ROOM_ID, data));
    // setChatMessage("");
  }, [state, chatMessage]);

  //----on message sent success-------
  React.useEffect(()=>{
    
    if( chatState.ACTION_TYPE === sendMessageSuccess.toString()){

      //------SEND NOTIFICATION FOR MESSAGE SENT------------
      const notificationId = Date.now().toString();
      const newNotificationObject = {
          id: notificationId,
          sentFromId: authenticateState.user_id,
          sentToId: state?.recipient?.id ,
          title: "Sent you a message",
          message: chatMessage,
          createdAt: Date.now().toString(),
          isRead: false,
          sentFrom: {
              name: authenticateState.user?.name,
              imageUrl: authenticateState?.user?.profileImage,
          },
          sentTo: {
              name: state?.recipient?.name,
              imageUrl: state?.recipient?.profileImage,
          },
      };
      dispatch (addNotificationMiddleware(notificationId, newNotificationObject));
      
      setChatMessage("");
      dispatch(resetActionType());
    }
  },[chatState.ACTION_TYPE]);
  return (
    <Container>
      {/* side */}
      {/* shows only for large screen */}
      {/* --------------------------------------------------------- */}
      <RecentChatWrapper>
        <TopWrapper className="flex align-items-center justify-content-between">
          <div className={`${xs ? "hidden" : sm ? "hidden" : "block"}`}>
            Recent Chat
          </div>
          <Button
            className="p-button-sm p-button-text"
            onClick={() => setShowNewChatDialog(true)}
          >
            <BsFillChatLeftTextFill size={17.5} />
          </Button>
        </TopWrapper>

        {/* Recent Chat */}
        {/* ------------------------------------------------------------------------------ */}
        <RecentChat
          onNewChat={() => setShowNewChatDialog(true)}
          data={chatState.recentChats}
        />
        {/* ------------------------------------------------------------------------------ */}
      </RecentChatWrapper>
      {/* --------------------------------------------------------- */}

      {/* chat */}
      {/* --------------------------------------------------------- */}
      <MessagesWrapper>
        {state === null ? (
          <div className={`flex flex-column align-items-center gap-5`}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: image,
              }}
              height={"50%"}
            />

            <div>Start a new chat by clicking the message icon</div>
          </div>
        ) : (
          <React.Fragment>
            {/* Top Header section */}
            {/* ---------------------------------------------------------------- */}
            <TopWrapper className="flex align-items-center justify-content-between">
              <div className="flex align-items-center gap-2 px-1">
                <Image
                  src={state?.recipient?.profileImage ?? defaultImage}
                  imageClassName="h-2rem w-2rem border-circle"
                  imageStyle={{ objectFit: "cover" }}
                />

                <div>{state?.recipient?.name}</div>
              </div>

              <Button
                className="p-button-sm p-button-text"
                icon="pi pi-ellipsis-v"
                onClick={(e) => cm.current.show(e)}
              />
              <ContextMenu model={items} ref={cm} />
            </TopWrapper>
            {/* ---------------------------------------------------------------- */}
            <div className="col h-screen overflow-auto overflow-x-hidden relative">
              <FlatList
                list={chats}
                renderWhenEmpty={() => <div></div>}
                renderItem={(item, index) => (
                  <MessageItem
                    item={item}
                    key={index}
                    currentUserId={authenticateState.user.id}
                  />
                )}
              />
              <div ref={last} />
            </div>
            {/* send message */}
            {/* ---------------------------------------------------------------- */}
            <SendWrapper>
              <InputTextarea
                rows={1}
                cols={30}
                autoResize
                className="bg-gray-200 w-12 mb-5 max-h-10rem"
                value={chatMessage}
                onChange={(e) => setChatMessage(e.target.value)}
              />

              <Button
                loading={chatState.isSendMessageLoading}
                onClick={() => {
                  sendMessage();
                }}
                disabled={!chatMessage}
                icon="pi pi-send"
              />
            </SendWrapper>
            {/* ---------------------------------------------------------------- */}
          </React.Fragment>
        )}
      </MessagesWrapper>
      {/* --------------------------------------------------------- */}

      {/* FAB ICON FOR SMALL SCREEN */}
      {/* --------------------------------------------------------- */}
      <div
        className="speeddial-tooltip-demo  block lg:hidden"
        style={{ position: "absolute", left: 10, bottom: 270 }}
      >
        <Tooltip
          target=".speeddial-tooltip-demo .speeddial-right .p-speeddial-action"
          position="right"
        />

        <SpeedDial
          model={menu}
          direction="up"
          transitionDelay={80}
          showIcon="pi pi-th-large text-2xl"
          hideIcon="pi pi-times"
          className="speeddial-right"
          buttonClassName="bg-black-alpha-50 text-white border-white"
        />

        <RecentChatSmallScreen
          data={chatState.recentChats}
          visible={showRecentChatDialog}
          onHide={() => setShowRecentChatDialog(false)}
          onNewChat={() => setShowNewChatDialog(true)}
        />
      </div>
      {/* --------------------------------------------------------- */}

      {/* NEW CHAT  */}
      {/* --------------------------------------------------------- */}
      <NewChat
        visible={showNewChatDialog}
        onHide={() => setShowNewChatDialog(false)}
      />
      {/* --------------------------------------------------------- */}
    </Container>
  );
}
