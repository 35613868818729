import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

// logo
import logo from "../../assets/logo.svg";

import { getFormErrorMessage, isFormFieldValid } from "../../util/formikUtils";

//redux
import { useDispatch, useSelector } from "react-redux";
import { loginMiddleware } from "../../redux/middlewares/AuthenticateMiddleware";
import {
  loginError,
  loginSuccess,
  resetActionType,
} from "../../redux/slices/AuthenticateSlice";
import { goToAppPage } from "../../redux/slices/NavigationSlice";

export default function LoginPage(props) {
  const dispatch = useDispatch();
  const authenticateState = useSelector((state:any) => state.authenticate);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validate: (values) => {
      const errors: {
        email?: string,
        password?:string,
      } = {};
      
      if (!values.email) {
        errors.email = "Email is required.";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address. E.g. example@email.com";
      }

      if (!values.password) {
        errors.password = "Password is required";
      }
      return errors;
    },

    onSubmit: (values) => {
      dispatch<any>(loginMiddleware(values.email, values.password));
    },
  });

  React.useEffect(() => {
    if (authenticateState.ACTION_TYPE === loginSuccess.toString()) {
      dispatch(goToAppPage());
      dispatch(resetActionType());
    } else if (authenticateState.ACTION_TYPE === loginError.toString()) {
      toast.error(authenticateState.loginMessage);
      dispatch(resetActionType());
    }
  }, [authenticateState.ACTION_TYPE]);

  return (
    <main className="h-screen relative overflow-y-scroll">
      <div className="h-full relative flex">
        <div className="h-full col-12 lg:col-6 p-3 md:p-5 lg:py-8 flex flex-column">
          <div className="w-12 md:w-10 lg:w-9 align-self-center">
            <div className="mt-3">
              <Image src={logo} imageClassName="h-2rem md:h-3rem" />
            </div>

            <div className="flex flex-column mt-8 text-3xl md:text-5xl">
              <strong>Log in to the JUSTIS</strong>
              <strong>Social Portal</strong>
            </div>

            <form
              onSubmit={formik.handleSubmit}
              className="w-full flex flex-column mt-5"
            >
              <div className="p-fluid mb-3 mt-4">
                <div className="mb-3">
                  <label
                    htmlFor="email"
                    className={classNames({
                      "p-error": isFormFieldValid(formik, "email"),
                      "font-medium": true,
                    })}
                  >
                    Email address
                  </label>
                </div>
                <span className="p-input-icon-left">
                  <i className="pi pi-envelope font-bold text-color" />

                  <InputText
                    id="email"
                    name="email"
                    placeholder="name@domain.com"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    className={classNames({
                      "p-invalid": isFormFieldValid(formik, "email"),
                      "p-inputtext-lg": true,
                    })}
                  />
                </span>
                <div className="mt-2">
                  {getFormErrorMessage(formik, "email")}
                </div>
              </div>

              <div className="my-3 flex justify-content-end"></div>

              <div className="p-fluid mb-6">
                <div className="mb-3 flex justify-content-between">
                  <label
                    htmlFor="password"
                    className={classNames({
                      "p-error": isFormFieldValid(formik, "password"),
                      "font-medium": true,
                    })}
                  >
                    Password*
                  </label>

                  <Link
                    to="/forgot-password"
                    className="no-underline text-primary-800  hover:text-primary-600"
                  >
                    Forgot Password
                  </Link>
                </div>
                <span className="p-input-icon-left">
                  <i className="pi pi-lock font-bold text-color z-5" />

                  <Password
                    id="password"
                    name="password"
                    feedback={false}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    inputStyle={{ paddingLeft: 40 }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        formik.handleSubmit();
                      }
                    }}
                    toggleMask
                    className={classNames({
                      "p-invalid": isFormFieldValid(formik, "password"),
                      "p-inputtext-lg": true,
                    })}
                  />
                </span>

                <div className="mt-2">
                  {getFormErrorMessage(formik, "password")}
                </div>
              </div>

              <div className="p-fluid mb-6">
                <Button
                  type="submit"
                  label="LOGIN"
                  loading={authenticateState.isLoginLoading}
                  className="p-button-raised p-button-lg"
                />
              </div>
            </form>

            <div className="mb-3 text-center">
              <Link
                to="/register"
                className="no-underline text-md md:text-lg text-primary-800  hover:text-primary-600"
              >
                <p className="text-md m-0 ">Need an account? Sign up here</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="h-full flex-auto hidden lg:flex flex-column login-image justify-content-end">
          <div className="p-7 login w-10 align-self-center mb-5">
            <div>
              <strong className="text-white text-4xl">
                Connect, engage and unlock potential investments
              </strong>
            </div>

            <div>
              <p className="text-lg text-white font-medium">
                The JUSTIS digital portal seeks to bridge the gap between green
                entrepreneurs, policymakers, and investors to accelerate green
                growth and green transitions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
