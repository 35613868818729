import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import FlatList from "flatlist-react";
import { RecentChatItemComponent } from "./RecentChatItemComponent";
import { Sidebar } from "primereact/sidebar";
import { useSelector } from "react-redux";

export const RecentChat = ({ data, onHide, onNewChat }) => {
  const navigate = useNavigate();
  const authenticateState = useSelector((state) => state.authenticate);

  return (
    <div>
      <FlatList
        list={data}
        renderWhenEmpty={() => (
          <div className="flex p-3 align-items-center justify-content-center">
            <Button
              className="p-button-text"
              label="start a new conversation"
              icon="pi pi-plus"
              iconPos="right"
              onClick={onNewChat}
            />
          </div>
        )}
        renderItem={(item, index) => {
          let recipientObject =
            item.to.id !== authenticateState.user.id ? item.to : item.from;

          return (
            <RecentChatItemComponent
              key={index}
              item={recipientObject}
              onItemClick={() => {
                navigate("/chat", {
                  state: {
                    recipient: recipientObject,
                    recentChatObject: item,
                  },
                });
                onHide();
              }}
            />
          );
        }}
      />
    </div>
  );
};

export const RecentChatSmallScreen = ({ data, visible, onHide, onNewChat }) => {
  const navigate = useNavigate();
  const authenticateState = useSelector((state) => state.authenticate);

  return (
    <Sidebar
      visible={visible}
      onHide={onHide}
      showCloseIcon={false}
      className="py-2"
    >
      <div className="text-center text-xl font-semibold border-bottom-1 py-3 border-gray-200">
        Recent Chat
      </div>
      <FlatList
        list={data}
        renderWhenEmpty={() => (
          <div className="flex p-3 align-items-center justify-content-center">
            <Button
              className="p-button-text"
              label="start a new conversation"
              icon="pi pi-plus"
              iconPos="right"
              onClick={onNewChat}
            />
          </div>
        )}
        renderItem={(item, index) => {
          let recipientObject =
            item.to.id !== authenticateState.user.id ? item.to : item.from;

          return (
            <RecentChatItemComponent
              key={index}
              item={recipientObject}
              onItemClick={() => {
                navigate("/chat", {
                  state: {
                    recipient: recipientObject,
                    recentChatObject: item,
                  },
                });
                onHide();
              }}
            />
          );
        }}
      />
    </Sidebar>
  );
};
