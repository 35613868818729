import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  ACTION_TYPE: "",

  isGetUsersLoading: false,
  isGetUserLoading: false,
  isUpdateUserProfileLoading: false,
  isDeactivateUserLoading: false,
  isActivateUserLoading: false,

  getUsersMessage: "",
  getUserMessage: "",
  updateUserProfileMessage: "",
  deactivateUserMessage: "",
  activateUserMessage: "",
  
  users: [],
  user: {}


}

export const UserSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {

    getUsersStart: state => {
      state.ACTION_TYPE = getUsersStart.toString();
      state.isGetUsersLoading = true;
      state.getUsersMessage = "";
      // state.users = []

    },
    getUsersSuccess: (state, action) => {
      state.ACTION_TYPE = getUsersSuccess.toString();
      state.isGetUsersLoading = false;
      state.getUsersMessage = "";
      state.users = action.payload;
    },
    getUsersError: (state, action) => {
      state.ACTION_TYPE = getUsersError.toString();
      state.isGetUsersLoading = false;
      state.getUsersMessage = action.payload.message;
    },

    //get user
    getUserStart: state =>{
      state.ACTION_TYPE = getUserStart.toString();
      state.isGetUserLoading = true;
    },

    getUserSuccess: (state, action) => {
      state.ACTION_TYPE = getUserSuccess.toString();
      state.isGetUserLoading = false;
      state.user = action.payload;
    },

    getUserError: (state, action) => {
      state.ACTION_TYPE = getUserError.toString();
      state.isGetUserLoading = false;
      state.getUserMessage = action.payload.message;
    },
    

    //reset 
    resetActionType: state => {
      state.ACTION_TYPE = resetActionType.toString();
    },

  }
})

// Action creators are generated for each case reducer function
export const
  {
    getUserStart, getUserSuccess, getUserError,

    getUsersStart, getUsersSuccess, getUsersError,
    resetActionType
  } = UserSlice.actions

export default UserSlice.reducer