import {
  setUploadProgress, uploadFileError, uploadFileStart,
  uploadFileSuccess
} from "../slices/FileSlice";


//  firebase auth
import { getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { uploadFile } from 'react-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;




export const uploadFileMiddleware = (storageRef, file) => {
  return async (dispatch) => {
    dispatch(uploadFileStart());

    const uploadTask = uploadBytesResumable(storageRef, file, {
      contentType: file?.type ?? "audio/mpeg",
    });

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        dispatch(setUploadProgress({ progress }));
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        console.log(error);
        dispatch(uploadFileError({ message: error.code }));
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          dispatch(uploadFileSuccess({ path: downloadURL }));
          console.log("File available at", downloadURL);
        });
      }
    );
  };
};

let s3config = {
  bucketName: process.env.REACT_APP_S3_BUCKET,
  dirName: 'post_images', /* optional */
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
}
export const uploadFileToS3Middleware = (file , folderName = "post_images") => {
  return async (dispatch) => {

    //change s3 folder location if provided
    s3config.dirName = folderName;

    dispatch(uploadFileStart());
    console.log("upload to s3 starting?");
    uploadFile(file, s3config)
      .then(data => {
        console.log(data);
        dispatch(uploadFileSuccess({ path: data.location }));
        console.log("File available at", data.location);
      })
      .catch(err => {
        console.error(err)
        dispatch(uploadFileError({ message: "Upload error" }));
      })


  };
};
