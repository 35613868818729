import React from "react";
import { Button } from "primereact/button";
import { USERTYPE } from "../../constants/USERTYPE";

export default function ButtonGroup({
  selected,
  onButtonOneClick,
  onButtonTwoClick,
  onButtonThreeClick,
}) {
  return (
    <div className="flex gap-3  flex-wrap">
      <Button
        className={`p-button-sm ${
          selected === USERTYPE.ENTREPRENEUR
            ? "p-button-primary"
            : "p-button-outlined"
        }
              `}
        label="ENTREPRENEUR"
        icon={selected === USERTYPE.ENTREPRENEUR && "pi pi-check"}
        onClick={onButtonOneClick}
      />
      <Button
        className={`p-button-sm  ${
          selected === USERTYPE.POLICY_MAKER
            ? "p-button-primary"
            : "p-button-outlined"
        }
              `}
        label="POLICY MAKER"
        icon={selected === USERTYPE.POLICY_MAKER && "pi pi-check"}
        onClick={onButtonTwoClick}
      />
      <Button
        className={`p-button-sm ${
          selected === USERTYPE.INVESTOR
            ? "p-button-primary"
            : "p-button-outlined"
        }
              `}
        label="INVESTOR"
        icon={selected === USERTYPE.INVESTOR && "pi pi-check"}
        onClick={onButtonThreeClick}
      />
    </div>
  );
}
