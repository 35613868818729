import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ref } from "firebase/storage";
import { useDispatch, useSelector } from "react-redux";
import { storage } from "../../firebase/firebase-config";
import { uploadFileMiddleware, uploadFileToS3Middleware } from "../../redux/middlewares/FileMiddleware";
import { toast } from "react-toastify";
import { resetActionType, uploadFileError, uploadFileSuccess } from "../../redux/slices/FileSlice";
import { updateUserProfileMiddleware } from "../../redux/middlewares/AuthenticateMiddleware";
export const EditProfilePictureDialog = ({ visible, onHide, renderInput, onChange }) => {

  const authenticateState = useSelector((state) => state.authenticate);
  const userState = useSelector((state) => state.user);
  const fileState = useSelector((state) => state.file);
  const dispatch = useDispatch();

  const fileRef = React.useRef(null);
  const [selectedFile, setSelectedFile] = React.useState();

  const changeProfileImage = async (file) => {
    if (!file) {
      return;
    }

    if(file.size > 10000000){
      toast.error("File must be less that 10md")
      return;
    }

    // const storageRef = ref( storage, "profile_images/" + authenticateState.user_id );
    // dispatch(uploadFileMiddleware(storageRef, file));
    dispatch(uploadFileToS3Middleware(file, "profile_images"));
    onHide();

  };

  React.useEffect(() => {

    if (fileState.ACTION_TYPE === uploadFileSuccess.toString()) {
      dispatch(updateUserProfileMiddleware({ profileImage: fileState.uploadURL }));
      // toast.success("Profile Image Updated");
      dispatch(resetActionType());
    } else if (fileState.ACTION_TYPE === uploadFileError.toString()) {
      toast.error(fileState.uploadFileMessage);
      dispatch(resetActionType());
    }

  }, [fileState.ACTION_TYPE]);


  return (
    <Dialog
      header={"Change Profile Picture"}
      visible={visible}
      onHide={onHide}
      className="w-11 md:w-8 lg:w-6"
      footer={() => (
        <div>
          <Button
            className="p-button-secondary"
            label="Change"
            onClick={() => { changeProfileImage(selectedFile) }}
          />
        </div>
      )}
    >
      <div className="py-2">
        <div>Please select a picture to use as a profile image</div>
        <div className="mt-4 flex gap-4 align-items-center">
          <Button
            label="Choose Image"
            className="p-button-outlined"
            onClick={() => {
              fileRef.current.click();
            }}
          />
          <div>{selectedFile?.name ?? "No File Chosen"}</div>
        </div>

        <div className="hidden">
          <input
            type="file"
            accept="image/*"
            ref={fileRef}
            onChange={(e) => {
              e.preventDefault();
              setSelectedFile(e.target.files[0]);


            }}
          />
        </div>
      </div>
    </Dialog>
  );
};