import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  ACTION_TYPE: "",

  isLoginLoading: false,
  isSignupLoading: false,
  isHandleInvitedUserLoading: false,
  isSetNewPasswordLoading: false,
  isForgotPasswordLoading: false,
  isUpdatePasswordLoading: false,
  isUpdateUserProfileLoading: false,
  isGetUsersLoading: false,
  isLogOutLoading: false,

  loginMessage: "",
  signupMessage: "",
  handleInvitedUserMessage: "",
  setNewPasswordMessage: "",
  forgotPasswordMessage: "",
  updatePasswordMessage: "",
  getUsersMessage: "",
  logOutMessage: "",

  isLoggedIn: false,


  token: "",
  user_id: "",
  user: {},


}

export const AuthenticateSlice = createSlice({
  name: 'authenticate',
  initialState: INITIAL_STATE,
  reducers: {
    //login
    loginStart: state => {
      state.ACTION_TYPE = loginStart.toString();
      state.isLoginLoading = true;
      state.loginMessage = "";
      state.user = {}

    },
    loginSuccess: (state, action) => {
      state.ACTION_TYPE = loginSuccess.toString();
      state.isLoginLoading = false;
      state.loginMessage = "";
      state.user_id = action.payload.user_uid;
      state.user = action.payload.user ;
      state.isLoggedIn = true;

      // state.token = action.payload.token;
    },
    loginError: (state, action) => {
      state.ACTION_TYPE = loginError.toString();
      state.isLoginLoading = false;
      state.loginMessage = action.payload.message;
      state.isLoggedIn = false;
    },

    //signup  user
    signupStart: state => {
      state.ACTION_TYPE = signupStart.toString();
      state.isSignupLoading = true;
    },

    signupSuccess: (state, action) => {
      state.ACTION_TYPE = signupSuccess.toString();
      state.user_id = action.payload.user_uid;
      state.user = action.payload.user ;
      state.isSignupLoading = false;
      state.signupMessage = action.payload.message;
    },
    signupError: (state, action) => {
      state.ACTION_TYPE = signupError.toString();
      state.isSignupLoading = false;
      state.signupMessage = action.payload.message;

    },

    //get authenticated user
    //get user
    getAuthenticatedUserStart: state =>{
      state.ACTION_TYPE = getAuthenticatedUserStart.toString();
      state.isGetAuthenticatedUserLoading = true;
    },

    getAuthenticatedUserSuccess: (state, action) => {
      state.ACTION_TYPE = getAuthenticatedUserSuccess.toString();
      state.isGetAuthenticatedUserLoading = false;
      state.user = action.payload;
    },

    getAuthenticatedUserError: (state, action) => {
      state.ACTION_TYPE = getAuthenticatedUserError.toString();
      state.isGetAuthenticatedUserLoading = false;
      state.getAuthenticatedUserMessage = action.payload.message;
    },


    //update user profile
    updateUserProfileStart: state => {
      state.ACTION_TYPE = updateUserProfileStart.toString();
      state.isUpdateUserProfileLoading = true;
      state.updateUserProfileMessage = "";

    },
    updateUserProfileSuccess: (state, action) => {
      state.ACTION_TYPE = updateUserProfileSuccess.toString();
      state.isUpdateUserProfileLoading = false;
      state.updateUserProfileMessage = "";

    },
    updateUserProfileError: (state, action) => {
      state.ACTION_TYPE = updateUserProfileError.toString();
      state.isUpdateUserProfileLoading = false;
      state.updateUserProfileMessage = action.payload.message;
    },

    

    //set new password
    setNewPasswordStart: state => {
      state.ACTION_TYPE = setNewPasswordStart.toString();
      state.isSetNewPasswordLoading = true;
      state.setNewPasswordMessage = "";

    },
    setNewPasswordSuccess: (state, action) => {
      state.ACTION_TYPE = setNewPasswordSuccess.toString();
      state.isSetNewPasswordLoading = false;
      state.setNewPasswordMessage = action.payload.message;

    },
    setNewPasswordError: (state, action) => {
      state.ACTION_TYPE = setNewPasswordError.toString();
      state.isSetNewPasswordLoading = false;
      state.setNewPasswordMessage = action.payload.message

    },

    //forgot password
    forgotPasswordStart: state => {
      state.ACTION_TYPE = forgotPasswordStart.toString();
      state.isForgotPasswordLoading = true;
      state.forgotPasswordMessage = "";

    },
    forgotPasswordSuccess: (state, action) => {
      state.ACTION_TYPE = forgotPasswordSuccess.toString();
      state.isForgotPasswordLoading = false;
      state.forgotPasswordMessage = action.payload.message;

    },
    forgotPasswordError: (state, action) => {
      state.ACTION_TYPE = forgotPasswordError.toString();
      state.isForgotPasswordLoading = false;
      state.forgotPasswordMessage = action.payload.message
    },


    //update password
    updatePasswordStart: state => {
      state.ACTION_TYPE = updatePasswordStart.toString();
      state.isUpdatePasswordLoading = true;
      state.updatePasswordMessage = "";

    },
    updatePasswordSuccess: (state, action) => {
      state.ACTION_TYPE = updatePasswordSuccess.toString();
      state.isUpdatePasswordLoading = false;
      state.updatePasswordMessage = action.payload.message;

    },
    updatePasswordError: (state, action) => {
      state.ACTION_TYPE = updatePasswordError.toString();
      state.isUpdatePasswordLoading = false;
      state.updatePasswordMessage = action.payload.message

    },
    


    

    //validate token
    validateTokenSuccess: (state, action) => {
      state.ACTION_TYPE = validateTokenSuccess.toString();

    },

    
    //logout
    logOutStart: state => {
      state.ACTION_TYPE = logOutStart.toString();
      state.isLogOutLoading = true;

    },
    logOutSuccess: (state) => {
      state.ACTION_TYPE = logOutSuccess.toString();
      state.isLogOutLoading = false;
      state.isLoggedIn = false;
      state.user = {};
      state.token = "";

    },
    logOutError: (state) => {
      state.ACTION_TYPE = logOutError.toString();
      state.isLogOutLoading = false;
    },


    //reset 
    resetActionType: state => {
      state.ACTION_TYPE = resetActionType.toString();
    },

  }
})

// Action creators are generated for each case reducer function
export const
  {
    loginStart, loginSuccess, loginError,
    signupStart, signupSuccess, signupError,
    getAuthenticatedUserStart,getAuthenticatedUserSuccess,getAuthenticatedUserError,
    updateUserProfileStart, updateUserProfileSuccess, updateUserProfileError,
    
    handleInvitedUserStart, handleInvitedUserSuccess, handleInvitedUserError,
    setNewPasswordStart, setNewPasswordSuccess, setNewPasswordError,
    forgotPasswordStart, forgotPasswordSuccess, forgotPasswordError,
    updatePasswordStart, updatePasswordSuccess, updatePasswordError,
    logOutStart, logOutSuccess, logOutError,
    validateTokenSuccess,
    resetActionType
  } = AuthenticateSlice.actions

export default AuthenticateSlice.reducer