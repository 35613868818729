import React from "react";
import { useFormik } from "formik";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

// logo
import logo from "../../assets/logo.svg";
import eca from "../../assets/eca.png";
import unu from "../../assets/unu.png";

import { isFormFieldValid, getFormErrorMessage } from "../../util/formikUtils";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  forgotPasswordSuccess,
  forgotPasswordError,
  resetActionType,
} from "../../redux/slices/AuthenticateSlice";
import { forgotPasswordMiddleware } from "../../redux/middlewares/AuthenticateMiddleware";
import { goToAppPage } from "../../redux/slices/NavigationSlice";

export default function ForgotPasswordPage(props) {
  const dispatch = useDispatch();
  const authenticateState = useSelector((state) => state.authenticate);

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "Email is required.";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address. E.g. example@email.com";
      }

      return errors;
    },

    onSubmit: (values) => {
      dispatch(forgotPasswordMiddleware(values.email));
    },
  });

  React.useEffect(() => {
    if (authenticateState.ACTION_TYPE === forgotPasswordSuccess.toString()) {
      formik.resetForm();
      toast.success(
        `A Password reset email will be sent to ${formik.values.email}`
      );
      dispatch(resetActionType());
    } else if (
      authenticateState.ACTION_TYPE === forgotPasswordError.toString()
    ) {
      toast.error(authenticateState.forgotPasswordMessage);
      dispatch(resetActionType());
    }
  }, [authenticateState.ACTION_TYPE]);

  return (
    <main className="h-screen relative">
      <div className="background" />
      <div className="h-full relative flex flex-column justify-content-center align-items-center">
        <div className="login p-5 w-11 md:w-8 lg:w-5 flex flex-column align-items-center">
          <div className="mt-5 flex gap-3 align-items-center flex-wrap justify-content-center">
            <Image
              src={logo}
              alt="logo"
              imageClassName="w-6rem md:w-8rem"
              imageStyle={{
                objectFit: "contain",
              }}
            />

            <Image
              src={unu}
              alt="logo"
              imageClassName="w-6rem md:w-8rem"
              imageStyle={{
                objectFit: "contain",
              }}
            />

            <Image
              src={eca}
              alt="logo"
              imageClassName="w-6rem md:w-8rem"
              imageStyle={{
                objectFit: "contain",
              }}
            />
          </div>

          <h1 className="text-xl md:text-2xl lg:text-3xl text-primary-700 font-bold text-center">
            Forgot Password
          </h1>

          <form
            onSubmit={formik.handleSubmit}
            className="w-full flex flex-column align-items-center"
          >
            <div className="p-fluid w-11 mb-6 mt-4">
              <span className="p-float-label p-input-icon-right">
                <i className="pi pi-envelope" />
                <InputText
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={classNames({
                    "p-invalid": isFormFieldValid(formik, "email"),
                  })}
                />
                <label
                  htmlFor="email"
                  className={classNames({
                    "p-error": isFormFieldValid(formik, "email"),
                  })}
                >
                  Email
                </label>
              </span>
              <div className="mt-2">{getFormErrorMessage(formik, "email")}</div>
            </div>

            <div className="p-fluid w-11 mb-6 ">
              <Button
                type="submit"
                label="RESET PASSWORD"
                loading={authenticateState.isForgotPasswordLoading}
                className="p-button-raised"
                // onClick={(e) => {
                //   dispatch(goToAppPage());
                // }}
              />
            </div>
          </form>

          <div className="mb-3">
            <Link
              to="/"
              className="no-underline text-md md:text-lg text-primary-800  hover:text-primary-600"
            >
              <p className="text-md m-0 ">Remember Password? Login</p>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}
