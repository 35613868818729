import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "primereact/button";

export const MenuButton = ({ to, icon, label, onMenuClick }) => {
  return (
    <NavLink to={to} className="p-fluid no-underline cursor-pointer">
      <Button
        className=" p-button-text  text-gray-700  font-medium hover:bg-black-alpha-20 mb-1"
        onClick={onMenuClick}
      >
        <i className={`${icon} pl-2 text-lg`}></i>
        <span className="px-3">{label}</span>
      </Button>
    </NavLink>
  );
};
