


import { arrayRemove, arrayUnion, increment } from "firebase/firestore";
import { FIREBASECOLLECTION } from "../../constants/FIREBASECOLLECTIONS";
import { auth } from "../../firebase/firebase-config";
import { deleteFirebaseDoc, getCollectionFirebase, getFirebaseDoc, setFirebaseDoc, setFirebaseSubCollection, updateFirebaseDoc } from "../../util/firebaseFirestoreUtils";

import {
  addCommentError, addCommentStart, addCommentSuccess,
  addPostError, addPostStart, addPostSuccess,
  deletePostError, deletePostStart, deletePostSuccess,
  editPostError, editPostStart, editPostSuccess,
  getPostError, getPostsError, getPostsStart,
  getPostsSuccess, getPostStart, getPostSuccess,
  getCommentsSuccess, getCommentsError, getCommentsStart,
  likePostSuccess, likePostError, likePostStart, unlikePostSuccess,
} from "../slices/PostSlice";

export const addPostMiddleware = (postId, otherDetails) => {
  return async (dispatch) => {
    dispatch(addPostStart());
    try {

      setFirebaseDoc(`${FIREBASECOLLECTION.POSTS}/${postId}`, otherDetails);

      //get from user collection and save to redux
      dispatch(addPostSuccess({}));

    } catch (error) {
      const errorCode = error.code;
      dispatch(addPostError({ message: errorCode ?? error }));
    }
  };
};

export const editPostMiddleware = (postId, otherDetails) => {
  return async (dispatch) => {
    dispatch(editPostStart());
    try {

      updateFirebaseDoc(`${FIREBASECOLLECTION.POSTS}/${postId}`, otherDetails);

      //get from user collection and save to redux
      dispatch(editPostSuccess({}));

    } catch (error) {
      const errorCode = error.code;
      dispatch(editPostError({ message: errorCode ?? error }));
    }
  };
};

export const deletePostMiddleware = (postId) => {
  return async (dispatch) => {
    dispatch(deletePostStart());
    try {

      await deleteFirebaseDoc(`${FIREBASECOLLECTION.POSTS}/${postId}`);

      //get from user collection and save to redux
      dispatch(deletePostSuccess({}));

    } catch (error) {
      const errorCode = error.code;
      dispatch(deletePostError({ message: errorCode ?? error }));
    }
  };
};

export const likePostMiddleware = (postId, isLiked = true) => {
  return async (dispatch) => {
    dispatch(likePostStart());
    try {



      //if already liked, unlike
      if (isLiked) {

        //like if not liked
        await updateFirebaseDoc(`${FIREBASECOLLECTION.POSTS}/${postId}`, {
          likeCount: increment(1),
          likes: arrayUnion(auth.currentUser.uid.toString())
        });
        dispatch(likePostSuccess({}));

      } else {
        
        await updateFirebaseDoc(
          `${FIREBASECOLLECTION.POSTS}/${postId}`,
          {
            likeCount: increment(-1),
            likes: arrayRemove(auth.currentUser.uid.toString())
          }
        );
        dispatch(unlikePostSuccess({}));

      }

    } catch (error) {
      console.log(error);
      const errorCode = error.code;
      dispatch(likePostError({ message: errorCode ?? error }));
    }
  };
};

export const getPostsMiddleware = () => {
  return async (dispatch) => {
    dispatch(getPostsStart());

    try {
      const posts = [];
      const querySnapshot = await getCollectionFirebase(FIREBASECOLLECTION.POSTS);

      querySnapshot.forEach((doc) => {
        posts.push(doc.data());
        console.log(doc.id, " => ", doc.data());
      });

      dispatch(getPostsSuccess(posts));

    } catch (error) {
      const errorCode = error.code;
      // const errorMessage = error.message;
      dispatch(getPostsError({ message: errorCode }));
    }
  };
}


export const getPostByIdMiddleware = (postId) => {
  return async (dispatch) => {
    dispatch(getPostStart());

    try {
      const fireBasePost = await getFirebaseDoc(`${FIREBASECOLLECTION.POSTS}/${postId}`);
      dispatch(getPostSuccess(fireBasePost.data()));


    } catch (error) {
      const errorCode = error.code;
      // const errorMessage = error.message;
      dispatch(getPostError({ message: errorCode }));
    }
  };
}


export const getCommentsMiddleware = (postId) => {
  return async (dispatch) => {
    dispatch(getCommentsStart());

    try {
      const comments = [];
      const querySnapshot = await getCollectionFirebase(`${FIREBASECOLLECTION.POSTS}/${postId}/${FIREBASECOLLECTION.COMMENTS}`);

      querySnapshot.forEach((doc) => {
        comments.push(doc.data());
      });

      dispatch(getCommentsSuccess(comments));

    } catch (error) {
      const errorCode = error.code;
      // const errorMessage = error.message;
      dispatch(getCommentsError({ message: errorCode }));
    }
  };
}

export const addCommentMiddleware = (postId, commentId, commentObject) => {
  return async (dispatch) => {
    dispatch(addCommentStart());

    try {
      await setFirebaseSubCollection(`${FIREBASECOLLECTION.POSTS}/${postId}/${FIREBASECOLLECTION.COMMENTS}`, commentId, commentObject);

      //updated comment count
      await updateFirebaseDoc(`${FIREBASECOLLECTION.POSTS}/${postId}`, {
        commentCount: increment(1)
      })

      dispatch(addCommentSuccess({}));


    } catch (error) {
      const errorCode = error.code;
      // const errorMessage = error.message;
      dispatch(addCommentError({ message: errorCode }));
    }
  };
}

