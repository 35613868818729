import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import FlatList from "flatlist-react";
import { NewChatItemComponent } from "./NewChatItemComponent";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";

import { useDispatch, useSelector } from "react-redux";


export const NewChat = ({ visible, onHide }) => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);

  const [filterText, setFilterText] = React.useState("");
  const [filteredData, setFilteredData] = React.useState([]);

  React.useEffect(() => {

    setFilteredData(userState?.users ?? []);

    return () => {
      setFilteredData([]);
    }

  }, [])

  const onUserSearch = React.useCallback( (searchKey) => {
    setFilterText(searchKey);
    let filteredUsers = userState?.users.filter((user) =>
      user.name.toLowerCase().includes(searchKey.toLowerCase())
    );

    setFilteredData(filteredUsers);

  }, []);



  return (
    <Sidebar
      visible={visible}
      onHide={onHide}
      showCloseIcon={false}
      className="py-2"
    >
      <div className="text-center text-xl font-semibold border-bottom-1 py-3 border-gray-200 flex flex-column">
        Start New Conversation
        <div className="flex mt-3 mx-2">
          <InputText
            className=" p-inputtext-sm flex-auto border-noround-right"
            value={filterText}
            onChange={(e) => onUserSearch( e.target.value)}
            placeholder="Search users"
          />
          <Button
            className="p-button-sm border-noround-left"
            icon='pi pi-search'
          />
        </div>
      </div>
      <FlatList
        list={filteredData}
        renderWhenEmpty={() => (
          <div className="flex flex-column gap-5 p-3 align-items-center justify-content-center please try again">
            <p>OOPS! No users were found</p>
            <Button label="go back" onClick={onHide} />
          </div>
        )}
        renderItem={(item, index) => (
          <NewChatItemComponent
            key={index}
            item={item}
            onItemClick={() => {
              console.log(item);
              navigate("/chat", { state: {
                recipient:item,
                recentChatObject :{}
              } });
              onHide();
            }}
          />
        )}
      />
    </Sidebar>
  );
};
