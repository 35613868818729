import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";


//redux
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfileMiddleware } from "../../redux/middlewares/AuthenticateMiddleware";
import { CountrySelect, Input } from "../forms/CustomInputs";

export const EditProfileDialog = (props) => {
    const authenticateState = useSelector((state) => state.authenticate);
    const userState = useSelector((state) => state.user);

    const dispatch = useDispatch();

    const { visible, onHide, } = props;

    const formik = useFormik({
        initialValues: {
            name: userState.user?.name ?? "N/A",
            slogan: userState.user?.slogan ?? "N/A",
            address: userState.user?.address ?? "N/A",
            profileLink: userState.user?.profileLink ?? "N/A",
            website: userState.user?.website ?? "N/A",
            phone: userState.user?.phone ?? "N/A",
            email: userState.user?.email ?? "N/A",
            country: userState.user?.country ,
        },

        onSubmit: (values) => {

            const userObject = {
                name: values.name,
                slogan: values.slogan,
                address: values.address,
                profileLink: values.profileLink,
                website: values.website,
                phone: values.phone,
                email: values.email,
                country: values.country,

            }
            // console.log(userObject);

            dispatch(updateUserProfileMiddleware(userObject));
        },
    });

   
    return (
        <Dialog
                header={"Edit Intro"}
                visible={visible}
                onHide={onHide}
                className="w-11 md:w-8 lg:w-6"
                footer={() => (
                    <div>
                        <Button
                            className="p-button-secondary"
                            label="Save"
                            type="submit"
                            onClick={formik.handleSubmit}
                            loading={  authenticateState.isUpdateUserProfileLoading}
                        />
                    </div>
                )}
            >
                <form onSubmit={formik?.handleSubmit} className="w-full flex flex-column align-items-center  py-5 " >
                <div className="border-top-1 border-gray-200 w-full pt-3">
                    <small>* Indicates required</small>
                    <div className="mt-5" />
                    <Input
                        formik={formik}
                        label="Organisation Name"
                        id="name"
                        value={formik.values.name}
                    />

                    <Input
                        formik={formik}
                        label="Slogan"
                        id="slogan"
                        value={formik.values.slogan}
                    />

                    <CountrySelect
                        formik={formik}
                        label="Country"
                        id="country"
                        value={formik.values.country}
                    />

                    <Input
                        formik={formik}
                        label="Website"
                        placeholder="eg(https://www.google.com)"
                        id="website"
                        value={formik.values.website}
                    />

                    <Input
                        formik={formik}
                        label="Address"
                        id="address"
                        value={formik.values.address}
                    />

                    <Input
                        formik={formik}
                        label="Phone Number"
                        id="phone"
                        value={formik.values.phone}
                    />

                    {/* <Input
                    
                        formik={formik}
                        label="Email"
                        id="email"
                        value={formik.values.email}
                    /> */}

                    
                </div>
        </form>
            </Dialog>
    );
};