import React from "react";
import Lottie from "react-lottie";
import * as image from "../../assets/lottie/notfound.json";

export default function ErrorPage({ text = "User not found" }) {
  return (
    <div className="flex flex-column justify-content-start align-items-start pointer-events-none">
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: image,
        }}
        height={300}
        width={300}
      />

      <div className="text-center font-medium mt-0 w-full text-xl">{text}</div>
    </div>
  );
}
