import { Image } from "primereact/image";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SuggestedUserItemComponent from "./SuggestedUserItemComponent";
import { Button } from "primereact/button";

export const SuggestedUsers = ({ data = [], onProfileClick }) => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const [randomusers, setRandomUsers] = React.useState([]);

  // create an array of random users from userState.users array
  React.useEffect(() => {
    if (userState?.users?.length > 0) {
      let randomUsers = [];
      for (let i = 0; i < 7; i++) {
        let randomIndex = Math.floor(Math.random() * userState.users.length);
        randomUsers.push(userState.users[randomIndex]);
      }
      setRandomUsers(randomUsers);
    }
  }, [userState.users]);

  return (
    <main className="m-3 px-1 ">
      <div className="mb-3">
        <Button
          className="bg-gray-300 p-0 border-round-2xl w-full p-button-text px-4 py-2 align-items-center flex text-color"
          onClick={() => navigate("/search")}
        >
          <i className="pi pi-search" />
          <span className="pl-2 font-medium">Find Users</span>
        </Button>
      </div>
      <div className="text-xl text-color font-medium pt-1">Suggested Users</div>
      <div className="text-sm text-gray-500 py-3">
        Here are some users you can interact with
      </div>

      <div className="py-2 flex flex-column gap-3">
        {randomusers.map((user, index) => (
          <SuggestedUserItemComponent key={index.toString()} user={user} />
        ))}
      </div>
      <div style={{ height: "10vh" }} />
    </main>
  );
};
