import React from "react";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { ProgressSpinner } from "primereact/progressspinner";

import { USERTYPE } from "../../constants/USERTYPE";
//redux
import { useSelector } from "react-redux";
import { EditProfileDialog } from "../dialogs/EditProfileDialog";
import { EditProfilePictureDialog } from "../dialogs/EditProfilePictureDialog";
import { useNavigate } from "react-router-dom";
import { defaultImage } from "../../constants/IMAGES";

export const ViewProfileBlock = (props) => {
  const { isCurrentUser } = props;
  const navigate = useNavigate();

  // fetch data from redux
  const userState = useSelector((state) => state.user);
  const fileState = useSelector((state) => state.file);

  const user = {
    profileImage: userState.user?.profileImage ?? defaultImage,
    name: userState.user.name,
    slogan: userState.user.slogan,
    address: userState.user.address,
    profileLink: userState.user.profileLink,
    website: userState.user.website,
    phone: userState.user.phone,
    email: userState.user.email,
    country: userState.user.country,
    type: userState.user.userType,
  };

  const [showChangePP, setShowChangePP] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);

  return (
    <div className="bg-white p-3 border-round relative overflow-hidden">
      {/* absolute block to display type */}
      {/* ---------------------------------------------------------------- */}
      <div
        className={`absolute flex justify-content-center 
        align-items-center h-5rem w-12 text-white  
        ${
          user.type === USERTYPE.ENTREPRENEUR
            ? "bg-primary-500"
            : user.type === USERTYPE.INVESTOR
            ? "bg-blue-500"
            : "bg-cyan-500"
        }`}
        style={{
          top: 0,
          left: 0,
          right: 0,
          zIndex: 0,
          textTransform: "uppercase",
        }}
      >
        {user.type.toString().split("_").join(" ")}
      </div>
      {/* ---------------------------------------------------------------- */}
      {/* image block */}
      {/* ---------------------------------------------------------------- */}
      <div className="flex justify-content-between align-items-start relative">
        <div className="z-2 relative">
          <Image
            imageClassName="bg-primary-100 h-10rem w-10rem border-circle z-2"
            imageStyle={{
              objectFit: "cover",
              zIndex: 5,
            }}
            src={user?.profileImage}
          />

          {fileState.isUploadFileLoading && <ProgressSpinner />}

          {(!props.isGuest && isCurrentUser) && (
            <Button
              icon="pi pi-pencil"
              className="cursor-pointer bg-gray-400 border-gray-400 absolute p-button-sm"
              style={{ bottom: -5, left: 100 }}
              onClick={() => setShowChangePP(true)}
            />
          )}
        </div>

        {(!props.isGuest && isCurrentUser) && (
          <Button
            icon="pi pi-pencil"
            className="p-button-text text-white"
            onClick={() => {
              setShowEdit(true);
            }}
          />
        )}

        {(!props.isGuest && !isCurrentUser) && (
          <Button
            className={`border-round-3xl p-button-sm border-none mt-1 ${
              user.type === USERTYPE.ENTREPRENEUR
                ? "bg-primary-500 hover:bg-primary-400"
                : user.type === USERTYPE.INVESTOR
                ? "bg-blue-500 hover:bg-blue-400"
                : "bg-cyan-500 hover:bg-cyan-400"
            }`}
            label="Send Message"
            icon="pi pi-send"
            onClick={(e) => {
              navigate(`/chat`, {
                state: { recipient: userState.user },
              });
            }}
          />
        )}
      </div>
      {/* ---------------------------------------------------------------- */}

      {/* Details Block */}
      {/* ---------------------------------------------------------------- */}
      <div className="">
        <h2 className="mb-0">
          {user?.name}{" "}
          <span className="text-sm font-regular">({user?.country.name})</span>
        </h2>
        <div>{user?.slogan}</div>
      </div>
      <div className="mt-3 flex gap-2 flex-wrap">
        {user?.address && (
          <Chip
            icon="pi pi-map-marker"
            label={user.address}
            className="cursor-pointer hover:bg-primary-100"
            onClick={() => {
              window.open(`https://maps.google.com?q="${user.address}"`);
            }}
          />
        )}

        {user?.phone && (
          <Chip
            icon="pi pi-phone"
            label={user.phone}
            className="cursor-pointer hover:bg-primary-100"
            onClick={() => {
              window.open(`tel://${user.phone}`, "_self");
            }}
          />
        )}

        {user?.email && (
          <Chip
            icon="pi pi-envelope"
            label={user.email}
            className="cursor-pointer hover:bg-primary-100"
            onClick={() => {
              window.open(`mailto://${user.email}`);
            }}
          />
        )}

        {user?.website && (
          <Chip
            icon="pi pi-globe"
            label={"website"}
            className="cursor-pointer hover:bg-primary-100"
            onClick={() => {
              window.open(
                user.website.match(/^https?:/)
                  ? user.website
                  : "//" + user.website
              );
            }}
          />
        )}
      </div>
      {/* ---------------------------------------------------------------- */}

      {/* Profile Image Dialog  */}
      {/* ---------------------------------------------------------------- */}
      {isCurrentUser && (
        <EditProfilePictureDialog
          visible={showChangePP}
          onHide={() => setShowChangePP(false)}
        />
      )}
      {/* ---------------------------------------------------------------- */}

      {/* Details Dialog */}
      {/* ---------------------------------------------------------------- */}
      {isCurrentUser && (
        <EditProfileDialog
          visible={showEdit}
          onHide={() => setShowEdit(false)}
        />
      )}
      {/* ---------------------------------------------------------------- */}
    </div>
  );
};
