import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

// custom components
import Comments from "../../components/customs/Comments";
import { SuggestedUsers } from "../../components/customs/SuggestedUsers";

// Wrappers
import { useDispatch, useSelector } from "react-redux";
import {
  MainWithSide as Main,
  MainWithSideWrapper,
  Side
} from "../../components/containers/Wrappers";

// loader and error
import { useRef } from "react";
import PostItem from "../../components/customs/PostItem2";
import { addCommentMiddleware, deletePostMiddleware, getCommentsMiddleware, getPostByIdMiddleware } from "../../redux/middlewares/PostMiddleware";
import { addCommentSuccess, getPostError, getPostStart, getPostSuccess, resetActionType } from "../../redux/slices/PostSlice";
import ErrorPage from "../misc/ErrorPage";
import LoadingPage from "../misc/LoadingPage";
import { toast } from "react-toastify";
import EditPostDialog from "../../components/dialogs/EditPostDialog";
import { getFirebaseDocRef } from "../../util/firebaseFirestoreUtils";
import { FIREBASECOLLECTION } from "../../constants/FIREBASECOLLECTIONS";
import { onSnapshot } from "firebase/firestore";
import { addNotificationMiddleware } from "../../redux/middlewares/NotificationMiddleware";

export default function PostDetails({ }) {
  //redux
  const authenticateState = useSelector((state) => state.authenticate);
  const postState = useSelector((state) => state.post);


  // dialogs for the share components
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false);
  const [addedComment, setAddedComment] = React.useState(null); //temporarily store the added comment. this will be used for adding notifications


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const commentInputRef = useRef();

  //realtime post listener
  React.useEffect(() => {
    dispatch(getPostStart());

    const postRef = getFirebaseDocRef(`${FIREBASECOLLECTION.POSTS}/${id}`);

    const unsubscribeListener = onSnapshot(postRef,
      (doc) => {
        dispatch(getPostSuccess(doc.data()));
      },
      (error) => {
        dispatch(getPostError({ message: error }))
      });


    return () => {
      unsubscribeListener();
    };
  }, []);

  React.useEffect(() => {
    if (postState.ACTION_TYPE === getPostError.toString()) {
      dispatch(resetActionType());
    } else if (postState.ACTION_TYPE === getPostSuccess.toString()) {
      dispatch(getCommentsMiddleware(id));
      dispatch(resetActionType());
    }
    else if (postState.ACTION_TYPE === addCommentSuccess.toString()) {

      toast.success("Comment added successfully");
      dispatch(getCommentsMiddleware(id));

      //------SEND NOTIFICATION FOR COMMENT ADDED------------
      const notificationId = Date.now().toString();
      const newNotificationObject = {
          id: notificationId,
          sentFromId: authenticateState.user_id,
          sentToId: postState.post?.createdBy?.id,
          title: "Commented on your post",
          message: addedComment,
          createdAt: Date.now().toString(),
          isRead: false,
          sentFrom: {
              name: authenticateState.user?.name,
              imageUrl: authenticateState?.user?.profileImage,
          },
          sentTo: {
              name: postState.post?.createdBy?.name,
              imageUrl: postState.post?.createdBy?.profileImage,
          },
      };

      dispatch (addNotificationMiddleware(notificationId, newNotificationObject));
      dispatch(resetActionType());


    }
  }, [postState.ACTION_TYPE]);

  if (postState.isGetPostLoading) {
    return <LoadingPage />;
  }

  if (postState.ACTION_TYPE === getPostError.toString()) {
    return <ErrorPage />;
  }

  return (
    <MainWithSideWrapper>
      {/* Main Page Items */}
      {/* ---------------------------------------------------------------- */}
      <Main className="pt-3">
        <div className="flex flex-column gap-5 mt-4 pb-8">
          {!postState.isGetPostLoading &&
            <>
              <PostItem
                item={postState.post}
                onEditPost={() => {
                  // dispatch(getPostSuccess(item))
                  // setSelectedPost(postState.post);
                  setShowUpdateDialog(true);
                }}
                onDeletePost={() => {
                  // dispatch(getPostSuccess(item))
                  // setSelectedPost(postState.post);
                  confirmDialog({
                    message: "Do you want to delete this post?",
                    header: "Delete Confirmation",
                    icon: "pi pi-info-circle",
                    acceptClassName: "p-button-danger",
                    accept: () => {
                      dispatch(deletePostMiddleware(postState.post.id));
                    },
                    reject: () => { },
                  });
                }}
              />

              <Comments
                inputRef={commentInputRef}
                isAddCommentLoading={postState.isAddCommentLoading}
                isGetCommentsLoading={postState.isGetCommentsLoading}
                onAddComment={(comment) => {
                  setAddedComment(comment);

                  const id = Date.now();

                  const commentObject = {
                    id: id.toString(),
                    createdBy: authenticateState.user,
                    comment: comment,
                    createdAt: id,
                  }

                  dispatch(addCommentMiddleware(postState.post.id, id.toString(), commentObject))

                }}
                comments={postState?.comments ?? []} />
            </>
          }
        </div>
      </Main>
      {/* ---------------------------------------------------------------- */}

      {/* Suggested Users Component */}
      {/* ---------------------------------------------------------------- */}
      <Side>
        <SuggestedUsers
        // data
        />
      </Side>
      {/* ---------------------------------------------------------------- */}


      {/* Dialogs for the post components  */}
      {/* ---------------------------------------------------------------- */}


      <EditPostDialog
        visible={showUpdateDialog}
        onHide={() => setShowUpdateDialog(false)}
        // ! selected post details
        selectedPost={postState.post}
      />
    </MainWithSideWrapper>
  );
}
