import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
    ACTION_TYPE: "",

    isGetNotificationsLoading: false,
    isGetNotificationLoading: false,
    isAddNotificationLoading: false,
    isEditNotificationLoading: false,
    isDeleteNotificationLoading: false,
    isToggleReadNotificationLoading: false,

    getNotificationsMessage: "",
    getNotificationMessage: "",
    addNotificationMessage: "",
    editNotificationMessage: "",
    deleteNotificationMessage: "",
    toggleReadNotificationMessage: "",

    unreadNotificationCount: 0,

    notifications: [],
    notification: {},


}

export const NotificationSlice = createSlice({
    name: 'notification',
    initialState: INITIAL_STATE,
    reducers: {
        //get notifications
        getNotificationsStart: state => {
            state.ACTION_TYPE = getNotificationsStart.toString();
            state.isGetNotificationsLoading = true;
            state.getNotificationsMessage = "";

        },
        getNotificationsSuccess: (state, action) => {
            state.ACTION_TYPE = getNotificationsSuccess.toString();
            state.isGetNotificationsLoading = false;
            state.getNotificationsMessage = "";
            state.notifications = action.payload;

            
        },
        getNotificationsError: (state, action) => {
            state.ACTION_TYPE = getNotificationsError.toString();
            state.isGetNotificationsLoading = false;
            state.getNotificationsMessage = action.payload.message;
        },


        //get notification
        getNotificationStart: state => {
            state.ACTION_TYPE = getNotificationStart.toString();
            state.isGetNotificationLoading = true;
        },

        getNotificationSuccess: (state, action) => {
            state.ACTION_TYPE = getNotificationSuccess.toString();
            state.isGetNotificationLoading = false;
            state.notification = action.payload;
        },

        getNotificationError: (state, action) => {
            state.ACTION_TYPE = getNotificationError.toString();
            state.isGetNotificationLoading = false;
            state.getNotificationMessage = action.payload.message;
        },


        //add notification 
        addNotificationStart: state => {
            state.ACTION_TYPE = addNotificationStart.toString();
            state.isAddNotificationLoading = true;
            state.addNotificationMessage = "";

        },
        addNotificationSuccess: (state, action) => {
            state.ACTION_TYPE = addNotificationSuccess.toString();
            state.isAddNotificationLoading = false;
            state.addNotificationMessage = "";
        },
        addNotificationError: (state, action) => {
            state.ACTION_TYPE = addNotificationError.toString();
            state.isAddNotificationLoading = false;
            state.addNotificationMessage = action.payload.message;
        },



        //edit notification 
        editNotificationStart: state => {
            state.ACTION_TYPE = editNotificationStart.toString();
            state.isEditNotificationLoading = true;
            state.editNotificationMessage = "";

        },
        editNotificationSuccess: (state, action) => {
            state.ACTION_TYPE = editNotificationSuccess.toString();
            state.isEditNotificationLoading = false;
            state.editNotificationMessage = "";
        },
        editNotificationError: (state, action) => {
            state.ACTION_TYPE = editNotificationError.toString();
            state.isEditNotificationLoading = false;
            state.editNotificationMessage = action.payload.message;
        },

        //delete notification 
        deleteNotificationStart: state => {
            state.ACTION_TYPE = deleteNotificationStart.toString();
            state.isDeleteNotificationLoading = true;
            state.deleteNotificationMessage = "";

        },
        deleteNotificationSuccess: (state, action) => {
            state.ACTION_TYPE = deleteNotificationSuccess.toString();
            state.isDeleteNotificationLoading = false;
        },
        deleteNotificationError: (state, action) => {
            state.ACTION_TYPE = deleteNotificationError.toString();
            state.isDeleteNotificationLoading = false;
            state.deleteNotificationMessage = action.payload.message;
        },


        //toggle read notification 
        toggleReadNotificationStart: state => {
            state.ACTION_TYPE = toggleReadNotificationStart.toString();
            state.isDeleteNotificationLoading = true;
            state.toggleReadNotificationMessage = "";

        },
        toggleReadNotificationSuccess: (state, action) => {
            state.ACTION_TYPE = toggleReadNotificationSuccess.toString();
            state.isDeleteNotificationLoading = false;
        },
        toggleReadNotificationError: (state, action) => {
            state.ACTION_TYPE = toggleReadNotificationError.toString();
            state.isDeleteNotificationLoading = false;
            state.toggleReadNotificationMessage = action.payload.message;
        },

        //set unread notification count
        setUnreadNotificationCount: (state, action) => {
            state.ACTION_TYPE = setUnreadNotificationCount.toString();
            state.unreadNotificationCount = action.payload.unreadNotificationCount;            
        },


        //reset 
        resetActionType: state => {
            state.ACTION_TYPE = resetActionType.toString();
        },

    }
})

// Action creators are generated for each case reducer function
export const
    {
        getNotificationStart, getNotificationSuccess, getNotificationError,

        getNotificationsStart, getNotificationsSuccess, getNotificationsError,
        addNotificationStart, addNotificationSuccess, addNotificationError,
        editNotificationStart, editNotificationSuccess, editNotificationError,
        deleteNotificationStart, deleteNotificationSuccess, deleteNotificationError,
        toggleReadNotificationStart, toggleReadNotificationSuccess, toggleReadNotificationError,
        setUnreadNotificationCount,
        resetActionType
    } = NotificationSlice.actions

export default NotificationSlice.reducer