import React from "react";
import PostItem from "../../components/customs/PostItem2";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useDispatch, useSelector } from "react-redux";
import EditPostDialog from "../../components/dialogs/EditPostDialog";

import {
  deletePostMiddleware,
  likePostMiddleware,
} from "../../redux/middlewares/PostMiddleware";

export default function PostResults({ title, data = [] }) {
  //redux
  const authenticateState = useSelector((state) => state.authenticate);

  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false);
  const [selectedPost, setSelectedPost] = React.useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //   TODO: listen to realtime update for likes

  return (
    <div className=" my-6">
      <ConfirmDialog />
      <h1 className="font-medium text:xl md:text-3xl">{title}</h1>
      <div className="flex flex-column gap-5">
        {data?.map((item, index) => (
          <PostItem
            key={index.toString()}
            item={item}
            onEditPost={() => {
              setSelectedPost(item);
              setShowUpdateDialog(true);
            }}
            onDeletePost={() => {
              setSelectedPost(item);
              confirmDialog({
                message: "Do you want to delete this post?",
                header: "Delete Confirmation",
                icon: "pi pi-info-circle",
                acceptClassName: "p-button-danger",
                accept: () => {
                  dispatch(deletePostMiddleware(item.id));
                },
                reject: () => {},
              });
            }}
          />
        ))}
      </div>

      <EditPostDialog
        visible={showUpdateDialog}
        onHide={() => setShowUpdateDialog(false)}
        // ! selected post details
        selectedPost={selectedPost}
      />
    </div>
  );
}
