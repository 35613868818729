import { useBreakpoints, useCurrentWidth } from "react-breakpoints-hook";

const Container = (props) => {
  return (
    <div
      className={`${props.className} grid w-full grid-nogutter overflow-hidden mx-auto`}
    >
      {props.children}
    </div>
  );
};

const RecentChatWrapper = (props) => {
  let { xs, sm, md, lg, xlg } = useBreakpoints({
    xs: { min: 0, max: 360 },
    sm: { min: 361, max: 960 },
    md: { min: 961, max: 1400 },
    lg: { min: 1401, max: null },
  });

  return (
    <div
      className={`${props.className} hidden col-fixed h-screen overflow-hidden relative lg:flex flex-column border-x-1 border-gray-200 bg-white`}
      style={{
        width: xs ? 80 : sm ? 80 : md ? 200 : lg ? 300 : 300,
      }}
    >
      {props.children}
    </div>
  );
};

const MessagesWrapper = (props) => {
  return (
    <div
      className={`${props.className} col h-screen overflow-hidden relative flex flex-column`}
    >
      {props.children}
    </div>
  );
};

const TopWrapper = (props) => {
  return (
    <div
      className={`${props.className} bg-white p-2 border-y-1 border-gray-200 `}
    >
      {props.children}
    </div>
  );
};

const SendWrapper = (props) => {
  return (
    <div
      className={`${props.className} bg-white border-y-1 border-gray-200 pb-6 px-5 pt-3 flex align-items-start justify-items-between gap-2 `}
    >
      {props.children}
    </div>
  );
};

export {
  Container,
  RecentChatWrapper,
  MessagesWrapper,
  TopWrapper,
  SendWrapper,
};
