import React from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";

// dailogs
import { useSelector } from "react-redux";
import { EditFinancialInformationDialog } from "../dialogs/EditFinancialInformationDialog";

export const ViewFinancialInformationBlock = (props) => {
  const { isCurrentUser } = props;


  const userState = useSelector((state) => state.user);

  const user = {
    costOfOperation: userState.user.costOfOperation ?? "N/A",
    annualRevenue: userState.user.annualRevenue ?? "N/A",
    annualProfit: userState.user.annualProfit ?? "N/A",
    numberOfClients: userState.user.numberOfClients ?? "N/A",
    sourceOfFinance:userState.user.sourceOfFinance ?? "N/A",
    accessToFinance: userState.user.accessToFinance ?? "N/A",
  };

  const [showEdit, setShowEdit] = React.useState(false);

  

  return (
    <div className="mt-4 p-3 bg-white">
      <div className="flex justify-content-between align-items-center">
        <h2>Financial Information</h2>

        {(!props.isGuest && isCurrentUser) && <Button icon="pi pi-pencil" className="p-button-text" onClick={() => {setShowEdit(true)}} /> }


      </div>

      <div className="flex flex-wrap mb-2 gap-5">
        <Item
          label="Cost of Operation"
          value={user?.costOfOperation + " GHS" ?? "N/A"}
        />
        <Item
          label="Annual Revenue"
          value={user?.annualRevenue + " GHS" ?? "N/A"}
        />
        <Item
          label="Annual Profit"
          value={user?.annualProfit + " GHS" ?? "N/A"}
        />
        <Item
          label="Number of Clients"
          value={user?.numberOfClients ?? "N/A"}
        />
        <Item
          label="Source of Finance"
          value={user?.sourceOfFinance ?? "N/A"}
        />
        <Item
          label="Access To Finance"
          value={user?.accessToFinance ?? "N/A"}
        />
      </div>

      {/* Dialog */}
      {/* ---------------------------------------------------------------- */}
      {(!props.isGuest && isCurrentUser) &&<EditFinancialInformationDialog
        visible={showEdit}
        onHide={() => setShowEdit(false)}
      />}
      {/* ---------------------------------------------------------------- */}
    </div>
  );
};

const Item = ({ label, value }) => {
  return (
    <div className="flex flex-column">
      <div>
        <small>{label}</small>
      </div>
      <div className="text-xl">{value}</div>
    </div>
  );
};
