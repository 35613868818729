import React from 'react'
import SearchUserItem from "../../components/customs/SearchUserItem";


export default function UserResults({title, data=[]}) {
  return (
    <div>
      <h1 className="font-medium text:xl md:text-3xl">{title}</h1>
      <div className="flex flex-column gap-4">
        {data?.map((user, index) => (
          <SearchUserItem key={index.toString()} user={user} />
        ))}
      </div>
    </div>
  );
}
