import React from "react";
import { Sidebar } from "primereact/sidebar";
// router
import { Navigate, Route, Routes } from "react-router-dom";
// pages
import ChatPage from "../pages/chat/ChatPage";
import HomePage from "../pages/misc/HomePage";
import ProfilePage from "../pages/profile/ProfilePage";
import SearchPage from "../pages/search/SearchPage";
import UserPostsPage from "../pages/post/UserPostsPage";
import PostDetailsPage from "../pages/post/PostDetailsPage";
import TopSearch from "../pages/search/TopSearch";
import Policy from "../pages/search/Policy";
import GeneralUpdate from "../pages/search/GeneralUpdate";
import Opportunity from "../pages/search/Opportunity";
import Users from "../pages/search/Users";

// navbar
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../components/nav/Navbar";
import SideLeft from "../components/nav/SideLeft";
import SideMenu from "../components/nav/SideMenu";
import NotificationsPage from "../pages/notifications/NotificationsPage";
import { getFirebaseDocs } from "../util/firebaseFirestoreUtils";
import { FIREBASECOLLECTION } from "../constants/FIREBASECOLLECTIONS";
import { onSnapshot, orderBy } from "firebase/firestore";
import { getNotificationsError, getNotificationsStart, getNotificationsSuccess, setUnreadNotificationCount } from "../redux/slices/NotificationSlice";
import { getTokenForNotificaiton, onMessageListener } from "../firebase/firebase-config";
import { log } from "console";
import { toast } from "react-toastify";

export default function AppRouter() {
  const dispatch = useDispatch();

  const [menu, setMenu] = React.useState(false);

  //notification token
  const [isNotificationTokenFound, setIsNotificationTokenFound] = React.useState(false);

  const lastVisibleNotificationDocSnapshot = React.useRef(null);

  //-----push notifications-------------
  React.useEffect(()=>{
    
    getTokenForNotificaiton( setIsNotificationTokenFound);

    onMessageListener().then( payload =>{
      toast.success( "New Notification notification:  "+ payload.notification.title+"\n "+payload.notification.body);
    }).catch( err=>console.log( 'failed', err))
  },[] );

   /**
    * get real time notifications 
    * and count unread notifications
    * */
   React.useEffect(() => {

    const notificationsRef = getFirebaseDocs(`${FIREBASECOLLECTION.NOTIFICATIONS}`, [orderBy("createdAt", "desc")]);

    const unsubscribeListener = onSnapshot(notificationsRef, (querySnapshot) => {
      console.log("on snap callback called for get notifications");
      dispatch(getNotificationsStart());
      try {
        const notifications = [];
        let unreadNotificationCount = 0;

        querySnapshot.forEach((doc) => {
          notifications.push(doc.data());


          if (!doc.data().isRead) {

            unreadNotificationCount = unreadNotificationCount + 1

          }

        });


        // Get the last visible document
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        lastVisibleNotificationDocSnapshot.current = lastVisible;
        dispatch(getNotificationsSuccess(notifications));
        dispatch(setUnreadNotificationCount({ unreadNotificationCount: unreadNotificationCount }));

      } catch (error) {
        const errorCode = error.code;
        dispatch(getNotificationsError({ message: errorCode ?? error }));
      }
    });

    return () => {
      unsubscribeListener();
    };
  }, []);
  return (
    <div className="transition-ease-in-out transition-duration-500 bg-gray-100">
      <Navbar username={{}} showMenu={() => setMenu(true)} />
      <div
        className="h-screen grid w-full grid-nogutter"
        style={{
          paddingTop: 60,
        }}
      >
        <div className="bg-gray-100 relative " style={{ minHeight: "70vh" }}>
          <SideLeft />
        </div>
        <div className="bg-gray-100 col mx-auto">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="profile/:id" element={<ProfilePage />} />
            <Route path="search" element={<SearchPage />}>
              <Route path="top" element={<TopSearch />} />
              <Route path="users" element={<Users />} />
              <Route path="general-update" element={<GeneralUpdate />} />
              <Route path="policy" element={<Policy />} />
              <Route path="opportunity" element={<Opportunity />} />
            </Route>
            <Route path="post/:id" element={<PostDetailsPage />} />
            <Route path="posts/:id" element={<UserPostsPage />} />
            <Route path="chat" element={<ChatPage />} />
            <Route path="notifications" element={<NotificationsPage />} />

            {/* default redirect */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>

      {/* menu show for small screen*/}
      <Sidebar
        visible={menu}
        onHide={() => setMenu(false)}
        showCloseIcon={false}
      >
        <div className="h-screen">
          <SideMenu hideMenu={() => setMenu(false)} />
        </div>
      </Sidebar>
    </div>
  );
}
