import React from "react";
import Lottie from "react-lottie";
import * as image from "../../assets/lottie/loading.json";

export default function ComponentName({}) {
  return (
    <div className="h-screen flex justify-content-center align-items-center w-12 md:w-10 lg:w-9">
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: image,
        }}
        height={150}
        width={150}
      />
    </div>
  );
}
