import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  ACTION_TYPE: "",
}

export const NavigationSlice = createSlice({
  name: 'navigation',
  initialState: INITIAL_STATE,
  reducers: {

    goToLoadingPage: state => {
        state.ACTION_TYPE = goToLoadingPage.toString();
    },

    goToLoginPage: state => {
        state.ACTION_TYPE = goToLoginPage.toString();
    },

    goToDashboardPage: state => {
        state.ACTION_TYPE = goToDashboardPage.toString();
    },

    goToAppPage: state => {
      state.ACTION_TYPE = goToAppPage.toString();
    },


    
    //reset password
    resetActionType: state => {
      state.ACTION_TYPE = resetActionType.toString();
    },

  }
})

// Action creators are generated for each case reducer function
export const {
  goToLoadingPage,
  goToLoginPage,
  goToDashboardPage,
  goToAppPage,
  resetActionType,
} = NavigationSlice.actions;

export default NavigationSlice.reducer