import { db } from "../firebase/firebase-config";

import { doc, updateDoc, getDoc, setDoc,getDocs, collection ,query, deleteDoc} from "firebase/firestore";

export const getFirebaseDocRef =  (documentPath) => {
    const docRef = doc(db, documentPath);
    return docRef;
}



/**
 * Get all docs inside a collection
 * @param {*} collectionPath 
 * @returns 
 */
export const getCollectionFirebase = async (collectionPath) => {
    const querySnapshot = await getDocs(collection(db, collectionPath));
    return querySnapshot;

}


/**
 * Get a firebase doc ref, or a doc ref inside a collection. just provide a correct path
 * @param {*} documentPath 
 * @param {*} docFieldObject 
 */
export const setFirebaseDoc = async (documentPath, docFieldObject) => {
    try {
        const docRef = doc(db, documentPath);
        await setDoc(docRef, docFieldObject);
    } catch (error) {
        throw error.message;
    }
}

/**
 *  sec a new collection. just provide the correct document Path, then the collection name and its doc id
 * 
 * @param {*} documentPath eg users/elections/:electionId this path must include the docID
 * @param {*} subCollectionPath eg candidates/"candidateId this path must include the collection id
 * @param {*} fieldObject  the data to insert into the collection
 */
export const setFirebaseSubCollection = async (subCollectionPath, docId,  docFieldObject) => {
    try {

        const subcollectionRef = collection(db, subCollectionPath) ;
        const docRef = doc(subcollectionRef, docId)
        await setDoc(docRef, docFieldObject)
        
    } catch (error) {
        throw error.message;
    }
}


/**
 * update a firebase doc. just provide a correct path
 * @param {*} documentPath 
 * @param {*} docFieldObjects 
 */
export const updateFirebaseDoc = async (documentPath,  docFieldObjects) => {
    try {
        const docRef = doc(db, documentPath);
        await updateDoc(docRef, docFieldObjects);

    } catch (error) {
        throw error.message;
    }
}

/**
 * Get a reference to a doc or a doc inside a sub collection. just provide the correct path
 * @param {*} documentPath 
 * @returns 
 */
export const getFirebaseDoc= async (documentPath) => {
    try {

        const docRef = doc(db, documentPath);
        const docSnap = await getDoc(docRef);
        return docSnap;
    } catch (error) {
        console.log(error);
    }
}


/**
 *  get all documents withing a collection/subcollection
 * @param {*} collectionPath 
 * @returns 
 */
export const getFirebaseDocs =   (collectionPath, queryConstraints =[]) => {
    try {
        const subcollectionRef = collection(db, collectionPath ) ;
        const query1 =  query(subcollectionRef, ...queryConstraints);
        return query1;
    } catch (error) {
        console.log(error);
    }

}

/**
 * delete a firebase doc. just provide a correct path
 * @param {*} documentPath 
 * @param {*} docFieldObject 
 */
 export const deleteFirebaseDoc = async (documentPath) => {
    try {
        const docRef = doc(db, documentPath);
        await deleteDoc(docRef);
    } catch (error) {
        throw error.message;
    }
}
