import React from "react";


// wrappers
import {
  MainWithSide as Main, MainWithSideWrapper as Wrapper
} from "../../components/containers/Wrappers";

// page components
import { ViewProfileBlock } from "../../components/currentProfile/ViewProfileBlock";
import { ViewRecentActivityBlock } from '../../components/currentProfile/ViewRecentActivityBlock';

// side
import { ViewAboutBlock } from "../../components/currentProfile/ViewAboutBlock";
import { ViewBusinessInformationBlock } from "../../components/currentProfile/ViewBusinessInformationBlock";
import { ViewFinancialInformationBlock } from "../../components/currentProfile/ViewFinancialInformationBlock";

export default function EntrepreneurProfilePage(props) {

  const {isCurrentUser , isGuest} = props;
  return (
    <Wrapper>
      <div className="pt-3 pb-8">
        <ViewProfileBlock isCurrentUser={isCurrentUser} isGuest={isGuest}/>
        <ViewAboutBlock isCurrentUser={isCurrentUser} isGuest={isGuest}/>
        <ViewBusinessInformationBlock isCurrentUser={isCurrentUser} isGuest={isGuest}/>
        <ViewFinancialInformationBlock isCurrentUser={isCurrentUser} isGuest={isGuest}/>
        <ViewRecentActivityBlock isCurrentUser={isCurrentUser} isGuest={isGuest}/>
      </div>
     
    </Wrapper>
  );
}
