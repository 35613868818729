import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

//redux
import { useSelector } from "react-redux";
import { AddRegulationsDialog } from "../dialogs/AddRegulationsDialog";
import { DeleteRegulationsDialog } from "../dialogs/DeleteRegulationsDialog";

export const ViewRegulationsBlock = (props) => {
  const { isCurrentUser } = props;

  const userState = useSelector((state) => state.user);
  const [showEdit, setShowEdit] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [indexToDelete, setIndexToDelete] = React.useState();

  const user = {
    regulations: userState.user.regulations,
  };

  const navigate = useNavigate();

  return (
    <div className="mt-4 bg-white p-3 border-round">
      <div className="flex justify-content-between align-items-center">
        <h2>Regulations</h2>
        {isCurrentUser && (
          <Button
            icon="pi pi-plus"
            className="p-button-text"
            onClick={() => {
              setShowEdit(true);
            }}
          />
        )}
      </div>
      {user?.regulations?.length ? (
        <div className="flex col gap-3 flex-wrap">
          {user?.regulations.map((r, index) => (
            <div key={index}>
              <Button
                className="p-button-secondary"
                label={r.documentName}
                icon="pi pi-external-link"
                iconPos="right"
                onClick={() => {
                  if (r.documentUrl) {
                    window.open(r.documentUrl);
                  }
                }}
              />
              {isCurrentUser && (
                <Button
                  className="p-button-danger"
                  // label={r.documentName}
                  icon="pi pi-trash"
                  // iconPos="right"
                  onClick={() => {
                    setIndexToDelete(index);
                    setShowDelete(true);
                  }}
                />
              )}
            </div>
          ))}
        </div>
      ) : (
        <div>No regulations have been added</div>
      )}

      {/* Dailogs */}
      {isCurrentUser && (
        <AddRegulationsDialog
          visible={showEdit}
          onHide={() => setShowEdit(false)}
        />
      )}
      {isCurrentUser && (
        <DeleteRegulationsDialog
          visible={showDelete}
          onHide={() => setShowDelete(false)}
          indexToDelete={indexToDelete}
        />
      )}
    </div>
  );
};
