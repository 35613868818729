import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Dropdown } from "primereact/dropdown";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

import {
  MainWithSide as Main,
  MainWithSideWrapper as Wrapper,
  Side
} from "../../components/containers/Wrappers";
import { SuggestedUsers } from "../../components/customs/SuggestedUsers";

import { county_filters } from "../../constants/countries";
import { tag_filters } from "../../constants/POSTTAGS";
import { getPostByTagSearchResultsMiddleware, getUserByCountrySearchResultsMiddleware } from "../../redux/middlewares/SearchMiddleware";
import { getPostSearchResultsSuccess, getUserSearchResultsSuccess } from "../../redux/slices/SearchSlice";

export default function SearchPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [filter, setFilter] = React.useState({
    country: "",
    tag: "",
  });

  const [selectedTab, setSelectedTab] = React.useState({
    name: "Top",
    to: "top",

  });


  const changeTab = (item, to) => {
    setSelectedTab(item);
    navigate(`/search/${to}`, { state: filter });
  };

  const active = "bg-primary text-white hover:bg-primary";

  const tabs = [
    {
      name: "Top",
      to: "top",
    },
    {
      name: "Users",
      to: "users",
    },
    {
      name: "General Update",
      to: "general-update",
    },
    {
      name: "Opportunity",
      to: "opportunity",
    },
    {
      name: "Policy",
      to: "policy",
    },
  ];

  React.useEffect(()=>{
    const selectedTabb = tabs[0];
                changeTab({ name: selectedTabb.name, to: selectedTabb.to }, selectedTabb.to);

                dispatch(getUserSearchResultsSuccess([]));
                dispatch( getPostSearchResultsSuccess([]) );

  },[]);


  return (
    <Wrapper>
      <Main className="my-5">
        {/* TABS CONTROL AND SEARCH INPUT */}
        {/* -------------------------------------------------------------- */}
        <div
          className="bg-white px-4 py-3 mb-5 border-round-md 
        flex flex-column align-items-center"
        >
          <div className="w-full flex gap-2 flex-wrap">
            <Dropdown
              value={filter.country}
              options={county_filters}
              onChange={(e) =>
                setFilter({
                  ...filter,
                  country: e.target.value,
                })
              }
              placeholder="Filter users by country..."
              className="flex-auto"
            />
            <Dropdown
              value={filter.tag}
              options={tag_filters}
              onChange={(e) =>
                setFilter({ ...filter, tag: e.target.value, })
              }
              placeholder="Filter Posts by tag..."
              className="flex-auto"
            />

            <Button
              label="search"
              icon="pi pi-search"
              onTouchStart={()=>{}}
              onClick={(e) => {
                
                const selectedTabb = tabs[0];
                changeTab({ name: selectedTabb.name, to: selectedTabb.to }, selectedTabb.to);

                dispatch(getUserSearchResultsSuccess([]));
                dispatch( getPostSearchResultsSuccess([]) );
                
                if( filter.country){
                  dispatch( getUserByCountrySearchResultsMiddleware( filter.country));
                }
                if( filter.tag){
                  dispatch( getPostByTagSearchResultsMiddleware( filter.tag));
                }

              }
              }
             
            />
          </div>



          <div className="mt-3 flex gap-1  md:gap-4 flex-wrap">
            {tabs.map((item, index) => (
              <Chip
                key={index}
                label={item.name}
                className={`cursor-pointer hover:bg-primary-100 ${selectedTab.name === item.name && active
                  }`}
                onClick={() => {
                  changeTab(item, item.to);
                }}
              />
            ))}
          </div>
        </div>

        {/* -------------------------------------------------------------- */}

        {/* RENDER FULL PAGE ON ROUTE CHANGE */}
        {/* -------------------------------------------------------------- */}
        <div>
          <Outlet />
        </div>

        <div className="h-10rem" />
        {/* -------------------------------------------------------------- */}
      </Main>
      <Side>
        <SuggestedUsers />
      </Side>
    </Wrapper>
  );
}
