import React from 'react';

// wrappers
import {
  MainWithSide as Main, MainWithSideWrapper as Wrapper
} from "../../components/containers/Wrappers";

// page components

// side
import { ViewAboutBlock } from '../../components/currentProfile/ViewAboutBlock';
import { ViewProfileBlock } from '../../components/currentProfile/ViewProfileBlock';
import { ViewRecentActivityBlock } from '../../components/currentProfile/ViewRecentActivityBlock';
import { ViewRepresentativesBlock } from '../../components/currentProfile/ViewRepresentativesBlock';

export default function InvestorProfilePage(props) {
  const {isCurrentUser , isGuest} = props;
  return (
    <Wrapper>
      <div className="pt-3 pb-8">
        <ViewProfileBlock isCurrentUser={isCurrentUser} isGuest={isGuest}/>
        <ViewAboutBlock isCurrentUser={isCurrentUser} isGuest={isGuest}/>
        <ViewRepresentativesBlock isCurrentUser={isCurrentUser} isGuest={isGuest}/>
        <ViewRecentActivityBlock isCurrentUser={isCurrentUser} isGuest={isGuest}/>
      </div>
      
    </Wrapper>
  );
}
