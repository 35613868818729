import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  ACTION_TYPE: "",

  isGetPostsLoading: false,
  isGetCommentsLoading: false,
  isGetPostLoading: false,
  isLikePostLoading: false,
  isAddPostLoading: false,
  isAddCommentLoading: false,
  isEditPostLoading: false,
  isDeletePostLoading: false,

  getPostsMessage: "",
  getCommentsMessage: "",
  getPostMessage: "",
  likePostMessage: "",
  addPostMessage: "",
  addCommentMessage: "",
  editPostMessage: "",
  deletePostMessage: "",
  
  posts: [],
  comments: [],
  post: {},
  comment: {},


}

export const PostSlice = createSlice({
  name: 'post',
  initialState: INITIAL_STATE,
  reducers: {
    //get posts
    getPostsStart: state => {
      state.ACTION_TYPE = getPostsStart.toString();
      state.isGetPostsLoading = true;
      state.getPostsMessage = "";

    },
    getPostsSuccess: (state, action) => {
      state.ACTION_TYPE = getPostsSuccess.toString();
      state.isGetPostsLoading = false;
      state.getPostsMessage = "";
      state.posts = action.payload;
    },
    getPostsError: (state, action) => {
      state.ACTION_TYPE = getPostsError.toString();
      state.isGetPostsLoading = false;
      state.getPostsMessage = action.payload.message;
    },

    //get comments
    getCommentsStart: state => {
      state.ACTION_TYPE = getCommentsStart.toString();
      state.isGetCommentsLoading = true;
      state.getCommentsMessage = "";

    },
    getCommentsSuccess: (state, action) => {
      state.ACTION_TYPE = getCommentsSuccess.toString();
      state.isGetCommentsLoading = false;
      state.getCommentsMessage = "";
      state.comments = action.payload;
    },
    getCommentsError: (state, action) => {
      state.ACTION_TYPE = getCommentsError.toString();
      state.isGetCommentsLoading = false;
      state.getCommentsMessage = action.payload.message;
    },

    //get post
    getPostStart: state =>{
      state.ACTION_TYPE = getPostStart.toString();
      state.isGetPostLoading = true;
    },

    getPostSuccess: (state, action) => {
      state.ACTION_TYPE = getPostSuccess.toString();
      state.isGetPostLoading = false;
      state.post = action.payload;
    },

    getPostError: (state, action) => {
      state.ACTION_TYPE = getPostError.toString();
      state.isGetPostLoading = false;
      state.getPostMessage = action.payload.message;
    },

    //like post
    likePostStart: state =>{
      state.ACTION_TYPE = likePostStart.toString();
      state.isLikePostLoading = true;
    },

    likePostSuccess: (state, action) => {
      state.ACTION_TYPE = likePostSuccess.toString();
      state.isLikePostLoading = false;
    },
    unlikePostSuccess: (state, action) => {
      state.ACTION_TYPE = unlikePostSuccess.toString();
      state.isLikePostLoading = false;
    },

    likePostError: (state, action) => {
      state.ACTION_TYPE = likePostError.toString();
      state.isLikePostLoading = false;
      state.likePostMessage = action.payload.message;
    },
    

    //add post 
    addPostStart: state => {
      state.ACTION_TYPE = addPostStart.toString();
      state.isAddPostLoading = true;
      state.addPostMessage = "";

    },
    addPostSuccess: (state, action) => {
      state.ACTION_TYPE = addPostSuccess.toString();
      state.isAddPostLoading = false;
      state.addPostMessage = "";
    },
    addPostError: (state, action) => {
      state.ACTION_TYPE = addPostError.toString();
      state.isAddPostLoading = false;
      state.addPostMessage = action.payload.message;
    },

    //add comment 
    addCommentStart: state => {
      state.ACTION_TYPE = addCommentStart.toString();
      state.isAddCommentLoading = true;
      state.addCommentMessage = "";

    },
    addCommentSuccess: (state, action) => {
      state.ACTION_TYPE = addCommentSuccess.toString();
      state.isAddCommentLoading = false;
      state.addCommentMessage = "";
    },
    addCommentError: (state, action) => {
      state.ACTION_TYPE = addCommentError.toString();
      state.isAddCommentLoading = false;
      state.addCommentMessage = action.payload.message;
    },

    //edit post 
    editPostStart: state => {
      state.ACTION_TYPE = editPostStart.toString();
      state.isEditPostLoading = true;
      state.editPostMessage = "";

    },
    editPostSuccess: (state, action) => {
      state.ACTION_TYPE = editPostSuccess.toString();
      state.isEditPostLoading = false;
      state.editPostMessage = "";
    },
    editPostError: (state, action) => {
      state.ACTION_TYPE = editPostError.toString();
      state.isEditPostLoading = false;
      state.editPostMessage = action.payload.message;
    },
   
    //delete post 
    deletePostStart: state => {
      state.ACTION_TYPE = deletePostStart.toString();
      state.isDeletePostLoading = true;
      state.deletePostMessage = "";

    },
    deletePostSuccess: (state, action) => {
      state.ACTION_TYPE = deletePostSuccess.toString();
      state.isDeletePostLoading = false;
    },
    deletePostError: (state, action) => {
      state.ACTION_TYPE = deletePostError.toString();
      state.isDeletePostLoading = false;
      state.deletePostMessage = action.payload.message;
    },

   

    //reset 
    resetActionType: state => {
      state.ACTION_TYPE = resetActionType.toString();
    },

  }
})

// Action creators are generated for each case reducer function
export const
  {
    getPostStart, getPostSuccess, getPostError,

    getPostsStart, getPostsSuccess, getPostsError,
    getCommentsStart, getCommentsSuccess, getCommentsError,
    addPostStart, addPostSuccess, addPostError,
    likePostStart, likePostSuccess,unlikePostSuccess, likePostError,
    addCommentStart, addCommentSuccess, addCommentError,
    editPostStart, editPostSuccess, editPostError,
    deletePostStart, deletePostSuccess, deletePostError,
    resetActionType
  } = PostSlice.actions

export default PostSlice.reducer