


import { FIREBASECOLLECTION } from "../../constants/FIREBASECOLLECTIONS";
import { getFirebaseDocs, setFirebaseDoc } from "../../util/firebaseFirestoreUtils";


import { 
    getUserSearchResultsStart, getUserSearchResultsSuccess ,getUserSearchResultsError ,
    getPostSearchResultsStart, getPostSearchResultsSuccess ,getPostSearchResultsError ,
    getUserPostsStart, getUserPostsSuccess ,getUserPostsError ,
} from '../../redux/slices/SearchSlice';
import { getDocs, where } from "firebase/firestore";
import { POSTTAGS } from "../../constants/POSTTAGS";

export const getUserByCountrySearchResultsMiddleware = (COUNTRY_TO_SEARCH) => {
  return async (dispatch) => {
    dispatch(getUserSearchResultsStart());
    try {

        const usersQuery = getFirebaseDocs(`${FIREBASECOLLECTION.USERS}`, [
            where("country.name", ">=", COUNTRY_TO_SEARCH),
            // limit(5),
          ]);
          const querySnapshot = await getDocs(usersQuery);
          const userss = [];
          querySnapshot.forEach((doc) => {
            userss.push(doc.data());
          });
      
          dispatch( getUserSearchResultsSuccess(userss));

    } catch (error) {
      const errorCode = error.code;
      dispatch(getUserSearchResultsError({ message: errorCode ?? error }));
    }
  };
};

export const getPostByTagSearchResultsMiddleware = (TAG_TO_SEARCH = POSTTAGS.GENERALUPDATE) => {
  return async (dispatch) => {
    dispatch(getPostSearchResultsStart());
    try {

        const postsQuery = getFirebaseDocs(`${FIREBASECOLLECTION.POSTS}`, [
            where("tags", "array-contains-any", [TAG_TO_SEARCH]),
            // limit(5),
          ]);
          const querySnapshot = await getDocs(postsQuery);
          const postss = [];
          querySnapshot.forEach((doc) => {
            postss.push(doc.data());
          });
      
          dispatch( getPostSearchResultsSuccess(postss));

    } catch (error) {
      const errorCode = error.code;
      dispatch(getPostSearchResultsError({ message: errorCode ?? error }));
    }
  };
};

export const getPostsByUserIdMiddleware = (USER_ID ) => {
  return async (dispatch) => {
    dispatch(getUserPostsStart());
    try {

        const postsQuery = getFirebaseDocs(`${FIREBASECOLLECTION.POSTS}`, [
            where("createdBy.id", "==", USER_ID),
            // limit(5),
          ]);
          const querySnapshot = await getDocs(postsQuery);
          const postss = [];
          querySnapshot.forEach((doc) => {
            postss.push(doc.data());
          });
      
          dispatch( getUserPostsSuccess(postss));

    } catch (error) {
      const errorCode = error.code;
      dispatch(getUserPostsError({ message: errorCode ?? error }));
    }
  };
};

