import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import React from "react";

import { useFormik } from "formik";
import { Input } from "../forms/CustomInputs";


//redux
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateUserProfileMiddleware } from "../../redux/middlewares/AuthenticateMiddleware";
import { isFormFieldValid } from "../../util/formikUtils";

export const AddRegulationsDialog = (props) => {

    const authenticateState = useSelector((state) => state.authenticate);
    const userState = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const location = useLocation();
    // const {regulationIndex} = location.state;


    const { visible, onHide, } = props;

    const formik = useFormik({
        initialValues: {
            documentName: "",
            documentUrl: "",
        },

        validate: (values) => {
            const errors = {};
            if (!values.documentName) {
              errors.documentName = "Regulation name is required.";
            } 
            if (!values.documentUrl) {
              errors.documentUrl = "Regulation URL is required.";
            } 
           
            return errors;
          },

        onSubmit: (values) => {

            const REG = userState.user?.regulations ?? [] 
            let REGULATIONS = [ ...REG]
            REGULATIONS.push(
                {
                    documentName : values.documentName,
                    documentUrl : values.documentUrl,
                }
             );

            const userObject = {
                regulations: REGULATIONS 

            }

            dispatch(updateUserProfileMiddleware(userObject));
        },
    });

    

    return (
        <Dialog
            header={"Add Regulation"}
            visible={visible}
            onHide={onHide}
            className="w-11 md:w-8 lg:w-6"
            footer={() => (
                <div>
                    <Button
                        className="p-button-secondary"
                        label="Save"
                        type="submit"
                        onClick={formik.handleSubmit}
                        loading={authenticateState.isUpdateUserProfileLoading}

                    />
                </div>
            )}
        >
            <div className="flex flex-column bg-white p-5 mb-5">
                
                {/* input sections */}
                {/* ---------------------------------------------------------------- */}
                <Input
                    formik={formik}
                    label="Regulation Name"
                    id="documentName"
                    name="documentName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.documentName}
                    className={classNames({ "p-invalid": isFormFieldValid(formik, "documentName"), })}
                />
                <Input
                    label="Google Doc Link To Regulation"
                    formik={formik}
                    id="documentUrl"
                    name="documentUrl"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.documentUrl}
                    className={classNames({ "p-invalid": isFormFieldValid(formik, "documentUrl"), })}
                />
                {/* ---------------------------------------------------------------- */}
            </div>
        </Dialog>
    );
};

