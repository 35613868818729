import ReactPlayer from "react-player";
import { Image } from "primereact/image";
import moment from "moment";
import { FaGlobeAmericas } from "react-icons/fa";
import { HiSaveAs } from "react-icons/hi";
import { Button } from "primereact/button";
import { saveAs } from "file-saver";
import Linkify from "react-linkify";
import { RWebShare } from "react-web-share";
import { Badge } from "primereact/badge";
import { AiFillLike, AiOutlineLike } from "react-icons/ai";

// globals
import { FEEDTYPE } from "../../constants/FEEDTYPE";
import { defaultImage } from "../../constants/IMAGES";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { likePostMiddleware } from "../../redux/middlewares/PostMiddleware";
import { getPostSuccess, likePostSuccess, resetActionType  } from "../../redux/slices/PostSlice";
import { addNotificationMiddleware } from "../../redux/middlewares/NotificationMiddleware";

const PostItem2 = (
    {
        item,
        onEditPost,
        onDeletePost,
        // onAvatarClick,
        // onLikeClick,
        // onCommentClick,
    },
    key
) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //redux
    const authenticateState = useSelector((state) => state.authenticate);
    const postState = useSelector((state) => state.post);


    const [isLiked, setIsLiked] = React.useState(
        item?.likes?.includes(authenticateState.user_id.toString())
    );

    //on like clicked
    const onLikeClicked = () => {
        dispatch( getPostSuccess( item));
        setIsLiked((value) => !value);
        dispatch(likePostMiddleware(item.id, !isLiked));
    }
    //on comment clicked
    const onCommentClicked = () => {
        dispatch( getPostSuccess( item));
        navigate(`/post/${item.id}`);
    }

    //when a post is liked
    React.useEffect(() => {

        if (postState.ACTION_TYPE === likePostSuccess.toString() && item.id === postState.post.id) {
            //-----------LIKES NOTIFICATION-------------
            const notificationId = Date.now().toString();
            const newNotificationObject = {
                id: notificationId,
                sentFromId: authenticateState.user_id,
                sentToId: item?.createdBy?.id,
                title: "Liked your post",
                message: item?.description,
                createdAt: Date.now().toString(),
                isRead: false,
                sentFrom: {
                    name: authenticateState.user?.name,
                    imageUrl: authenticateState?.user?.profileImage,
                },
                sentTo: {
                    name: item?.createdBy?.name,
                    imageUrl: item?.createdBy?.profileImage,
                },
            };

            dispatch (addNotificationMiddleware(notificationId, newNotificationObject));
            dispatch(resetActionType());

        }

    }, [postState.ACTION_TYPE, item]);

    return (
        <div key={key} className="bg-white border-round-lg pt-4">
            {/*SECTION ONE: USER DETAILS, TIME POSTED AND ACTIONS */}
            {/* ---------------------------------------------------------------- */}
            <div className="flex align-items-start justify-content-between px-2 md:px-5">
                {/* image block */}
                <div className="flex align-items-center justify-content-between gap-2">
                    <Link to={`/profile/${item?.userId}`}>
                        <Image
                            src={
                                item?.userId === authenticateState.user_id
                                    ? authenticateState.user.profileImage ?? defaultImage
                                    : item?.createdBy?.profileImage ?? defaultImage
                            }
                            imageClassName="border-circle h-3rem w-3rem"
                            imageStyle={{
                                objectFit: "cover",
                            }}
                        />
                    </Link>
                    <div className="flex flex-column text-color">
                        <div className="w-full text-base">
                            {item?.userId === authenticateState.user_id
                                ? authenticateState.user.name
                                : item?.createdBy?.name}{" "}
                            <Badge
                                className={`${item.createdBy?.userType === "entrepreneur"
                                    ? "bg-primary-100"
                                    : item.createdBy?.userType === "investor"
                                        ? "bg-blue-100"
                                        : "bg-cyan-100"
                                    } font-light text-gray-800`}
                                value={item.createdBy?.userType?.replace(/_+/g, " ")}
                            />{" "}
                        </div>
                        <div className="text-xs flex align-items-center gap-1 mt-1">
                            {moment(new Date(item?.createdAt)).fromNow()} <FaGlobeAmericas />
                        </div>
                    </div>
                </div>

                {/* action block, shows only for current logged in user*/}
                {item?.userId === authenticateState.user_id && (
                    <div className="flex gap-1 align-items-center">
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-sm p-button-text"
                            onClick={onEditPost}
                        />
                        <Button
                            icon="pi pi-trash"
                            className="p-button-sm p-button-text"
                            onClick={onDeletePost}
                        />
                    </div>
                )}
            </div>
            {/* ---------------------------------------------------------------- */}

            {/* SECTION TWO: DESCRIPTION */}
            {/* ---------------------------------------------------------------- */}
            <div className="mt-3 mb-1 px-3 md:px-5">
                <Linkify
                    //customize the whole component
                    componentDecorator={(decoratedHref, decoratedText) => (
                        <a
                            href={decoratedHref}
                            target={"_blank"}
                            rel="noreferrer"
                            className="no-underline"
                        >
                            {decoratedText}
                        </a>
                    )}
                    //link url
                    hrefDecorator={(urlHref) => urlHref}
                    //link text
                    textDecorator={(text) => {

                        function addHttps(value) {
                            if (!/^(f|ht)tps?:\/\//i.test(value)) {
                                value = "https://" + value;
                            }
                            return value;
                        }

                        return (
                            <span className="text-green-800">
                                {new URL(addHttps(text))?.hostname}
                            </span>
                        );
                    }}
                >
                    {item?.description}
                </Linkify>
            </div>

            {/* ---------------------------------------------------------------- */}

            {/*SECTION THREE: RENDER ON POST TYPE  */}
            {/* ---------------------------------------------------------------- */}
            <div className="w-full flex mx-auto">
                {item.fileData?.type === FEEDTYPE.IMAGE ? (
                    <ImageType file={item?.fileUrl} />
                ) : item.fileData?.type === FEEDTYPE.VIDEO ? (
                    <VideoType file={item?.fileUrl} />
                ) : item.fileData?.type === FEEDTYPE.DOCUMENT ? (
                    <DocumentType
                        documentName={item?.fileData.name}
                        onDownload={() => saveAs(item?.fileUrl, item?.documentName)}
                    />
                ) : (
                    <GeneralType />
                )}
            </div>

            {/* ---------------------------------------------------------------- */}

            {/*SECTION 4 LIKES AND COMMENTS*/}
            {/* ---------------------------------------------------------------- */}
            <div className="flex flex-column mt-3 border-gray-200">
                <div className="flex justify-content-between px-5 mt-2">
                    <span className="flex gap-2 align-items-center">
                        {item?.likeCount ?? 0} Likes
                    </span>

                    <span
                        className="hover:underline cursor-pointer"
                        onClick={onCommentClicked}
                    >
                        {item?.commentCount ?? 0} Comments
                    </span>
                </div>

                {/* on like button clicked */}
                <div className="border-top-1 border-gray-200 py-1 mt-2 flex justify-content-center align-items-center gap-5">
                    <Button
                        className="p-button-sm p-button-text lg:text-lg 
          flex justify-content-center gap-2 text-color px-5"
                        onClick={onLikeClicked}
                    >
                        {isLiked ? (
                            <AiFillLike className="text-primary" size={20} />
                        ) : (
                            <AiOutlineLike size={20} />
                        )}{" "}
                        <span className="hidden md:block">Like</span>
                    </Button>

                    {/* on comment button clicked */}
                    <Button
                        className="p-button-sm p-button-text lg:text-lg 
          flex justify-content-center gap-2 text-color px-1 md:px-5"
                        onClick={() => {
                            navigate(`/post/${item.id}`);
                        }}
                    >
                        <i className="pi pi-comment" />{" "}
                        <span className="hidden md:block">Comment</span>
                    </Button>

                    <RWebShare
                        data={
                            {
                                text: "I saw this awesome post on justis social.",
                                url: `${window.location.href}/post/${item.id}`,
                                title: "Justis Social",
                            }

                        }
                        onClick={() => console.log("shared successfully!")}
                    >
                        <Button
                            className="p-button-sm p-button-text lg:text-lg 
          flex justify-content-center gap-2 text-color px-5"
                        >
                            <i className="pi pi-share-alt" />{" "}
                            <span className="hidden md:block">Share</span>
                        </Button>
                    </RWebShare>
                </div>
            </div>
            {/* ---------------------------------------------------------------- */}
        </div>
    );
};

function ImageType({ file }) {
    return (
        <div className="w-full mt-2 flex justify-content-center bg-gray-800 align-items-center">
            <Image
                src={file}
                imageStyle={{
                    aspectRatio: "4/3",
                    width: "100%",
                    objectFit: "contain",
                    backgroundColor: "rgba(10, 58, 90, .2)",
                }}
                alt="post"
                preview
                downloadable
            />
        </div>
    );
}

function VideoType({ file }) {
    return (
        <div className="mt-2 w-full">
            <ReactPlayer
                url={file}
                controls={true}
                width="100%"
                volume={0}
                loop={false}
                muted={true}
                autoPlay={true}
                style={{
                    backgroundColor: "rgba(10, 58, 90, .2)",
                    objectFit: "cover",
                }}
            />
        </div>
    );
}

function DocumentType({ documentName, onDownload }) {
    return (
        <div className="flex justify-content-center  mx-auto">
            <Button
                className="p-button-text p-button-sm p-button-raised flex align-items-center 
        justify-content-center text-color px-5 gap-3"
                onClick={onDownload}
            >
                <Image
                    src="https://www.svgrepo.com/show/375299/word-document.svg"
                    imageClassName="h-2rem w-2rem"
                />
                <div className="max-w-7rem lg:max-w-30rem white-space-nowrap overflow-hidden text-overflow-ellipsis">
                    {documentName}
                </div>
                <HiSaveAs size={20} />
            </Button>
        </div>
    );
}

function GeneralType() { }

export default PostItem2;
