import React from "react";

// wrappers
import {
  MainWithSideWrapper as Wrapper,
  MainWithSide as Main,
  Side,
} from "../../components/containers/Wrappers";

// PAGES
import NotFound from "../misc/ErrorPage";
import Loading from "../misc/LoadingPage";
import EntrepreneurProfilePage from "./EntrepreneurProfilePage";
import InvestorProfilePage from "./InvestorProfilePage";
import PolicyMakerProfilePage from "./PolicyMakerProfilePage";

//globals
import { USERTYPE } from "../../constants/USERTYPE";

//redux
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserByIdMiddleware } from "../../redux/middlewares/UserMiddleware";
import {
  resetActionType,
  updateUserProfileError,
  updateUserProfileSuccess,
} from "../../redux/slices/AuthenticateSlice";
import { getUserError } from "../../redux/slices/UserSlice";
import { ShareProfile } from "../../components/customs/ShareProfile";
import { SuggestedUsers } from "../../components/customs/SuggestedUsers";
import { Button } from "primereact/button";

export default function ProfilePage(props) {
  const dispatch = useDispatch();
  const authenticateState = useSelector((state) => state.authenticate);
  const userState = useSelector((state) => state.user);

  const { id } = useParams();

  React.useEffect(() => {
    dispatch(getUserByIdMiddleware(id));
  }, [id]);

  React.useEffect(() => {
    if (authenticateState.ACTION_TYPE === updateUserProfileSuccess.toString()) {
      dispatch(getUserByIdMiddleware(id));
      toast.success("Your profile has been updated successfully");
      dispatch(resetActionType());
    } else if (
      authenticateState.ACTION_TYPE === updateUserProfileError.toString()
    ) {
      toast.error(authenticateState.updateUserProfileMessage);
      dispatch(resetActionType());
    }
  }, [authenticateState.ACTION_TYPE]);

  if (userState.isGetUserLoading) {
    return <Loading />;
  }

  if (userState.ACTION_TYPE === getUserError.toString()) {
    return <NotFound />;
  }

  const USERTYPE_COMPONENT_MAP = {
    entrepreneur: EntrepreneurProfilePage,
    investor: InvestorProfilePage,
    policy_maker: PolicyMakerProfilePage,
  };

  const ComponentToRender =
    USERTYPE_COMPONENT_MAP[userState.user?.userType] ?? NotFound;

  return (
    <>
      <Wrapper>
        <Main className="pb-8">
          <ComponentToRender
            isCurrentUser={authenticateState.user_id === id}
            isGuest={props.isGuest}
          />

          {props.isGuest && (
            // if guest
            <div className="flex flex-column gap-3 align-items-center lg:hidden pt-3">
              <Link
                to="/register"
                className="no-underline text-md md:text-lg text-primary-800  hover:text-primary-600"
              >
                <Button>Dont have an account? Sign up</Button>
              </Link>

              <Link
                to="/login"
                className="no-underline text-md md:text-lg text-primary-800  hover:text-primary-600"
              >
                <Button>Already have an account? Sign in</Button>
              </Link>
            </div>
          )}
        </Main>
        <Side className="flex-column">
          {props.isGuest ? (
            // if guest
            <div className="mt-5 flex flex-column gap-3 align-items-center">
              <Link
                to="/register"
                className="no-underline text-md md:text-lg text-primary-800  hover:text-primary-600"
              >
                <Button>Dont have an account? Sign up</Button>
              </Link>

              <Link
                to="/login"
                className="no-underline text-md md:text-lg text-primary-800  hover:text-primary-600"
              >
                <Button>Already have an account? Sign in</Button>
              </Link>
            </div>
          ) : (
            // if logged in
            <>
              <ShareProfile
                color={
                  USERTYPE.POLICY_MAKER === userState.user?.userType
                    ? "p-button-secondary"
                    : ""
                }
              />
              <SuggestedUsers />
            </>
          )}
        </Side>
      </Wrapper>
    </>
  );
}
