/*
 * handles app routing
 */
import React from "react";

// app routes
import AuthRouter from "./AuthRouter";
import AppRouter from "./AppRouter";

import { useSelector } from "react-redux";
import { goToAppPage, goToLoginPage } from "../redux/slices/NavigationSlice";

export default function RootRouter() {
  const navigationState = useSelector((state:any) => state.navigation);
  
  if (navigationState.ACTION_TYPE === goToAppPage.toString()) {
    return (
      <AppRouter/>
    );
  } else if (navigationState.ACTION_TYPE === goToLoginPage.toString()) {
    return <AuthRouter/>
  }

  return <AuthRouter />;
}
