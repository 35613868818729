import React from "react";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

// icons
import { FcVideoCall, FcStackOfPhotos, FcDocument } from "react-icons/fc";
import { Image } from "primereact/image";

export const ShareAPost = ({ avatar, onAvatarClick, onShare }) => {
  return (
    <div className="bg-white border-round">
      <div className="flex p-3 justify-content-center gap-3">
        <Image
          src={avatar}
          imageClassName="bg-gray-200 border-circle h-3rem w-3rem object-fit-cover"
          onClick={onAvatarClick}
          imageStyle={{
            objectFit: "cover",
          }}
        />
        <Button
          className="p-button-text bg-gray-200 border-round-3xl col text-color"
          onClick={onShare}
        >
          <span className="pl-4 text-sm md:text-base">Would you like to share today ?</span>
        </Button>
      </div>
    </div>
  );
};
