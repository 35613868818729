import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";


import Empty from "../../components/search/Empty";
import Loading from "../../components/search/Loading";

// results
import PostResults from "../../components/search/PostResults";
import UserResults from "../../components/search/UserResults";

export default function TopSearch(props) {

  const searchState = useSelector((state) => state.search);
  const { state } = useLocation();



  if (searchState.isGetPostSearchResultsLoading || searchState.isGetUserSearchResultsLoading) {
    return <Loading />;
  }

  if (searchState.userSearchResults?.length === 0 && searchState.postSearchResults?.length === 0) {
    return (
      <>
        <Empty />
      </>
    );
  }

  return (
    <div>
      {searchState.userSearchResults?.length > 0 && (
        <UserResults title="Top Users" data={searchState.userSearchResults.slice(0, 5)} />
      )}
      {searchState.postSearchResults?.length > 0 && (
        <PostResults title="Top Posts" data={searchState.postSearchResults.slice(0, 5)} />
      )}
    </div>
  );
}
