import React from "react";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { useNavigate } from "react-router-dom";
import { AddRepresentativesDialog } from "../dialogs/AddRepresentativesDialog";
import { useSelector } from "react-redux";
import { DeleteRepresentativeDialog } from "../dialogs/DeleteRepresentativeDialog";

export const ViewRepresentativesBlock = (props) => {
  const { isCurrentUser } = props;
  const [showEdit, setShowEdit] = React.useState(false);
  const userState = useSelector((state) => state.user);

  const [showDelete, setShowDelete] = React.useState(false);
  const [indexToDelete, setIndexToDelete] = React.useState();


  const user = {
    representatives: userState.user?.representatives,
  };

  const navigate = useNavigate();

  return (
    <div className="mt-4 bg-white p-3 border-round">
      <div className="flex justify-content-between align-items-center">
        <h2>Representatives</h2>
        {isCurrentUser && (
          <Button
            icon="pi pi-plus"
            className="p-button-text"
            onClick={() => {
              setShowEdit(true);
            }}
          />
        )}
      </div>

      {user?.representatives ? (
        <div className="flex col gap-4 flex-wrap">
          {user?.representatives?.map((r, index) => (
            <div key={index} className="relative">
              <Image
                imageClassName="bg-primary-100 h-8rem w-8rem border-round"
                src={r.imageUrl}
                imageStyle={{
                  objectFit: "cover",
                }}
              />
              <div className="flex flex-column">
                <span className="text-sm">{r.name}</span>
                <span className="text-sm">{r.role}</span>
              </div>
              {isCurrentUser && (
                <Button
                  className="text-white p-button-sm p-button-text absolute"
                  icon="pi pi-trash"
                  onClick={() => {
                    setIndexToDelete(index);
                    setShowDelete(true);
                  }}
                  style={{
                    top: 0,
                    right: 0,
                  }}
                />
              )}
            </div>
          ))}
        </div>
      ) : (
        <div>No representatives have been added</div>
      )}

      {/* Dailogs */}
      {isCurrentUser && (
        <AddRepresentativesDialog
          visible={showEdit}
          onHide={() => setShowEdit(false)}
        />
      )}

      {isCurrentUser && (
        <DeleteRepresentativeDialog
          visible={showDelete}
          onHide={() => setShowDelete(false)}
          indexToDelete={indexToDelete}
        />
      )}
    </div>
  );
};
