import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  ACTION_TYPE: "",

  isGetPostSearchResultsLoading: false,
  isGetUserSearchResultsLoading: false,
  isGetUserPostsLoading: false,
  

  getPostSearchResultsMessage: "",
  getUserSearchResultsMessage: "",
  getUserPostsMessage: "",
  
  postSearchResults: [],
  userSearchResults: [],
  userPostsSearchResults: [],
  
  


}

export const SearchSlice = createSlice({
  name: 'search',
  initialState: INITIAL_STATE,
  reducers: {
    //get postSearchResults
    getPostSearchResultsStart: state => {
      state.ACTION_TYPE = getPostSearchResultsStart.toString();
      state.isGetPostSearchResultsLoading = true;
      state.getPostSearchResultsMessage = "";

    },
    getPostSearchResultsSuccess: (state, action) => {
      state.ACTION_TYPE = getPostSearchResultsSuccess.toString();
      state.isGetPostSearchResultsLoading = false;
      state.getPostSearchResultsMessage = "";
      state.postSearchResults = action.payload;
    },
    getPostSearchResultsError: (state, action) => {
      state.ACTION_TYPE = getPostSearchResultsError.toString();
      state.isGetPostSearchResultsLoading = false;
      state.getPostSearchResultsMessage = action.payload.message;
    },

    //get UserSearchResults
    getUserSearchResultsStart: state => {
      state.ACTION_TYPE = getUserSearchResultsStart.toString();
      state.isGetUserSearchResultsLoading = true;
      state.getUserSearchResultsMessage = "";

    },
    getUserSearchResultsSuccess: (state, action) => {
      state.ACTION_TYPE = getUserSearchResultsSuccess.toString();
      state.isGetUserSearchResultsLoading = false;
      state.getUserSearchResultsMessage = "";
      state.userSearchResults = action.payload;
    },
    getUserSearchResultsError: (state, action) => {
      state.ACTION_TYPE = getUserSearchResultsError.toString();
      state.isGetUserSearchResultsLoading = false;
      state.getUserSearchResultsMessage = action.payload.message;
    },

    //get UserSearchResults
    getUserPostsStart: state => {
      state.ACTION_TYPE = getUserPostsStart.toString();
      state.isGetUserPostsLoading = true;
      state.getUserPostsMessage = "";

    },
    getUserPostsSuccess: (state, action) => {
      state.ACTION_TYPE = getUserPostsSuccess.toString();
      state.isGetUserPostsLoading = false;
      state.getUserPostsMessage = "";
      state.userPostsSearchResults = action.payload;
    },
    getUserPostsError: (state, action) => {
      state.ACTION_TYPE = getUserPostsError.toString();
      state.isGetUserPostsLoading = false;
      state.getUserPostsMessage = action.payload.message;
    },

   

    //reset 
    resetActionType: state => {
      state.ACTION_TYPE = resetActionType.toString();
    },

  }
})

// Action creators are generated for each case reducer function
export const
  {
    getPostSearchResultsStart, getPostSearchResultsSuccess, getPostSearchResultsError,
    getUserSearchResultsStart, getUserSearchResultsSuccess, getUserSearchResultsError,
    getUserPostsStart, getUserPostsSuccess, getUserPostsError,
    resetActionType
  } = SearchSlice.actions

export default SearchSlice.reducer