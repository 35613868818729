import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";


//redux
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfileMiddleware } from "../../redux/middlewares/AuthenticateMiddleware";
import { Input, NumberInput, SelectSingle } from "../forms/CustomInputs";

export const EditFinancialInformationDialog = (props) => {

    const { visible, onHide, } = props;
    const dispatch = useDispatch();

    const authenticateState = useSelector((state) => state.authenticate);
    const userState = useSelector((state) => state.user);

    const formik = useFormik({
        initialValues: {
            costOfOperation: userState.user?.costOfOperation ?? 0,
            annualRevenue: userState.user?.annualRevenue ?? 0,
            annualProfit: userState.user?.annualProfit ?? 0,
            numberOfClients: userState.user?.numberOfClients ?? 0,
            sourceOfFinance: userState.user?.sourceOfFinance ?? "N/A",
            accessToFinance: userState.user?.accessToFinance ?? "No",
        },

        onSubmit: (values) => {

            const userObject = {
                costOfOperation: values.costOfOperation ,
                annualRevenue: values.annualRevenue ,
                annualProfit: values.annualProfit ,
                numberOfClients: values.numberOfClients ,
                sourceOfFinance: values.sourceOfFinance ,
                accessToFinance: values.accessToFinance ,

            }

            console.log(userObject);

            dispatch(updateUserProfileMiddleware(userObject));
        },
    });

   

    return (
        <Dialog
            header={"Edit Finanacial Information"}
            visible={visible}
            onHide={onHide}
            className="w-11 md:w-8 lg:w-6"
            footer={() => (
                <div>
                    <Button
                        className="p-button-secondary"
                        label="Save"
                        onClick={formik.handleSubmit}
                        loading={authenticateState.isUpdateUserProfileLoading}
                    />
                </div>
            )}
        >
            <form onSubmit={formik?.handleSubmit} className=" " >

                <div>
                    <NumberInput
                        formik={formik}
                        id="costOfOperation"
                        label="Cost of Operation"
                        value={formik.values.costOfOperation}
                    />

                    <NumberInput
                        formik={formik}
                        id="annualRevenue"
                        label="Annual Revenue"
                        value={formik.values.annualRevenue}
                    />

                    <NumberInput
                        formik={formik}
                        id="annualProfit"
                        label="Annual Profit"
                        value={formik.values.annualProfit}
                    />

                    <NumberInput
                        formik={formik}
                        id="numberOfClients"
                        label="Number of Clients"
                        value={formik.values.numberOfClients}
                    />

                    <Input
                        formik={formik}
                        id="sourceOfFinance"
                        placeholder="(eg.Trade, Services)"
                        label="Source of Finance"
                        value={formik.values.sourceOfFinance}
                    />

                    <SelectSingle
                        formik={formik}
                        id={"accessToFinance"}
                        label="Access to Finanace"
                        options={["No", "Yes"]}
                        value={formik.values.accessToFinance}
                    />
                </div>
            </form>
        </Dialog>
    );
};