import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";


//redux
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfileMiddleware } from "../../redux/middlewares/AuthenticateMiddleware";

export const DeleteRepresentativeDialog = (props) => {

    const authenticateState = useSelector((state) => state.authenticate);
    const userState = useSelector((state) => state.user);
    const dispatch = useDispatch();


    const { visible, onHide, indexToDelete } = props;

    const handleSubmit = (values) => {

        let REPRESENTATIVE = userState.user?.representatives ? [...userState.user?.representatives] : [] ;
        REPRESENTATIVE.splice( indexToDelete, 1);


        const userObject = {
            representatives: REPRESENTATIVE 

        }

        dispatch(updateUserProfileMiddleware(userObject));
    }

   

    return (
        <Dialog
            header={"DELETE REPRESENTATIVE?"}
            visible={visible}
            onHide={onHide}
            className="w-11 md:w-8 lg:w-6"
            footer={() => (
                <div>
                    <Button
                        className="p-button-secondary"
                        label="Cancel"
                        type="submit"
                        onClick={onHide}
                        loading={authenticateState.isUpdateUserProfileLoading}

                    />
                    <Button
                        className="p-button-danger"
                        label="Delete"
                        type="submit"
                        onClick={handleSubmit}
                        loading={authenticateState.isUpdateUserProfileLoading}

                    />
                </div>
            )}
        >
            <div className="flex flex-column bg-white p-5 mb-5">
                
                <h1>Are you sure you want to delete?</h1>
                {/* ---------------------------------------------------------------- */}
            </div>
        </Dialog>
    );
};

