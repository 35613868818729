


import { arrayRemove, arrayUnion, collection, doc, increment, runTransaction } from "firebase/firestore";
import { FIREBASECOLLECTION } from "../../constants/FIREBASECOLLECTIONS";
import { auth, db } from "../../firebase/firebase-config";
import { deleteFirebaseDoc, getCollectionFirebase, getFirebaseDoc, setFirebaseDoc, setFirebaseSubCollection, updateFirebaseDoc } from "../../util/firebaseFirestoreUtils";

import {
    sendMessageError, sendMessageStart, sendMessageSuccess,
    addCommentError, addCommentStart, addCommentSuccess,
    deletePostError, deletePostStart, deletePostSuccess,
    editPostError, editPostStart, editPostSuccess,
    getPostError, getRecentChatsError, getRecentChatsStart,
    getRecentChatsSuccess, getPostStart, getPostSuccess,
    getCommentsSuccess, getCommentsError, getCommentsStart,
    likePostSuccess, likePostError, likePostStart, unlikePostSuccess,
} from "../slices/ChatSlice";

/**
 * create/update a room with id, last message sent, createdAt ,updatedAt
 * create a new messages subcollection under room with id, to,from,message,createdAt
 */

export const sendMessageMiddleware = (roomId, messageObject) => {
    return async (dispatch) => {
        dispatch(sendMessageStart());
        try {
            await runTransaction(db, async (transaction) => {


                const roomDocRef = doc(db, `${FIREBASECOLLECTION.ROOMS}/${roomId}`);
                const roomDoc = transaction.get(roomDocRef);
                if ((await roomDoc).exists()) {
                    transaction.update(roomDocRef, {
                        updatedAt: Date.now(),
                        lastMessage: messageObject.message
                    })
                } else {
                    transaction.set(roomDocRef, {
                        id: roomId,
                        createdAt: Date.now(),
                        lastMessage: messageObject.message,
                        to:messageObject.to,
                        from:messageObject.from
                    });
                }

                // create a new message

                let messageId = Date.now().toString();
                const subcollectionRef = collection(db, `${FIREBASECOLLECTION.ROOMS}/${roomId}/${FIREBASECOLLECTION.MESSAGES}`) ;
                const messageRef = doc(subcollectionRef, messageId);
                transaction.set(messageRef, {id: messageId, ...messageObject})
        

            });
            
            console.log("Transaction successfully committed!");
            dispatch(sendMessageSuccess({}));

        } catch (error) {
            console.log("Transaction failed: ",error );
            dispatch(sendMessageError({ message: "erro sending message" }));

        }
       
    };
};

export const getRecentChatsMiddleware = () => {
    return async (dispatch) => {
        dispatch(getRecentChatsStart());

        try {
            const data = [];
            const querySnapshot = await getCollectionFirebase(FIREBASECOLLECTION.ROOMS);

            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });

            dispatch(getRecentChatsSuccess(data));

        } catch (error) {
            const errorCode = error.code;
            // const errorMessage = error.message;
            dispatch(getRecentChatsError({ message: errorCode }));
        }
    };
}
