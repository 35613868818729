// styles
import "./styles/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import PrimeReact from "primereact/api";
import "primeflex/primeflex.css";
import "./styles/customs.css";

// router
import { BrowserRouter as Router } from "react-router-dom";
import RootRouter from "./router/RootRouter";

// Redux imports
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  PrimeReact.ripple = true;

  return (
    <div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <RootRouter />
          </Router>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
