import React from "react";
import {
  MainWithSide as Main,
  MainWithSideWrapper,
  Side,
} from "../../components/containers/Wrappers";
import Empty from "../../components/search/Empty";

// results
import { useDispatch, useSelector } from "react-redux";
import PostResults from "../../components/search/PostResults";
import { getPostsByUserIdMiddleware } from "../../redux/middlewares/SearchMiddleware";
import { useParams } from "react-router-dom";
import Loading from "../../components/search/Loading";

export default function UserPostsPage(props) {
  const dispatch = useDispatch();
  const searchState = useSelector((state) => state.search);

  let { id } = useParams();

  React.useEffect(() => {
    dispatch(getPostsByUserIdMiddleware(id));
  }, [id]);

  if (searchState.isGetUserPostsLoading) {
    return (
      <MainWithSideWrapper>
        <Main>
          <Loading />
        </Main>
        <Side />
      </MainWithSideWrapper>
    );
  }

  if (searchState.userPostsSearchResults?.length === 0) {
    return (
      <MainWithSideWrapper>
        <Main>
          <Empty />;
        </Main>
        <Side />
      </MainWithSideWrapper>
    );
  }

  return (
    <MainWithSideWrapper>
      <Main className="flex flex-column gap-5 mt-4 pb-8">
        <PostResults
          title="User Posts"
          data={searchState.userPostsSearchResults}
        />
      </Main>
      <Side />
    </MainWithSideWrapper>
  );
}
