import React from "react";
import Lottie from "react-lottie";
import * as image from "../../assets/lottie/notfound.json";

export default function Loading() {
  return (
    <div className=" flex flex-column  justify-content-center align-items-center pointer-events-none">
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: image,
        }}
        height={"40%"}
        width={'60%'}
      />

      <div className="w-10 md:w-8">
        <h1 className="text-2xl md:text-4xl text-center font-medium">
          No results to display!
        </h1>
        <p className="text-lg text-center">
          Try searching for something else
        </p>
      </div>
    </div>
  );
}
