import React from "react";
import { Button } from "primereact/button";
import { USERTYPE } from "../../constants/USERTYPE";

//redux
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const ViewRecentActivityBlock = (props) => {
  const { isCurrentUser } = props;

  const userState = useSelector((state) => state.user);

  const posts = [];

  return (
    <div className="p-3 bg-white mt-4 border-round">
      <div className="flex justify-content-between align-items-center">
        <h2>Recent Activity</h2>
        
      </div>
      {/* <div className="flex pb-5 align-items-center flex-wrap justify-content-start gap-3">
        {posts?.length <= 0 ? (
          <div className="text-center w-12">
          {userState.user.name} has not made any posts yet
          </div>
          ) : (
            <div className="text-center w-12">
            This user has {posts?.length} post(s)
            </div>
            )}
          </div> */}
      <div>
        {props.isGuest ?
          <h2>Login/Sign up to see this user's posts</h2>
          :
        <Link to={`/posts/${userState.user.id}`} className='no-underline'>
          <Button
            className={`w-12 ${userState.user.userType === USERTYPE.ENTREPRENEUR
                ? "bg-primary-500 hover:bg-primary-400"
                : userState.user.type === USERTYPE.INVESTOR
                  ? "bg-blue-500 hover:bg-blue-400"
                  : "bg-cyan-500 hover:bg-cyan-400"
              } border-none`}
            label="Click to see all posts"
          />
        </Link>
        }
      </div>
    </div>
  );
};
