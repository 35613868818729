import { Image } from "primereact/image";
import React from "react";
import { useNavigate } from "react-router-dom";

const SuggestedUserItemComponent = ({ user }) => {
  const navigate = useNavigate();

  return (


      <div className=" flex flex-column gap-3">
        <div
         
          className="flex align-items-center gap-2 cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            navigate(`/profile/${user.id}`);
          }}
        >
          <Image
            imageClassName="bg-gray-200 border-circle h-3rem w-3rem object-fit-cover"
            src={user.profileImage}
            imageStyle={{
              objectFit: "cover",
            }}
          />

          <div className="text-xs">
            <div className='mb-2'> {user.name}</div>

            <span
              className={`${user?.userType === "entrepreneur"
                  ? "bg-primary-100"
                  : user?.userType === "investor"
                    ? "bg-blue-100"
                    : "bg-cyan-100"
                } font-light text-gray-800 px-2 border-round`}
              style={{
                paddingTop: 3,
                paddingBottom: 3,
              }}
            >
              {user?.userType?.replace(/_+/g, " ")}
            </span>
          </div>
        </div>
      </div>
  );
};

export default SuggestedUserItemComponent;